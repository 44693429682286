import NewHeader from './NewHeader';

import './NewCard.css';
import { SmartUrl } from '../utils';

function NewCard({ data, to = '/', removeBackBtn = false, onBack, ...props }) {

  return (
    <div {...props} className="relative">
      <div className='absolute top-0 left-0 z-[200]'>
        <NewHeader back={!removeBackBtn} to={to} onBack={onBack} hideMenu noLogo />
      </div>
      <div style={{ height: 300 }}>
        <img className='object-cover w-full h-full' src={data?.cover ? SmartUrl(data?.cover) : SmartUrl(data?.banner)} />
        <div className='absolute top-0 left-0 w-full z-[100] h-full bg-image-gradient' />
      </div>
      <div className='absolute left-0 bottom-0 card-content mb-5 ml-8 font-interRegular text-white z-[200]'>
        <div className='font-interBold text-xl'>{data?.title}</div>
        <div>{data?.name} {data?.description}</div>
      </div>
    </div>
  );
}

export default NewCard;
