import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoJS.css'


export const VideoJS = ({ source, options, onReady, onEnd, onError }) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("w-full")
      videoElement.classList.add("h-full")
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });
      player.on("ended", onEnd)
      player.on('error', onError)
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player className='w-full h-full'>
      <div ref={videoRef} className='w-full h-full' />
    </div>
  );
}