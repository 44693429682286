import { AddIcon, CheckCircle } from "../../../../components/Icons";
import { UploadFile } from "../../../../components/Inputs";
import { InputUnderline } from "../../../../components/NewInputs";

export default function Step1({ products, updateProducts, onAddProduct }) {
  return (
    <>
      {products.map((product, index) => (
        <MerchProductItem
          key={index}
          { ...product }
          index={index}
          onNameChange={e => updateProducts(index, 'name', e.target.value)}
          onPriceChange={e => updateProducts(index, 'price', e.target.value)}
          onSizesChange={e => updateProducts(index, 'sizes', e.target.value)}
          onQuantityChange={e => updateProducts(index, 'quantity', e.target.value)}
          onFileChange={e => updateProducts(index, 'image', e.target.files[0])}
        />
      ))}
      <button className='flex items-center mt-10' onClick={onAddProduct}>
        <AddIcon />
        <i className='font-interRegular ml-4 text-xs'>
          Agregar más productos
        </i>
      </button>
    </>
  )
}

export function MerchProductItem({ index, name, price, sizes, quantity, image, onNameChange, onPriceChange, onSizesChange, onQuantityChange, onFileChange }) {
  return (
    <div className="my-4">
      <p className='text-base font-bold font-interRegular'>
        Producto {index + 1}
      </p>
      <InputUnderline
        placeholder='Nombre del Producto'
        value={name}
        onChange={onNameChange}
      />
      <InputUnderline
        placeholder='Precio'
        value={price}
        onChange={onPriceChange}
        type='number'
      />
      <InputUnderline
        placeholder='Tallas disponibles (opcional)'
        value={sizes}
        onChange={onSizesChange}
      />
      <InputUnderline
        placeholder='Cantidad de productos en stock (opcional)'
        value={quantity}
        onChange={onQuantityChange}
        type='number'
      />
      <div className='mt-4'>
        <div className='flex flex-row justify-between'>
          <div className='text-gray-400 my-4'>
            <div>Peso: 50 KB</div>
          </div>
          {image !== null && <CheckCircle />}
        </div>
        <UploadFile className='w-full' img label='Subir imágen' handleFileChange={onFileChange} />
      </div>
    </div>
  )
}