import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  logout,
  s_refresh_token,
  s_session_token,
  s_token_expired,
  updateAccessToken,
} from '../../reducer/SessionReducer';
import { parseJwt, str_not_empty } from '../../utils';
import config from '../../config';

const defaultOptions = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  body: undefined,
  params: '',
  auth: true,
  append: false,
  forceNext: false,
  auto: true,
  upload: false,
};

export const useApi = (resource, options = defaultOptions) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [next, setNext] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(options.auto);//loading true when using auto to avoid flickering loading
  const [error, setError] = useState('');
  const token = useSelector(s_session_token);
  const expired = useSelector(s_token_expired);
  const refreshToken = useSelector(s_refresh_token);

  options = {
    ...defaultOptions,
    ...options,
  };

  const clearError = () => setError('');

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      clearError()
      let headers = apiHeaders(options, token);

      //console.log('[useApi headers]', headers, options)
      //console.log('[useApi token expired]', expired);

      /*if (expired && str_not_empty(token)) {
        headers = await refreshTokenApi(refreshToken, headers, dispatch);
      }*/

      const parsedResponse = await doFetch(headers, options, resource, page);
      const resultData = parsedResponse.results || parsedResponse;
      const hasNext =
        parsedResponse.next !== null &&
        parsedResponse.next !== undefined &&
        parsedResponse.next;
      setNext(hasNext);

      if (options.append) {
        setData([...data, ...resultData]);
      } else {
        setData(resultData);
      }

      if (hasNext || options.forceNext) {
        setPage(page + 1);
      }
    } catch (error) {
      console.error(`[FAILED FETCH] ${config.API_URL}${resource}`, error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [resource, page]);

  const asyncFetch = async (body, appendParams, forceHeader) => {
    try {
      setLoading(true);
      clearError()
      let headers = apiHeaders(options, token);

      //console.log('[useApi headers]', headers, options)
      //console.log('[useApi token expired]', expired);

      /*if (expired) {
        headers = await refreshTokenApi(refreshToken, headers, dispatch);
      }*/

      options.body = body;
      const parsedResponse = await doFetch(forceHeader || headers, options, resource, undefined, appendParams);
      //console.log(`SUCCESS FETCH - RESOURCE: ${resource}`, parsedResponse);
      const resultData = parsedResponse.results || parsedResponse;

      if (options.append) {
        setData([...data, ...resultData]);
      } else {
        setData(resultData);
      }

      return resultData
    } catch (error) {
      console.error(`[FAILED FETCH] ${config.API_URL}${resource}`, error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (options.auto) {
      fetchData();
    }
  }, [resource]);

  return { data, loading, next, error, fetchData, asyncFetch, clearError };
};

const refreshTokenApi = async (refreshToken, headers, dispatch) => {
  const response = await fetch(`${config.API_URL}/api/token/refresh/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  });

  if (response.status !== 200) {
    console.log('token refresh error, forzing logout');
    dispatch(logout());
    headers.Authorization = undefined;
    return headers;
  }

  const data = await response.json();
  const newToken = data.access;

  const { exp } = parseJwt(newToken);
  //console.log('[refreshReply]', exp, newToken)
  headers.Authorization = `Bearer ${newToken}`;
  dispatch(updateAccessToken({ accessToken: newToken, exp }));

  return headers;
};

function apiHeaders(options, token) {
  const headers = {
    ...options.headers,
  };

  if (str_not_empty(token) && options.auth) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
}

async function doFetch(headers, options, resource, page, appendParams) {
  const params = options.params || '';
  const pageParam = page ? `&page=${page}` : '';
  const extraParams = appendParams ? appendParams : ''

  if (options.upload) {
    headers = {
      'Authorization': headers['Authorization']
    }
  }
  /*console.log(
    '[doFetch]',
    headers,
    `${config.API_URL}${resource}${extraParams}?format=json${pageParam}${params}`
  );*/
  const response = await fetch(
    `${config.API_URL}${resource}${extraParams}?format=json${pageParam}${params}`,
    {
      method: options.method,
      headers,
      body: bodyType(options),
    }
  );
  //console.log('[doFetch] response', response.status, response.statusText);

  const reply = await response.json();
  if (response.status < 200 || response.status > 299) {
    const message = reply.data ? reply.data.message : 'Error interno';
    console.log('[reply message]', JSON.stringify(reply));

    throw new Error(message);
  }

  return reply;
}

function bodyType(options) {
  if (options.upload) {
    return options.body;
  }

  return options.method === 'POST' ? JSON.stringify(options.body) : undefined;
}

export function isSuccess(reply) {
  return reply !== undefined && reply.status && reply.status === 'success';
}
