import React from 'react'

export const ProfileTitles = ({ name, detail1, detail2, online, ...props }) => {
    const label = online ? 'SHOW EN LINEA' : 'SHOW EN VIVO'
    return (
        <div
            {...props}
            className={`text-[#fff] ml-7 ${props.className}`}>
            <div className='text-xs bg-white text-black w-[7rem] text-center py-[.15rem] mb-4 rounded-full'>{label}</div>
            <p className='text-[2.25rem] md:mb-[3.2vh] mb-[2vh] font-interBold leading-[2.7rem] w-[15rem] flex items-end'>
                {name}
            </p>
            <div>
                <p className='text-xl font-interMedium font-medium mb-1'>
                    {detail1}
                </p>
                <p className='text-xl italic mb-[2vh] font-interExtraLight font-thin leading-3'>
                    {!online && detail2}
                </p>
            </div>
        </div>
    )
}

export default ProfileTitles