import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    accessToken: '',
    refreshToken: '',
    exp: 0,
    userId: null,
    email: '',
    name: '',
    photo: '',
    creator_id: null,
    is_creator: false,
    login_session_code: ''
  },
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.exp = action.payload.exp;
      state.userId = action.payload.userId;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.photo = action.payload.photo;
      state.creator_id = null;
      state.is_creator = false;
    },
    logout: (state) => {
      state.accessToken = '';
      state.refreshToken = '';
      state.exp = 0;
      state.userId = null;
      state.email = '';
      state.name = '';
      state.photo = '';
      state.creator_id = null;
      state.is_creator = false;
    },
    updateAccessToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.exp = action.payload.exp;
    },
    setUserAsCreator: (state, action) => {
      state.creator_id = action.payload.creator_id;
      state.is_creator = true;
    },
    deactivateCreator: (state, action) => {
      state.creator_id = null;
      state.is_creator = false;
    },
    updateUserInfo: (state, action) => {
      state.creator_id = action.payload.creator_id;
      state.is_creator = action.payload.is_creator;
      state.photo = action.payload.photo ? action.payload.photo : state.photo;
      state.userId = action.payload.userId
    },
    setLoginSessionCode: (state, action) => {
      state.login_session_code = action.payload.session
      state.email = action.payload.email;
    }
  },
});

export function s_is_auth(state) {
  return state.session.accessToken !== '';
}

export function s_session_token(state) {
  return state.session.accessToken;
}

export function s_refresh_token(state) {
  return state.session.refreshToken;
}

export function s_token_expired(state) {
  return Date.now() >= state.session.exp * 1000;
}

export function s_login_session_code(state) {
  return {
    session_code: state.session.login_session_code,
    email: state.session.email
  }
}

export function s_user_info(state) {
  return {
    userId: state.session.userId,
    name: state.session.name,
    email: state.session.email,
    photo: state.session.photo,
    is_creator: state.session.is_creator,
    creator_id: state.session.creator_id,
  };
}

export const {
  login,
  logout,
  updateAccessToken,
  setUserAsCreator,
  updateUserInfo,
  deactivateCreator,
  setLoginSessionCode
} = sessionSlice.actions;
export default sessionSlice.reducer;
