import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import NewHeader from "../../components/NewHeader";
import { ROOT, SwiperFeedsPath, creatorProfilePath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import ImageBackground from "../../components/Landing/ImageBackground";
import useHeight from "../../utils/height";
import { FooterInfo } from "../../components/Landing";
import { AppleIcon, PlayStoreIcon } from "../../components/Icons";
import { useSelector } from "react-redux";
import { s_user_info } from "../../reducer/SessionReducer";
import { EmbedVideo } from "../../components/Landing/LandingVideo";
import { StoreButton } from "../../components/Buttons";
import { s_payment_id } from "../../reducer/PaymentReducer";
import { CopyButton } from "../../components/NewButtons";
import toast from "react-hot-toast";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { SmartUrl } from "../../utils";


function CodePurchased() {
    const navigate = useNavigate()
    const { name, slug } = useParams();
    const paymentId = useSelector(s_payment_id)
    const { height } = useHeight()
    const [data, setData] = useState(null)
    const [user, setUser] = useState('')
    const [show, setShow] = useState(true)
    const [isLoading, setLoading] = useState(false)
    // const [loading,setLoading] = useState(true)
    const swiperProfile = SwiperFeedsPath(name, slug)

    useEffect(() => {
        GetData()
        setTimeout(() => setShow(false), 4000)
    }, [])

    const GetData = async () => {
        try {
            setLoading(true)
            let res = await getSubscriptionById(slug)
            setData(res)
            setLoading(false)
            let use = await getUser(res?.product?.userId)
            setUser(use)
        } catch (error) {
            setLoading(false)
        }
    }

    async function getSubscriptionById(docId) {
        const docRef = doc(db, "subscriptions", docId);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const subscriptionData = docSnap.data();
                const productId = subscriptionData.productId;
                const productRef = doc(db, 'FeedsVertical', productId);
                const productSnap = await getDoc(productRef);
                const productData = productSnap.data();
                return { ...subscriptionData, product: productData }; // Returns the document data
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
                return null; // Handle the case when the document doesn't exist
            }
        } catch (error) {
            console.error("Error getting document:", error);
            return null; // Handle error appropriately
        }
    }

    async function getUser(uid) {
        const docRef = doc(db, "users", uid);
        try {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const uData = docSnap.data();
                return uData
            } else {
                console.log("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error getting document:", error);
            return null;
        }
    }
    const handleArtistClick = () => {
        navigate(creatorProfilePath(user.userId))
    }

    const handleAppleClick = () => {
        let storeLink = 'https://apps.apple.com/cl/app/mint/id6467195856'
        setTimeout(() => {
            window.location.href = storeLink;
        }, 500);
    }

    const handleAndroidClick = () => {
        let storeLink = 'https://play.google.com/store/apps/details?id=info.mintapp'
        setTimeout(() => {
            window.location.href = storeLink;
        }, 500);
    }
    const videoParentRef = useRef(null)

    if (isLoading) {
        return <CenterSpinner />
    }
    const isVideoPreview = data?.product?.previewType === 'video'
    return (
        <div className="relative font-interRegular bg-white">
            {(isVideoPreview && data?.product?.mp4Preview) && <EmbedVideo
                innerRef={videoParentRef}
                onError={err => { }}
                src={data?.product?.mp4Preview}
                className="absolute top-0 left-0 w-full h-[100dvh] "
                autoplay
                loop
                muted
            />}
            {!isVideoPreview && <ImageBackground src={SmartUrl(data?.product?.cover)} />}
            <div style={{ height: height }}
                className="relative flex flex-col justify-between">
                <NewHeader back to={ROOT} hideMenu />
                <div style={{ background: "linear-gradient(#00000000 , #00000099, #000)" }}
                    className="px-8 absolute bottom-0 pb-16 pt-16 right-0 left-0 text-white">
                    <div className="text-xs text-white rounded-lg text-black mr-3 py-2 mb-2">
                        <strong>Copia el código de compra </strong>
                        aquí para que puedas reproducir este contenido en la App MINT:
                    </div>
                    {/* <div className="text-sm">Código de compra</div> */}
                    <div className="flex items-center mb-5">
                        <div className="font-bold text-3xl mr-3">{data?.code}</div>
                        <CopyButton text={data?.code} />
                    </div>
                    <div className="text-xs mb-4">Descarga la App MINT para acceder a tu contenido</div>
                    <div className="flex">
                        <StoreButton label="App Store" IconComponent={<AppleIcon size={25} />} onClick={handleAppleClick} />
                        <div className="mx-2" />
                        <StoreButton label="Google Play" IconComponent={<PlayStoreIcon size={20} />} onClick={handleAndroidClick} />
                    </div>
                    <div className="border-t w-8 my-6" />
                    <div className="font-bold text-2xl">{data?.product?.title}</div>
                    {/* <div className="italic mb-4">{data.product?.bio}</div> */}
                    <button onClick={handleArtistClick}>@{user?.name?.toLowerCase()}</button>
                </div>
            </div>
        </div>
    );
}

export default CodePurchased;
