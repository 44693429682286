export const TRACK_GOOGLE = 'Google';
export const TRACK_EMAIL = 'Email';
export const TRACK_INSTAGRAM = 'Instagram';
export const TRACK_TIKTOK = 'Tiktok';
export const TRACK_FACEBOOK = 'Facebook';
export const TRACK_PAY_4_REPRO = '4 reproductions';
export const TRACK_PAY_MONTHLY = 'Monthly';
export const TRACK_PAY_CARD = 'Card';
export const TRACK_PAY_DIRECT_PAY = 'Direct pay';
export const TRACK_SMS = 'Sms';
