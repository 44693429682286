import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import NewHeader from "../../components/NewHeader";
import { SwiperFeedsPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import ImageBackground from "../../components/Landing/ImageBackground";
import useHeight from "../../utils/height";
import { FooterInfo } from "../../components/Landing";

function SongPurchased() {
    const { name, slug } = useParams();
    const { height } = useHeight()
    const [data, setData] = useState('')
    const swiperProfile = SwiperFeedsPath(name, slug)

    const { asyncFetch: asyncFetchUser, loading } = useApi(resources['singleCreator'].replace(':id', name), { auto: false });
    const { asyncFetch } = useApi(resources['getPurchaseCode'], { auto: false, method: 'POST' });
    const { data: dataList, loading: isLoading } = useApi(resources['searchBySlug'].replace(':slug', slug));
    const audio = dataList?.data

    useEffect(() => {
        Fetch()
    }, [])

    const Fetch = async () => {
        const usr = await asyncFetchUser()
        usr && setData(usr)
        // await GetCode(usr)
    }
    const GetCode = async (usr) => {
        const body = {
            user: usr?.user?.id,
            item_slug: slug,
            type: 'audio'
        }
        const res = await asyncFetch(body)
        console.log(res)
    }

    if (loading || isLoading) {
        return <CenterSpinner />
    }

    return (
        <div className="relative">
            <ImageBackground src={data?.photo} />
            <div style={{ height: height }}
                className="relative h-[100dvh] flex flex-col justify-between">
                <NewHeader back to={swiperProfile} />
                <FooterInfo
                    name={data?.user?.first_name}
                    title={audio?.title}
                    description={audio?.description}
                    slug={data?.slug_field}
                />
            </div>
        </div>
    );
}

export default SongPurchased;
