import './Buttons.css';
import { SmallSpinner } from './Spinner';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import TiktokIcon from '../assets/tiktok.png';
import InstagramIcon from '../assets/insta.png';

export function Button({ label }) {
  return <button className='btn-basic'>{label}</button>;
}

export function ColoredBtn(props) {
  const botonStyle = {
    backgroundColor: props.color,
    padding: '10px 20px',
    color: '#fff',
    borderRadius: '3rem',
    cursor: 'pointer',
    width: '12.5rem',
  };

  return (
    <button {...props} style={{ ...botonStyle, ...props.style }}>
      {props.children}
    </button>
  );
}

export function GradientBorderBtn({
  children,
  onClick,
  disabled,
  blue,
  loading,
}) {
  const className = blue ? 'blue-gradient-button' : 'gradient-btn';
  return (
    <button
      className={`${className} ${disabled && 'grd-disabled'}`}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {loading ? <SmallSpinner /> : children}
    </button>
  );
}

export function FlatButton({ children, onClick, style }) {
  return (
    <button className={`flat-button`} style={style} onClick={onClick}>
      {children}
    </button>
  );
}

export function BlueButton(props) {
  return <ColoredBtn color='#2155c4' {...props} />;
}

export function GradientButton({ children, onClick, disabled, loading }) {
  return (
    <button
      className='gradient-button'
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? <SmallSpinner /> : children}
    </button>
  );
}

export function RadioButton({ active }) {
  const Radio = active ? MdRadioButtonChecked : MdRadioButtonUnchecked;

  return <Radio className='payment-radio' />;
}


export const StoreButton = ({ label, IconComponent, dark, onClick }) => {
  const bg = dark ? 'bg-black' : 'bg-white'
  const text = dark ? 'text-white' : 'text-black'
  return (
    <button className={`${bg} ${text} flex w-[140px] min-w-[128px] rounded-lg p-1 px-3 items-center leading-tight`} onClick={onClick}>
      {IconComponent}
      <div className="ml-2 text-left">
        <div className="text-[8px]">Descárgalo en</div>
        <div className='text-xs'><strong>{label}</strong></div>
      </div>
    </button>
  )
}