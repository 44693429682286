export default function Steps({ options, selected }) { 
  return (
    <ul className='flex justify-between mt-[4svh] mb-[2svh] text-[0.6rem]'>
      {options.map((value, index) => (
        <li key={index} className={`${selected === index ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
          {index + 1}. {value}
        </li>
      ))}
    </ul>
  )
}