function InputError({ message }) {
  return (
    <div className='flex justify-center items-center'>
      <div className='bg-[#FF0000] w-6 h-6 flex justify-center items-center rounded-full mr-2'>
        <p className='font-interBold'>!</p>
      </div>
      <i className='font-interRegular text-xs'>{message}</i>
    </div>
  );
}

export default InputError;
