import { useEffect, useRef, useState } from 'react'
import './Landing.css'
import { isSafari } from '../../utils';

function VideoBackground({ src, img, onClick }) {
  const videoParentRef = useRef(null)
  const [shouldUseImage, setShouldUseImage] = useState(false);

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0]
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => { })
              .catch(() => {
                // videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  // return shouldUseImage ? (
  //   <img src={src}
  //     style={{ zIndex: -100 }}
  //     className="absolute top-0 left-0 w-full h-[100dvh] object-cover"
  //     alt="Muted Video" />
  // ) : 
  return (
    <EmbedVideo
      onClick={onClick}
      poster={img}
      src={src}
      className="absolute top-0 left-0 w-full h-[100dvh] object-cover"
      innerRef={videoParentRef}
    />
  );
}

export default VideoBackground


var EmbedVideo = function ({ src, className, innerRef, poster, onClick }) {
  return (
    <div
      onClick={onClick}
      ref={innerRef}
      style={{ zIndex: -100 }}
      dangerouslySetInnerHTML={{
        __html: `
        <video
        poster="${poster}"
          loop
          muted
          autoplay
          preload="metadata"
          playsinline
          src="${src}"
          class="${className}"
        />,
      ` }}></div>
  )
}