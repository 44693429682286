import { createSlice } from '@reduxjs/toolkit';

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    creatorId: null,
    option: null,
    option_description: null,
    method: null,
    creatorName: null,
    slugField: null,
    price: null,
    purchaseCode: null,
    content_title: null,
    content_description: null,
    creator_photo: null,
    success: false,
    paymentId: null,
    productId: null,

  },
  reducers: {
    setCreatorId: (state, action) => {
      state.creatorId = action.payload.creatorId;
      state.creatorName = action.payload.creatorName;
      state.slugField = action.payload.slugField;
      state.content_title = action.payload.content_title;
      state.content_description = action.payload.content_description;
      state.creator_photo = action.payload.creator_photo;
      state.inner_photo = action.payload.inner_photo;
      state.productId = action.payload.productId;
    },
    setPaymentOption: (state, action) => {
      state.option = action.payload.option;
      state.purchaseCode = action.payload.purchaseCode;
      state.price = action.payload.price;
      state.option_description = action.payload.option_description;
    },
    setSuccessFlag: (state, action) => {
      state.success = action.payload;
    },
    setPaymentMethod: (state, action) => {
      state.method = action.payload;
    },
    setPaymentId: (state, action) => {
      state.paymentId = action.payload
    }
  },
});

export function s_payment_params(state) {
  return {
    slugField: state.payment.slugField,
    creatorName: state.payment.creatorName,
    creatorId: state.payment.creatorId,
    paymentOption: state.payment.option,
    paymentMethod: state.payment.method,
    purchaseCode: state.payment.purchaseCode,
    price: state.payment.price,
    content_title: state.payment.content_title,
    content_description: state.payment.content_description,
    creator_photo: state.payment.creator_photo,
    inner_photo: state.payment.inner_photo,
    option_description: state.payment.option_description,
    success: state.payment.success,
    productId: state.payment.productId,
  };
}

export function s_payment_id(state) {
  return state.payment.paymentId
}

export const {
  setPaymentOption,
  setCreatorId,
  setSuccessFlag,
  setPaymentMethod,
  setPaymentId
} = paymentSlice.actions;
export default paymentSlice.reducer;
