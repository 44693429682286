export default function Counter({ value = 0, max = 99999, onSubtract, onAdd }) {
  return (
    <div className='flex justify-between items-center w-[64px] h-[26px] border border-[black] rounded-full'>
      <button onClick={onSubtract}
        disabled={value === 0}
        className={`${value == 0 && 'text-[#D9D9D9]'} py-2 px-2`}>-</button>
      <div className={`text-xs ${value === 0 && 'text-[#D9D9D9] '}`}>{value}</div>
      <button onClick={onAdd}
        className={`${value === max && 'text-[#D9D9D9]'} py-2 px-2`}>+</button>
    </div>
  )
}