import React from 'react'
import { useSelector } from 'react-redux';

import NewCard from '../../../components/NewCard'
import { IMAGES } from '../../../assets';
import { BasicButton } from '../../../components/NewButtons';
import { useNavigate } from 'react-router-dom';
import { ROOT, STORE } from '../../../utils/Routes';
import useHeight from '../../../utils/height';
import { s_buy_tickets } from '../../../reducer/TicketsReducer';


export const Success = () => {
    const navigate = useNavigate()
    const { height } = useHeight()
    const { type } = useSelector(s_buy_tickets)

    const handleContinue = () => {
        if (type === 'ONLINE') {
            navigate(`${STORE}/scanner/online`)
        } else {
            navigate(`${STORE}/scanner`)
        }
    }

    const dataCard = {
        photo: IMAGES.TICKETSBUY,
        title: '',
    };

    return (
        <div className='bg-white h-[100vh]'>
            <div style={{ height: height }} >
                <NewCard
                    className={'bg-top h-[45dvh]'}
                    data={dataCard} onBack={() => navigate(ROOT)} />
                <div className='px-6'>
                    <Steps />
                    <div className='text-lg font-interBold mb-[4svh]'>¡Compra exitosa!</div>
                    <p className='text-base text-[#1C1C1C] mb-[3svh]'>{'Enviamos las entradas al correo electrónico del comprador.'}</p>
                    <p className='text-sm text-[#1C1C1C] w-[16rem] italic'>{'Si no recibiste tus entradas contáctanos a contacto@mintapp.info'}</p>
                </div>
                <div className='flex justify-center'>
                    <BasicButton
                        onClick={handleContinue}
                        className={'mt-[6dvh]'}
                    >
                        <div className='text-2xl font-interRegular'>
                            {'Ver entradas'}
                        </div>
                    </BasicButton>
                </div>
            </div>
        </div>
    )
}

function Steps({ step = 3 }) {
    return (
        <ul className='flex justify-between mt-6 mb-4 text-[0.6rem]'>
            <li className={`${step === 1 ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                1. Asistentes
            </li>
            <li className={`${step === 2 ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                2. Medio de Pago
            </li>
            <li className={`${step === 3 ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                3. Entradas
            </li>
        </ul>
    )
}
// export default Success