import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
// import { Pagination } from 'swiper';

import { CREATE, ROOT } from '../../utils/Routes';
import NewHeader from '../../components/NewHeader';

import splash01 from '../../assets/welcome/splash01.png'
import splash02 from '../../assets/welcome/splash02.png'
import splash03 from '../../assets/welcome/splash03.png'
import splash04 from '../../assets/welcome/splash04.png'
import useHeight from '../../utils/height';


/**
 * uses SplashScreens array for render every splashs
 */
function Welcome() {
  const navigate = useNavigate();
  const { height } = useHeight()

  const handleContinue = () => navigate(CREATE);

  return (
    <div className='overflow-hidden text-white font-interRegular' style={{ height }}>
      <Swiper slidesPerView={1} style={{ height }} pagination={true}
      //  modules={[Pagination]}
      >
        {SplashScreens.map((v, i) => (
          <SwiperSlide key={i} style={{ height }}>
            <div className='relative' style={{ height }}/* style={{ height, backgroundSize: 'cover', backgroundBlendMode: 'multiply', background: 'rgba(28, 28, 28, 0.7)', backgroundImage: `url(${v.bgImage})` }}*/>
              <img style={{ height, width: '100%' }} className='absolute top-0 left-0 z-[-10]' src={v.bgImage} />
              <NewHeader back hideMenu />
              <div className='px-8'>
                {v.component}
              </div>
              <div className='absolute bottom-16 left-0 w-full text-center text-sm' onClick={handleContinue}>
                <u><i>Continuar</i></u>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

const Splash01 = () => (
  <div className='mt-[25dvh]'>
    <div className='text-xl font-interBold w-[180px] leading-tight'>¡Bienvenid@ a la comunidad de creadores MINT!</div>
    <div className='text-xs mt-6'>Tu éxito es nuestra misión.</div>
  </div>
)

const Splash02 = () => (
  <div className='mt-[50dvh]'>
    <div>Material inédito</div>
    <div className='text-xs mt-6'>Somos la plataforma perfecta para ofrecer una preventa exclusiva de tu material inédito.</div>
  </div>
)

const Splash03 = () => (
  <div className='mt-[15dvh]'>
    <div>100% del precio de venta</div>
    <div className='mt-6 text-xs'>Te quedas con el 100% del precio de venta de cada pieza que publiques en MINT. </div>
    <div className='mt-[30dvh] text-right'>Controla tu oferta</div>
    <div className='mt-6 text-xs text-right'>Puedes definir la modalidad, precio de venta y duración de tu contenido publicado. </div>
  </div>
)

const Splash04 = () => (
  <div className='mt-[30dvh]'>
    <div className='w-[150px]'>Ganancias: Acceso y Transparencia</div>
    <div className='text-xs mt-6 w-[200px]'>Podrás retirar tus ventas en todo momento.</div>
    <div className='text-xs mt-2 w-[250px]'>Tendrás acceso a comprobantes y a un servicio con total transparencia.</div>
  </div>
)

const SplashScreens = [{
  bgImage: splash01,
  component: <Splash01 />
}, {
  bgImage: splash02,
  component: <Splash02 />
}, {
  bgImage: splash03,
  component: <Splash03 />
}, {
  bgImage: splash04,
  component: <Splash04 />
}]

export default Welcome;
