import { useEffect, useRef, useState } from "react";
import NewHeader from "../../components/NewHeader";
import { ROOT } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import AudioPlayer from "../../components/Landing/AudioPlayer";
import LandingButton from "../../components/Landing/LandingButton";
import Descriptions from "../../components/Landing/Descriptions";
import ImageBackground from "../../components/Landing/ImageBackground";
import PlayButton from "../../components/Landing/PlayButton";
import { useSwiper, useSwiperSlide } from "swiper/react";

/**
 * Song preview
 */
function Song({ loading, data }) {
    const audioRef = useRef(null)
    const swiperSlide = useSwiperSlide();
    const swiper = useSwiper()
    const [playing, setPlaying] = useState(false)

    const handlePlay = () => {
        const ref = audioRef.current.audio.current
        if (ref.paused) {
            ref.play()
        } else {
            ref.pause()
        }
        setPlaying(!ref.paused)
    }

    const handleEnded = () => {
        setPlaying(!audioRef.current.audio.current.paused)
        swiper?.slideNext()
    }


    useEffect(() => {
        const ref = audioRef.current.audio
        if (ref.current !== null) {
            if (!swiperSlide.isActive) {
                ref.current.pause();
                ref.current.currentTime = 0;
                setPlaying(false)
            } else if (!ref.current.ended) {
                ref.current.play()
                setPlaying(true)
            }
        }
    }, [swiperSlide.isActive]);

    if (loading) {
        return <CenterSpinner />
    }

    return (
        <div className="relative">
            <ImageBackground src={data?.profilePhoto} />
            <div className="relative h-[100dvh] flex flex-col justify-between">
                <NewHeader to={ROOT} />
                <div className="px-10 mb-[4vh] text-white">
                    <Descriptions name={data?.userName} title={data?.title} description={data?.description} />
                    <AudioPlayer ref={audioRef}
                        onLoadedData={() => {
                            if (swiperSlide.isActive) {
                                audioRef.current?.audio.current.play()
                            }
                        }}
                        src={data?.file} onEnded={handleEnded} />
                    <div className="flex items-center justify-center">
                        <PlayButton playing={playing} onClick={handlePlay} />
                        <div className='text-white w-full ml-6 leading-tight text-sm font-interExtraLight'>
                            <i>Dale</i>{' '}
                            <strong className='font-interBold'>
                                <u className='underline underline-offset-2'>play</u>
                            </strong>
                            <i> para escuchar 15 segundos de esta canción</i>
                        </div>
                    </div>
                    <div className="mt-6">
                        <LandingButton label="¡Quiero comprarla!"
                        // onClick={handleBuy} 
                        />
                    </div>
                    <div className='text-center mt-[2vh] font-bold'>
                        Sólo disponible en esta plataforma
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Song;
