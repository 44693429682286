import { Link, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import NewHeader from "../../components/NewHeader";
import { LOGIN, ROOT, landingPreviewVideoPlayerPath, paymentSelectionPath, playerPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import LandingButton from "../../components/Landing/LandingButton";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { s_is_auth } from "../../reducer/SessionReducer";
import VideoBackground from "../../components/Landing/VideoBackground";
import { isSafari } from "../../utils";
import { useSwiperSlide } from "swiper/react";
import LandingVideoBG, { EmbedVideo } from "../../components/Landing/LandingVideo";
import { setCreatorId, setPaymentOption } from "../../reducer/PaymentReducer";
import { BackIcon, MuteIcon, UnMuteIcon } from "../../components/Icons";
import { VideoDescriptions } from "../../components/Landing";
import useHeight from "../../utils/height";

/**
 * Landing without login
 */
function VideoLanding({ item }) {
  const navigate = useNavigate()
  const { name, slug } = useParams();
  const dispatch = useDispatch()
  const is_auth = useSelector(s_is_auth)
  const [isLoading, setLoading] = useState(false)
  const [muted, setMuted] = useState(true)
  const videoParentRef = useRef(null)
  const { height } = useHeight()
  const swiperSlide = useSwiperSlide();
  const routeSelection = paymentSelectionPath(name, slug);
  // const player = playerPath(name, slug);


  const { data, loading } = useApi(resources['singleCreator'].replace(':id', name));
  const { data: product } = useApi(resources['searchBySlug'].replace(':slug', item));
  const {
    asyncFetch: fetchCheckout
  } = useApi(resources['checkout'].replace(':slug', item), { auto: false });

  const video = product.data && product?.data

  const handleButton = async () => {
    try {
      setLoading(true)
      const route = landingPreviewVideoPlayerPath(name, video.slug_field)
      if (is_auth) {
        const res = await fetchCheckout()
        console.log('[fetchCheckout]', res)
        if (!res) {
          setLoading(false)
          return
        }
        if (res.next_step !== 'checkout') {
          // window.location.reload();
          navigate(route)
        } else {
          const { price, purchase_code, name: description } = res.options[0];
          dispatch(setCreatorId({
            creatorId: data?.pk,
            creatorName: name,
            slugField: item,
            creator_photo: data?.photo,
            inner_photo: data.inner_photo,
            content_title: video?.title,
            content_description: video?.description,
          }));
          dispatch(setPaymentOption({
            option: 1,
            purchaseCode: purchase_code,
            price: price,
            option_description: description,
          }));
          navigate(routeSelection);
        }
      } else {
        navigate(LOGIN, { state: { route: route } })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('error', error)
    }
  }

  useEffect(() => {
    Play()
  }, [swiperSlide.isActive]);

  const Play = () => {
    try {
      if (videoParentRef.current) {
        const Player = videoParentRef.current?.children[0]
        if (Player) {
          Player.controls = false;
          Player.playsinline = true;
          Player.muted = true;
          Player.setAttribute("muted", "");
          Player.autoplay = true;
          if (swiperSlide?.isActive) {
            Player.play()
          }
          if (!swiperSlide.isActive) {
            Player.pause();
          }
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const ToggleMute = () => {
    const Player = videoParentRef?.current?.children[0]
    if (videoParentRef.current) {
      if (Player) {
        setMuted(!muted)
        Player.muted = !muted
      }
    }
  }

  if (loading) {
    return <CenterSpinner />
  }

  return (
    <div className="relative h-[100svh]" style={{ height: height }}>
      <EmbedVideo
        poster={data?.photo}
        src={video?.file_short_version}
        className="absolute top-0 left-0 w-full h-[100dvh] object-cover"
        innerRef={videoParentRef} />
      <div className="absolute top-0 right-0 left-0">
        <Header onClick={() => { navigate('/') }} muted={muted} onToggleMute={ToggleMute} />
      </div>
      <div className="px-8 absolute bottom-8 right-0 left-0 text-white">
        <VideoDescriptions name={data?.user?.first_name} title={data?.user?.first_name} description={video?.title} />
        <div className="mt-6">
          <LandingButton
            className={'h-[50px]'}
            label="¡Quiero escucharla!"
            loading={isLoading}
            onClick={handleButton}
          />
        </div>
        <div className='text-center mt-[2vh] font-bold'>
          Sólo disponible en esta plataforma
        </div>
      </div>
    </div>
  );
}

const Header = ({ onClick, onToggleMute, muted }) => (
  <div style={{ zIndex: 10000 }} className="p-6 flex items-center justify-between">
    <div onClick={onClick}>
      <BackIcon size={24} />
    </div>
    <button
      onClick={onToggleMute}
      className="bg-[#ffffff30] h-[2rem] w-[2rem] flex items-center justify-center rounded-full">
      {!muted ? <MuteIcon /> : <UnMuteIcon />}
    </button>
  </div>
)

export default VideoLanding;

