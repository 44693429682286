import { useState } from 'react';

export const useValidatorNumber = (max) => {
  const [number, setNumber] = useState('');
  const [messageErrorNumber, setMessageErrorNumber] = useState('');

  const handleChangeNumber = (inputText) => {
    const specialCharactersRegex = /^[0-9]+$/;

    if (max) {
      inputText = inputText.substring(0, max)
    }

    if (
      parseInt(inputText) <= 999999999 ||
      specialCharactersRegex.test(inputText) || inputText.length === 0
    ) {
      setNumber(inputText);
      setMessageErrorNumber('');
    } else {
      setMessageErrorNumber('El número de teléfono no es válido');
      setNumber(inputText);
    }
  };

  return [number, handleChangeNumber, messageErrorNumber];
};
