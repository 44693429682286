import { AppleIcon, PlayStoreIcon } from './Icons';
import { StoreButton } from './Buttons';

import './AppModal.css';
import useHeight from '../utils/height';


export default function AppModal({ onClose }) {
  const { height } = useHeight()
  const handleAppleClick = () => {
    let storeLink = 'https://apps.apple.com/cl/app/mint/id6467195856';
    let appDeepLink = 'mintapp://';
    setTimeout(() => {
      if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) {
        window.location.href = appDeepLink;
      } else {
        window.location.href = storeLink;
      }
    }, 500);
  }

  const handleAndroidClick = () => {
    let storeLink = 'https://play.google.com/store/apps/details?id=info.mintapp';
    let appDeepLink = 'mintapp://';
    setTimeout(() => {
      if (window.matchMedia('(display-mode: standalone)').matches || navigator.standalone) {
        window.location.href = appDeepLink;
      } else {
        window.location.href = storeLink;
      }
    }, 500);
  }

  const handleClose = (e) => {
    if (onClose && e.target === document.getElementById('app-modal')) {
      onClose();
    }
  };

  return (
    <div className='app-modal-background font-interRegular' style={{ height }} id='app-modal' onClick={handleClose}>
      <div style={{ backgroundColor: 'white' }} className='app-modal-container p-6 px-8 text-center relative'>
        <div className='absolute -top-[32px] left-0 right-0 flex justify-center'>
          <img src="/logo512.png" className='h-[80px] w-[80px] rounded-lg' alt="logo mint" />
        </div>
        <div className='pt-8 text-xl font-interBold leading-tight'>
          Disfruta de una experiencia
        </div>
        <div className='text-xl font-interBold leading-none'>
          completa en la aplicación
        </div>
        <div className='text-[12px] mt-4'>
          Puedes comprar contenido en esta página web, pero solo podrás acceder a tus compras en la aplicación
        </div>
        <div className="flex justify-around my-8">
          <StoreButton label="App Store" dark IconComponent={<AppleIcon size={25} color="white" />} onClick={handleAppleClick} />
          <StoreButton label="Google Play" dark IconComponent={<PlayStoreIcon size={20} />} onClick={handleAndroidClick} />
        </div>
        <button className='font-interBold' onClick={onClose}>Ahora no</button>
      </div>
    </div>
  );
}
