function Descriptions({ name, title, description }) {
  return (
    <div>
      <p className='text-[2.5rem] font-interBold leading-[2.8rem] w-[15rem] mb-5'>
        {name}
      </p>
      <div className="mb-5">
        <p className='text-xl font-interMedium font-medium mb-1'>
          {title}
        </p>
        <p className='text-xl italic font-interExtraLight font-thin leading-3'>
          {description}
        </p>
      </div>
    </div>
  )
}

export default Descriptions