import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSwiper, useSwiperSlide } from "swiper/react";
import useHeight from "../../../utils/height";
import { EmbedVideo } from "../../../components/Landing/LandingVideo";
import { VideoDescriptions } from "../../../components/Landing";
import LandingButton from "../../../components/Landing/LandingButton";

import { BasicButton, MuteButton } from "../../../components/NewButtons";
import { BackgroundGradient } from "../../../components/Landing/BackgroundGradient";
import { PaymentModal1 } from "../../../components/modals";
import { usePamynet } from "../usePamynet";
import { UserDataModal } from "../../../components/CardDataModal";
import { PaymentModal } from "../../../components/PaymentModal";
import CheckoutForm from "../../Payment/CheckoutForm";
import { auth } from "../../../firebase";
import { UnMuteIcon } from "../../../components/Icons";
import { isSafari } from "../../../utils";
import ImageBackground from "../../../components/Landing/ImageBackground";
import ampli from "../../../ampli";

/**
 * Landing without login
 */
export function VideoSlide({ item, muted, setMuted, pressed, swiperRef }) {
    const navigate = useNavigate()
    // const { name } = useParams();
    const dispatch = useDispatch()
    const [isLoading, setLoading1] = useState(false)
    const videoParentRef = useRef(null)
    const { height } = useHeight()
    const swiperSlide = useSwiperSlide();
    const [showMute, setShowMute] = useState(false)
    // const [paymentModal, setPaymentModal] = useState(false)
    const swiper = useSwiper()
    const safari = isSafari()

    const {
        paymentModal, setPaymentModal,
        initModal, setinitModal,
        currentProduct, setCurrentProduct,
        loading, setLoading,
        infoModal, setInfoModal,
        authLoading, setAuthLoading,
        codeVisible, setCodeVisible,
        prod,
        StartPayment,
        closeInfoModal,
        handleAuth,
        handleReset,
        CancelPay,
        RePay
    } = usePamynet()
    // console.log(item.user.userId)
    const video = item

    const CloseInfo = () => {
        closeInfoModal()
        const Player = videoParentRef?.current
        if (Player) {
            Player?.play();
        }
    }

    const handleButton = () => {
        const Player = videoParentRef.current
        if (Player) {
            Player?.pause();
        }
        setinitModal(true)
        setCurrentProduct(item)
    }

    useEffect(() => {
        const unsub = Play()
        return () => unsub
    }, [swiperSlide.isActive, videoParentRef.current]);

    const Play = () => {
        try {
            const Player = videoParentRef?.current
            if (Player) {
                Player.muted(muted);
                if (swiperSlide?.isActive) {
                    Player?.play()
                }
                if (!swiperSlide?.isActive) {
                    Player?.pause();
                }
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleMute = () => {
        setMuted(!muted)
        setShowMute(true)
        setTimeout(() => setShowMute(muted ? true : false), 2000)
    }

    useEffect(() => {
        const player = videoParentRef?.current
        if (player) {
            player.muted(muted)
        }
    }, [muted])

    const handleEnded = () => {
        swiperRef.current.swiper.slideNext();
    }

    const handleOnReady = (player) => {
        videoParentRef.current = player
    }

    const closeModal = () => {
        setinitModal(false)
        const Player = videoParentRef.current
        if (Player) {
            Player?.play();
        }
    }

    const mp4_preview = video?.mp4Preview
    const artist_name = video?.user?.name
    let isAudio = item?.previewType == 'audio'

    return (
        <div className="relative" style={{ height: height, }}>
            <div onClick={handleMute}>
                <EmbedVideo
                    innerRef={videoParentRef}
                    onError={err => {
                        console.log('error', err)
                    }}
                    // poster={video?.cover}
                    src={mp4_preview}
                    className="absolute top-0 left-0 w-full h-[100dvh]"
                    onReady={handleOnReady}
                    onEnded={handleEnded}
                    muted={true}
                />
                <BackgroundGradient />
                {/* <div className="absolute top-0 right-0 left-0">
                <Header onClick={() => { navigate('/') }} muted={muted} onToggleMute={ToggleMute} />
            </div> */}
                {isAudio &&
                    <ImageBackground src={item?.banner}
                    // onClick={handleMute}
                    />}
                <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        {showMute && <MuteButton muted={muted} />}
                    </div>
                </div>
                {muted &&
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                        <div className="bg-[#ffffff30]  h-[4rem] w-[4rem] flex items-center justify-center rounded-full ">
                            <UnMuteIcon />
                        </div>
                    </div>
                }
                {/*<div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                    <div className="flex flex-col justify-center items-center">
                        {!playing && isIos() &&
                            <div className="text-white italic mt-[15dvh]">TOCA PARA ESCUCHAR</div>
                        }
                    </div>
                    </div>*/}
            </div>
            <div className="px-8 absolute bottom-8 right-0 left-0 text-white z-[200]">
                <VideoDescriptions type={item?.type} isAudio={item?.isAudio} name={artist_name} title={video?.title} producer={video?.producer} description={video?.description} slug={artist_name} uid={video.userId} />
                <div className="mt-6">
                    <LandingButton
                        label="¡Acceder!"
                        loading={isLoading}
                        onClick={handleButton}
                    />
                </div>
                <div className='text-center mt-[2vh] font-bold'>
                    Sólo disponible en esta plataforma
                </div>
            </div>
            {initModal && <PaymentModal1
                currentProduct={currentProduct}
                loading={loading}
                onClick={StartPayment}
                onClose={closeModal} />}
            {infoModal && (
                <UserDataModal
                    onContinue={handleAuth}
                    onReset={handleReset}
                    onClose={CloseInfo} loading={authLoading}
                />
            )}
            {codeVisible && (
                <PaymentModal onClose={() => setCodeVisible(false)}  >
                    <div>
                        <div style={{ textAlign: 'center', }}>Ya compraste este producto con el siguiente código de compra:</div>
                        <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>{prod?.code}</div>
                        <br />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <BasicButton onClick={CancelPay} style={{ backgroundColor: '#DCDCDC', borderRadius: 10 }} >
                                <div className="text-[16px] font-bold text-black leading-none">Cancelar</div>
                            </BasicButton>
                            <div style={{ width: 20 }} />
                            <BasicButton onClick={RePay} style={{ backgroundColor: '#000', borderRadius: 10 }} >
                                <div className="pl-5 pr-5 text-[14px] font-bold leading-none">Volver a comprar</div>
                            </BasicButton>
                        </div>
                    </div>
                </PaymentModal>
            )}
            {paymentModal && (
                <PaymentModal onContinue={handleAuth} onClose={() => setPaymentModal(false)} loading={authLoading} >
                    <>
                        {<CheckoutForm
                            amount={currentProduct?.price}
                            setPaymentModal={setPaymentModal}
                            uid={auth?.currentUser?.uid}
                            navigate={navigate}
                            creatorName={'creatorName'}
                        // paymentKey={paymentKey}
                        />}
                    </>
                </PaymentModal>
            )}
        </div>
    );
}

export default VideoSlide;

