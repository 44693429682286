import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '../../../../components/Headers';
import {
  InputSimple,
  InputUnderline,
  UploadFile,
} from '../../../../components/NewInputs';
import { BasicButton } from '../../../../components/NewButtons';
import { AddIcon, AlertIcon, CheckCircle, GarbageIcon } from '../../../../components/Icons';
import { empty, int } from '../../../../utils';
import { Icons } from '../../../../assets';
import { PaymentCard } from '../../../../components';
import { useApi } from '../../../../network/hooks/useApi';
import { resources } from '../../../../network/resources';
import { useSelector } from 'react-redux';
import { s_session_token } from '../../../../reducer/SessionReducer';
import config from '../../../../config';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import useHeight from '../../../../utils/height';

import 'react-datepicker/dist/react-datepicker.css';


const TicketCategories = {
  "1": {
    label: 'Preventa 1',
    price: '',
    quantity: ''
  },
  "2": {
    label: 'General',
    price: '',
    quantity: ''
  },
  "3": {
    label: 'VIP',
    price: '',
    quantity: ''
  }
}

const OnlineCategories = {
  "1": {
    label: 'Preventa 1',
    price: '',
    quantity: ''
  },
  "2": {
    label: 'Preventa 2',
    price: '',
    quantity: ''
  },
  "3": {
    label: 'General',
    price: '',
    quantity: ''
  }
}

function Tickets({ online }) {
  const navigate = useNavigate();
  const { height } = useHeight()
  const [busy, setBusy] = useState(false)
  const [step, setStep] = useState(1);
  const [eventName, setEventName] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [place, setPlace] = useState('');
  const [address, setAddress] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [ticketImg, setTicketImg] = useState(null);
  const [nameAccount, setNameAccount] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);

  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [accountEmail, setAccountEmail] = useState('')
  const [paypalEmail, setPaypalEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [wnPhone, setWnPhone] = useState('')
  const [wnEmail, setWNEmail] = useState('')
  const [guests, setGuests] = useState([
    {
      name: '',
      email_to: '',
      email_from: '',
    },
  ]);

  const [categories, setCategories] = useState(online ? OnlineCategories : TicketCategories)

  // const FormatTime = (date, time) => {
  //   var ts = moment(date + time, "YYYY-MM-DD HH:mm").valueOf();
  //   return moment(ts).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
  // }
  const FormatTime = (date) => {
    // return moment(date).format('YYYY-MM-DD HH:mm:ss.SSSSSS');
    return moment(date).format('HH:mm:ss.SSS000');
  }

  const { asyncFetch, error } = useApi(resources['createTickets'], {
    auto: false,
    method: 'POST',
    upload: true,
  });
  const { asyncFetch: asyncFetchpPayments, error: paymentsError } = useApi(resources['createPayment'], {
    auto: false,
    method: 'POST',
    upload: true,
  });

  const accessToken = useSelector(s_session_token);

  /*async function postpayment(data) {
    // let body = JSON.stringify({ data })
    const reply = await fetch(`${config.API_URL}/users/payment/creator`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: data
    });
    return await reply.json();
  }*/

  const Pay = async () => {
    const form = new FormData();
    if (selectedCard == 0) {
      form.append("payment_type", 'bank')
      form.append('bank_account_owner', nameAccount)
      form.append('bank_account', bankAccount)
      form.append('bank_name', bankName)
      form.append('bank_account_type', accountType)
      form.append('payment_email', accountEmail)
      form.append('payment_phone', phone)
    }
    if (selectedCard == 1) {
      form.append('payment_type', 'paypal')
      form.append('payment_email', paypalEmail)

    }
    if (selectedCard == 2) {
      form.append('payment_type', 'western union')
      form.append('bank_account_owner', fullName)
      form.append('payment_email', wnEmail)
      form.append('payment_phone', wnPhone)
    }

    // for (var pair of form.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    // const body = JSON.stringify(selectedOption)
    const reply = await asyncFetchpPayments(form);
    // console.log('reply', reply, 'reply')
    if (reply) {
      return reply
    }
    else {
      return false
    }

  }

  const handlePressCard = (idx) => {
    setSelectedCard(idx)
    setBankName('')
    setNameAccount('')
    setBankAccount('')
    setAccountType('')
    setAccountEmail('')
    setPhone('')
    setPaypalEmail('')
    setFullName('')
    setWnPhone('')
    setWNEmail('')
    setCity('')
    setCountry('')
  }
  // console.log('FormatTime(date)', FormatTime(date))
  const handleUpload = async () => {
    try {
      setBusy(true)
      const categories_values = Object.values(categories)
      const available_tickets = categories_values.reduce((t, v) => t + int(v.quantity), 0)
      const wholesales = categories_values.map(v => ({
        price: v.price,
        number_of_tickets: v.quantity
      }))
      const form = new FormData();

      form.append('organizer', organizer);
      // form.append('start_time', FormatTime(moment(date).format('YYYY-MM-DD'), time));
      form.append('start_time', FormatTime(date));
      form.append('name', eventName);
      form.append('location', place);
      form.append('address', address);
      form.append('price', 1000);
      //form.append('available_tickets', available_tickets);
      form.append('wholesales', wholesales)
      form.append('invitations', guests);
      form.append('cover', ticketImg);
      form.append('is_online_event', online)

      console.log('[handleUpload]', address)
      const reply = await asyncFetch(form);
      console.log('[handleUpload] response:', reply);
      let pay = await Pay()
      if (reply && pay) {
        navigate('/crear/uploadsuccess')
      }
    } catch (e) {
      console.error('[handleUpload]', e)
    } finally {
      setBusy(false)
    }
  };

  const handleBack = () => {
    if (step === 1) {
      navigate(-1);
    } else {
      setStep(Math.max(1, step - 1));
    }
  };

  const handleAddGuest = () => {
    setGuests([...guests, { name: '', email_to: '', email_from: '' }]);
  };

  const handleGuestChange = (index, field, value) => {
    const updatedGuests = [...guests];
    updatedGuests[index][field] = value;
    setGuests(updatedGuests);
  };

  const handleRemoveGuest = (index) => {
    const updatedGuests = [...guests];
    updatedGuests.splice(index, 1);
    setGuests(updatedGuests);
  };

  const handlePhoto = (event) => {
    const file = event.target.files[0];
    setTicketImg(file);
  };

  //used to change style of input date and time on the first screen when is focused
  const [isFocusedDate, setIsFocusedDate] = useState(false);
  const [isFocusedTime, setIsFocusedTime] = useState(false);

  const handleFocusDate = () => {
    setIsFocusedDate(true);
  };
  const handleFocusTime = () => {
    setIsFocusedTime(true);
  };

  return (
    <div style={{ height: height }}
      className='h-screen bg-white overflow-auto flex flex-col'>
      <Header title={''} onBack={handleBack} />
      <div className='px-8 pt-4 flex-1 flex flex-col'>
        {error || paymentsError && <div className='bg-red-200 text-xs p-2 rounded-lg flex items-center mb-4'>
          <div className='mr-4'>
            <AlertIcon />
          </div>
          En este momento el sistema se encuentra muy ocupado favor intente mas tarde.
        </div>}
        <div className='text-2xl font-bold font-interRegular mb-2'>{online ? "Shows en Línea" : "Entradas"}</div>
        <Steps step={step} />
        {step === 1 && (
          <StepOne
            // onContinue={handleUpload1}
            onContinue={() => setStep(2)}
            eventName={eventName}
            onEventNameChange={(e) => setEventName(e.target.value)}
            organizer={organizer}
            onEventOrganizerChange={(e) => setOrganizer(e.target.value)}
            place={place}
            onPlaceChange={(e) => setPlace(e.target.value)}
            address={address}
            onAddressChange={(e) => setAddress(e.target.value)}
            date={date}
            onDateChange={(d, e) => setDate(d)}
            time={time}
            onTimeChange={(e) => setTime(e.target.value)}
            handlePhoto={handlePhoto}
            ticketImg={ticketImg}
            handleFocusDate={handleFocusDate}
            isFocusedDate={isFocusedDate}
            handleFocusTime={handleFocusTime}
            isFocusedTime={isFocusedTime}
            online={online}
          />
        )}
        {step === 2 && (
          <StepTwo
            onContinue={() => setStep(3)}
            categories={categories}
            setCategories={setCategories}
          />
        )}
        {step === 3 && (
          <StepThree
            onContinue={() => setStep(4)}
            handleAddGuest={handleAddGuest}
            handleGuestChange={handleGuestChange}
            handleRemoveGuest={handleRemoveGuest}
            guests={guests}
          />
        )}
        {/* {step === 4 && (
          <StepFour
            onContinue={handleUpload}
            nameAccount={nameAccount}
            onNameAccountChange={(e) => setNameAccount(e.target.value)}
            bankAccount={bankAccount}
            onBankAccountChange={(e) => setBankAccount(e.target.value)}
            bankName={bankName}
            onBankNameChange={(e) => setBankName(e.target.value)}
            accountType={accountType}
            onAccountTypeChange={(e) => setAccountType(e.target.value)}
            phone={phone}
            handlePressCard={handlePressCard}
            selectedCard={selectedCard}
            onPhoneChange={(e) => setPhone(e.target.value)}
          />
        )} */}

        {step === 4 && <StepFour
          nameAccount={nameAccount}
          onNameAccountChange={e => setNameAccount(e.target.value)}
          bankAccount={bankAccount}
          onBankAccountChange={e => setBankAccount(e.target.value)}
          bankName={bankName}
          onBankNameChange={e => setBankName(e.target.value)}
          accountType={accountType}
          onAccountTypeChange={e => setAccountType(e.target.value)}
          onContinue={handleUpload}
          // onContinue={Pay}
          handlePressCard={handlePressCard}
          selectedCard={selectedCard}
          onAccountEmailChange={e => setAccountEmail(e.target.value)}
          accountEmail={accountEmail}
          onPaypalEmailChange={e => setPaypalEmail(e.target.value)}
          paypalEmail={paypalEmail}
          setPhone={e => setPhone(e.target.value)}
          phone={phone}
          onChangeFullName={e => setFullName(e.target.value)}
          fullName={fullName}
          onWnPhoneChange={e => setWnPhone(e.target.value)}
          wnPhone={wnPhone}
          onWNEmailChange={e => setWNEmail(e.target.value)}
          wnEmail={wnEmail}
          onCityChange={e => setCity(e.target.value)}
          city={city}
          onCountryChange={e => setCountry(e.target.value)}
          country={country}
          loading={busy}
        />}
      </div>
    </div>
  );
}



function StepOne({
  onContinue,
  handlePhoto,
  eventName,
  onEventNameChange,
  organizer,
  onEventOrganizerChange,
  place,
  onPlaceChange,
  address,
  onAddressChange,
  date,
  onDateChange,
  time,
  onTimeChange,
  ticketImg,
  handleFocusDate,
  isFocusedDate,
  handleFocusTime,
  isFocusedTime,
  online
}) {
  const disabled =
    empty(eventName) ||
    empty(organizer) ||
    (!online && empty(place)) ||
    (!online && empty(address)) ||
    !date ||
    !ticketImg;

  // empty(time) ||
  return (
    <div className='flex-1 flex flex-col justify-between'>
      <div>
        <p className='text-base font-bold font-interRegular'>
          {online ? "Información para crear las entradas*" : "Información para crear el evento *"}
        </p>
        <InputUnderline
          placeholder='Nombre del Evento'
          value={eventName}
          onChange={onEventNameChange}
        />
        <InputUnderline
          placeholder='Organizador'
          value={organizer}
          onChange={onEventOrganizerChange}
        />

        {!online && <InputUnderline
          placeholder='Lugar'
          value={place}
          onChange={onPlaceChange}
        />}
        {!online && <InputUnderline
          placeholder='Dirección'
          value={address}
          onChange={onAddressChange}
        />}
        <div className='flex w-full focus:outline-none border-b border-gray-300 text'>
          <DatePicker
            placeholderText="Fecha y Horario"
            // dateFormat="yyyy-MM-dd mm:ss"
            selected={date}
            onChange={onDateChange}
            showTimeSelect
            dateFormat="Pp"
            className="bg-transparent text-black h-8 pb-4 pt-6 text-sm"
          />
        </div>
        {/* <InputUnderline
            type={`date`}
            className={'mr-5'}
            placeholder='Fecha'
            value={date}
            onChange={onDateChange}
            onFocus={handleFocusDate}
          /> */}
        {/* <InputUnderline
            type={`time`}
            placeholder='Horario'
            value={time}
            className={' w-2/4 ml-2'}
            onChange={onTimeChange}
            onFocus={handleFocusTime}
          /> */}
        <div className='mt-4'>
          <p className='font-bold font-interRegular text-base'>Portada *</p>
          <div className='flex flex-row justify-between'>
            <div className='text-gray-400 my-4'>
              <div>Peso: 50 KB</div>
            </div>
            {ticketImg !== null && <CheckCircle />}
          </div>
          <UploadFile
            className='w-full'
            handleFileChange={handlePhoto}
            img
            label='Subir imágen'
          />
        </div>
      </div>

      <div className='mt-7 mb-8'>
        <BasicButton
          className='w-full text-base h-12'
          onClick={onContinue}
          disabled={disabled}
        >
          Continuar
        </BasicButton>
      </div>
    </div>
  );
}

function StepTwo({
  onContinue,
  categories,
  setCategories
}) {
  const disabled = Object.values(categories).some(v => empty(v.price) || empty(v.quantity))

  const handlePriceChange = (e, key) => {
    categories[key].price = e.target.value
    setCategories({ ...categories })
  }

  const handleQuantityChange = (e, key) => {
    categories[key].quantity = e.target.value
    setCategories({ ...categories })
  }

  return (
    <div className='flex-1 flex flex-col justify-between'>
      <div>
        <p className='text-base font-bold font-interRegular'>Categoría</p>
        {Object.keys(categories).map(key => (
          <TicketItem
            key={key}
            label={categories[key].label}
            price={categories[key].price}
            quantity={categories[key].quantity}
            onPriceChange={e => handlePriceChange(e, key)}
            onQuantityChange={e => handleQuantityChange(e, key)} />
        ))}
      </div>
      <div className='mt-7 mb-8'>
        <BasicButton
          className='w-full text-base h-12'
          onClick={onContinue}
          disabled={disabled}
        >
          Continuar
        </BasicButton>
      </div>
    </div>
  );
}

const TicketItem = ({ label, price, quantity, onPriceChange, onQuantityChange, onEdit, onAdd }) => (
  <div className='ml-4 mt-4'>
    <div className='flex justify-between items-center'>
      <div className='font-interRegular text-sm'>
        {label} {/*<span className='inline-block ml-3' onClick={onEdit}><EditIcon /></span>*/}
      </div>
      {/*<CircleButton onClick={onAdd} />*/}
    </div>
    <InputUnderline
      type='number'
      placeholder='Precio'
      value={price}
      onChange={onPriceChange}
    />
    <InputUnderline
      type='number'
      placeholder='Cantidad de Entradas'
      value={quantity}
      onChange={onQuantityChange}
    />
  </div>
)

function StepThree({
  onContinue,
  handleAddGuest,
  handleGuestChange,
  handleRemoveGuest,
  guests,
}) {
  const isAnyFieldEmpty = () => {
    return guests.some(
      // name: '',
      // email_to: '',
      // email_from: '',
      (guest) =>
        guest.name.trim() === '' ||
        guest.email_to.trim() === '' ||
        guest.email_from.trim() === ''
    );
  };

  return (
    <div className='flex-1 flex flex-col justify-between'>
      <div>
        <p className='text-base font-bold font-interRegular'>
          Lista de invitados con acceso gratuito
        </p>

        {guests.map((guest, index) => (
          <div key={index} className='mt-7'>
            <div className='flex justify-between'>
              <p className='font-interRegular text-sm'>Invitado {index + 1}</p>
              <button onClick={() => handleRemoveGuest(index)}>
                <GarbageIcon />
              </button>
            </div>
            <div className='ml-4'>
              <InputUnderline
                placeholder='Nombre'
                value={guest.name}
                onChange={(e) =>
                  handleGuestChange(index, 'name', e.target.value)
                }
              />
              <InputUnderline
                placeholder='Correo electrónico o Instagram'
                value={guest.email_to}
                onChange={(e) =>
                  handleGuestChange(index, 'email_to', e.target.value)
                }
              />
              <InputUnderline
                placeholder='WhatsApp o Correo del organizador'
                value={guest.email_from}
                onChange={(e) =>
                  handleGuestChange(index, 'email_from', e.target.value)
                }
              />
            </div>
          </div>
        ))}
        <button className='flex items-center my-4' onClick={handleAddGuest}>
          <AddIcon />
          <i className='font-interRegular ml-4'>
            Presiona aquí para agregar más invitados
          </i>
        </button>
      </div>
      <div className='mb-8'>
        <BasicButton
          className='w-full text-base h-12'
          onClick={onContinue}
        // disabled={isAnyFieldEmpty()}
        >
          Continuar
        </BasicButton>
      </div>
    </div>
  );
}

// function StepFour({
//   onContinue,
//   bankAccount,
//   onBankAccountChange,
//   nameAccount,
//   onNameAccountChange,
//   bankName,
//   onBankNameChange,
//   accountType,
//   onAccountTypeChange,
//   phone,
//   onPhoneChange,
// }) {
//   const disabled =
//     empty(nameAccount) ||
//     empty(bankAccount) ||
//     empty(bankName) ||
//     empty(accountType) ||
//     empty(phone);

//   return (
//     <div className='flex-1 flex flex-col justify-between'>
//       <div>
//         <p className='text-base font-bold font-interRegular'>
//           Datos bancarios y de contacto del responsable de repartir las
//           ganancias *
//         </p>
//         <InputUnderline
//           placeholder='Nombre de la cuenta bancaria'
//           value={nameAccount}
//           onChange={onNameAccountChange}
//         />
//         <InputUnderline
//           type='number'
//           placeholder='N° cuenta bancaria'
//           value={bankAccount}
//           onChange={onBankAccountChange}
//         />
//         <InputUnderline
//           placeholder='Banco'
//           value={bankName}
//           onChange={onBankNameChange}
//         />
//         <InputUnderline
//           placeholder='Tipo de Cuenta'
//           value={accountType}
//           onChange={onAccountTypeChange}
//         />
//         <InputUnderline
//           type='number'
//           placeholder='Número telefónico'
//           value={phone}
//           onChange={onPhoneChange}
//         />
//       </div>
//       <div className='mt-7 mb-8'>
//         <BasicButton
//           className='w-full text-base h-12'
//           onClick={onContinue}
//           disabled={disabled}
//         >
//           Continuar
//         </BasicButton>
//       </div>
//     </div>
//   );
// }

const paymentCards = [
  { id: '1', icon: Icons.tb },
  { id: '2', icon: Icons.paypal },
  { id: '3', icon: Icons.wunion },
]

function StepFour({ onNameAccountChange, onBankAccountChange, onAccountTypeChange, nameAccount,
  onAccountEmailChange, accountEmail, setPhone, phone,
  onPaypalEmailChange, paypalEmail, loading,
  accountType, bankAccount, bankName, onBankNameChange,
  onChangeFullName, fullName, onWNEmailChange, wnEmail, onCityChange, city, onCountryChange, country,
  onWnPhoneChange, wnPhone,
  handlePressCard, onContinue, selectedCard }) {
  const disabled = empty(bankName) || empty(nameAccount) || empty(accountType) || empty(bankAccount) || empty(accountEmail) || empty(phone)
  const disabled1 = empty(paypalEmail)
  const disabled2 = empty(fullName) || empty(wnEmail) || empty(country) || empty(city) || empty(wnPhone)

  return (
    <div>
      <div className='font-bold font-interRegular text-base'>
        Datos bancarios y de contacto *
      </div>
      <div className='text-xs text-[#BFBFBF] mt-2 mb-4'>
        Datos del responsable de repartir las ganancias de acuerdo a los contratos vigentes.
      </div>
      <div className='flex justify-between mb-4' >
        {paymentCards.map((item, idx) => {
          return (
            <PaymentCard key={idx} onClick={() => handlePressCard(idx)} selected={selectedCard == idx} icon={item.icon} />
          )
        })}
      </div>
      {selectedCard == 0 &&
        <>
          <InputSimple placeholder='Nombre de la cuenta bancaria' value={nameAccount} onChange={onNameAccountChange} />
          <InputSimple placeholder='N° cuenta bancaria' value={bankAccount} onChange={onBankAccountChange} />
          <InputSimple placeholder='Banco' value={bankName} onChange={onBankNameChange} />
          <InputSimple placeholder='Tipo de Cuenta' value={accountType} onChange={onAccountTypeChange} />
          <InputSimple placeholder='Correo electrónico' value={accountEmail} onChange={onAccountEmailChange} />
          <InputUnderline placeholder='Número telefónico' value={phone} onChange={setPhone} />
          <div className='mt-7 mb-8'>
            <BasicButton
              className='w-full text-base h-12' onClick={onContinue} loading={loading} disabled={disabled}>
              Continuar
            </BasicButton>
          </div>
        </>}
      {selectedCard == 1 &&
        <>
          <InputUnderline placeholder='Correo electrónico' value={paypalEmail} onChange={onPaypalEmailChange} />
          <div className='mt-7 mb-8'>
            <BasicButton
              className='w-full text-base h-12' onClick={onContinue} loading={loading} disabled={disabled1}>
              Continuar
            </BasicButton>
          </div>
        </>}
      {selectedCard == 2 &&
        <>
          <InputSimple placeholder='Nombre Completo' value={fullName} onChange={onChangeFullName} />
          <InputSimple placeholder='Correo electrónico' value={wnEmail} onChange={onWNEmailChange} />
          <InputSimple placeholder='Número Telefónico' value={wnPhone} onChange={onWnPhoneChange} />
          <InputSimple placeholder='Ciudad' value={city} onChange={onCityChange} />
          <InputUnderline placeholder='País' value={country} onChange={onCountryChange} />
          <div className='mt-7 mb-8'>
            <BasicButton
              className='w-full text-base h-12' onClick={onContinue} loading={loading} disabled={disabled2}>
              Continuar
            </BasicButton>
          </div>
        </>}
    </div>
  )
}

function Steps({ step }) {
  return (
    <ul className='flex justify-between my-6 text-[0.6rem]'>
      <li
        className={`${step === 1 ? 'font-semibold' : 'text-black/[.3]'
          } text-[.62rem] font-interRegular`}
      >
        1. Información
      </li>
      <li
        className={`${step === 2 ? 'font-semibold' : 'text-black/[.3]'
          } text-[.62rem] font-interRegular`}
      >
        2. Precios
      </li>
      <li
        className={`${step === 3 ? 'font-semibold' : 'text-black/[.3]'
          } text-[.62rem] font-interRegular`}
      >
        3. Invitados
      </li>
      <li
        className={`${step === 4 ? 'font-semibold' : 'text-black/[.3]'
          } text-[.62rem] font-interRegular`}
      >
        4. Pagos
      </li>
    </ul>
  );
}

export default Tickets;
