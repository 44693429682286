import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Header } from "../../../../components/Headers";

import merch1 from '../../../../assets/tmp/image 26.png';
import merch2 from '../../../../assets/tmp/image 29.png';
import merch3 from '../../../../assets/tmp/image 36.png';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Steps from "../../../../components/Payment/Steps";
import { IMAGES } from "../../../../assets";
import NewCard from "../../../../components/NewCard";
import { useApi } from "../../../../network/hooks/useApi";
import { resources } from "../../../../network/resources";
import Step3 from "./Step3";
import Success from "./Success";
import { merchVoucherPath } from "../../../../utils/Routes";


const Cart = [{
  thumbnail: merch1,
  title: 'Remera Gira 2023',
  price: '$ 3.000 CLP'
}, {
  thumbnail: merch2,
  title: 'Buzo Gira 2023',
  price: '$ 5.000 CLP'
}, {
  thumbnail: merch3,
  title: 'Gorra Shisosa 2023',
  price: '$ 1.500 CLP'
}]


export default function MerchCheckout() {
  const { name } = useParams()
  const navigate = useNavigate()
  const [step, setStep] = useState(0)

  const { data, loading } = useApi(resources['singleCreator'].replace(':id', name));

  const handleVoucher = () => {
    navigate(merchVoucherPath(name))
  }

  const nextStep = () => setStep(Math.min(step + 1, 3))
  const prevStep = () => setStep(Math.max(step - 1, 0))

  //console.log(data)

  const dataCard = {
    photo: data.inner_photo,
    title: '',
  };

  return (
    <div className="bg-white w-full h-full">
      {step === 0 && <Header />}
      {step > 0 && <NewCard className={'h-[45svh] bg-top'} data={dataCard} onBack={step > 0 && prevStep} />}
      <div className="px-10">
        {step > 0 && <div>
          <Steps options={['Datos de entrega', 'Medio de pago', 'Comprobante']} selected={step - 1} />
        </div>}
        {step === 0 && <Step1 data={Cart} onContinue={nextStep}/>}
        {step === 1 && <Step2 onContinue={nextStep} />}
        {step === 2 && <Step3 onContinue={nextStep} />}
        {step === 3 && <Success onContinue={handleVoucher} />}
      </div>
    </div>
  )
}

