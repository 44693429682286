import React, { useEffect, useRef, useState } from 'react';
import './musicCard.css';
import { Icons } from '../../assets';
import Spinner from '../Spinner';
// import { ampli } from '../../ampli';
import tempAvatar from '../../assets/temp-avatar.png';
import ReactGA from 'react-ga4';

export const MusicCard = ({ audio, handleClick, paid, name }) => {
  const audioRef = useRef();
  const executedRef1 = useRef(false);
  const executedRef2 = useRef(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  let item = audio[0];

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('timeupdate', updateProgress);

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
    };
  }, []);

  const updateProgress = () => {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);
    const progress = (audio.currentTime / audio.duration) * 100;
    setProgress(progress);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const HandlePlay = () => {
    if (!paid) {
      handleClick();
    } else {
      const audio = audioRef.current;
      setIsPlaying(!isPlaying);
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();

        ReactGA.event('song_played', {
          evento_name: 'song_played',
          item_name: item?.title,
          creator_id: item?.creator,
          creator_name: name,
        });

        //amplitude tracking
        // ampli.songPlayed({
        //   creator_name: name,
        //   creator_id: item?.creator,
        //   name: item?.title,
        // });
      }
    }
  };

  useEffect(() => {
    if (currentTime > 15 && !executedRef1.current) {
      ReactGA.event('song_listened_15seg', {
        evento_name: 'song_listened_15seg',
        item_name: item?.title,
        creator_id: item?.creator,
        creator_name: name,
      });

      //amplitude tracking
      //   ampli.songListened({
      //     creator_name: name,
      //     creator_id: item?.creator,
      //     name: item?.title,
      //     seconds: 15,
      //   });

      //   ampli.songListened15Seg({
      //     creator_name: name,
      //     creator_id: item?.creator,
      //     name: item?.title,
      //   });
      executedRef1.current = true;
    }
  }, [currentTime]);

  useEffect(() => {
    if (currentTime > 30 && !executedRef2.current) {
      ReactGA.event('song_listened_30seg', {
        evento_name: 'song_listened_30seg',
        item_name: item?.title,
        creator_id: item?.creator,
        creator_name: name,
      });
      //amplitude tracking
      // ampli.songListened({
      //   creator_name: name,
      //   creator_id: item?.creator,
      //   name: item?.title,
      //   seconds: 30,
      // });

      // ampli.songListened30Seg({
      //   creator_name: name,
      //   creator_id: item?.creator,
      //   name: item?.title,
      // });
      executedRef2.current = true;
    }
  }, [currentTime]);

  return (
    <div onClick={HandlePlay}>
      <div className='image_wrapper_new_audio_container'>
        <audio ref={audioRef} src={item?.file} />
        <img
          src={item?.photo ?? tempAvatar}
          className='image_wrapper_new_audio'
        />
        {isPlaying && (
          <img src={Icons.AudioSource} className='playing_icon_audio' />
        )}
        <div className='new_audio_text'>
          <div className='new_audio_title'>{item?.title}</div>
          <div className='audio_player_song_description'>
            {item?.description}
            <p>{formatTime(item?.duration_seconds)} min</p>
          </div>
        </div>
      </div>
      <div className='new_audio_player_slider_div'>
        <div
          className='new_audio_player_slider'
          style={{ width: '100%', height: '6px', background: '#ccc' }}
        >
          <div
            className='new_audio_player_slider'
            style={{
              width: `${progress}%`,
              height: '100%',
              background: '#0F00C1',
            }}
          ></div>
        </div>
        <div className='audio_player_timer_container'>
          <div className='new_audio_player_song_duration light_text'>
            {formatTime(currentTime)}
          </div>
          <div className='new_audio_player_song_duration light_text'>
            {formatTime(item?.duration_seconds)}
          </div>
        </div>
      </div>
    </div>
  );
};
