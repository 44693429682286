import { CardElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ButtonBlue, ButtonGrey } from '../../components/NewButtons';
import { useEffect, useState } from 'react';
import { usePayments } from './hooks';
import { purchasePath } from '../../utils/Routes';
import config from '../../config';

const CheckoutForm = ({ amount, uid, loading, setPaymentModal, creatorName, navigate, title }) => {
    const [errorMessage, setErrorMessage] = useState(false)
    const [complete, setComplete] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const { SaveToDb } = usePayments()

    const stripe = useStripe();
    const elements = useElements();
    let price = amount?.replace(/\D/g, '');

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }
        const paymentElement = elements.getElement(PaymentElement);
        paymentElement?.on('change', (event) => {
            console.log(event.error ? event.error.message : null);
        });

        return () => {
            paymentElement?.off('change');
        };
    }, [stripe, elements]);

    const handleChange = (event) => {
        console.log('event', event)
        setComplete(event.complete)
        setErrorMessage(event.error ? event.error.message : '');
    };

    const OnSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(PaymentElement),
            });
            if (error) {
                setLoading(false);
                setErrorMessage(error.message);
                return;
            }
            if (paymentMethod) {
                await handlePay(paymentMethod.id)
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error)
        }
    }

    const handlePay = async (id) => {
        let url = `${config.FUNCTIONS_URL}/createCharge`
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentMethodId: id,
                    uid: uid,
                    amount: price,
                    returnUrl: 'http://localhost:3000',
                }),
            });
            const responseData = await response.json();
            if (responseData.success) {
                let id = await SaveToDb(uid, price)
                setPaymentModal(false)
                navigate(purchasePath(creatorName, id))
                setLoading(false);
            } else {
                // console.log(responseData.error);
                setLoading(false);
            }
        } catch (error) {
            console.log('Error processing payment', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const CARD_ELEMENT_OPTIONS = {
        iconStyle: "solid",
        hidePostalCode: true,
        placeholder: 'Catr',
        style: {
            base: {
                iconColor: "blue",
                color: "#000",
                fontSize: "16px",
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: "antialiased",
                "::placeholder": {
                    color: "#CFD7DF",
                }
            },
            invalid: {
                color: "#e5424d",
                ":focus": {
                    color: "#303238"
                }
            }
        }
    };

    return (
        <form style={{ zIndex: 100000 }} onSubmit={OnSubmit}>
            <div style={{}} className='mb-8 text-lg font-interBold' >Información de Pago</div>
            {/* <div style={{}} className='mb-2 text-sm font-interRegular' >Número de Tarjeta</div> */}
            {/* <div style={{}} className='mb-2 text-sm font-interRegular' >Fecha de Expiración</div> */}
            {/* <div style={{}} className='mb-2 text-sm font-interRegular' >Código de Seguridad (CVC)</div> */}
            <div className='mb-2 text-sm font-interRegular' >Detalles de tarjeta</div>
            <div style={{ borderWidth: 1, borderRadius: 6 }} className='py-2 px-1'>
                <CardElement
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleChange} />
            </div>
            <div className='mt-8' />
            <ButtonBlue type='submit'
                disabled={!complete || isLoading}
                loading={loading || isLoading}
                label="Pagar"
            />
            <div className='mt-2' />
            {errorMessage && <h1 style={{ color: 'red' }}>{errorMessage}</h1>}
        </form>
    );
};

export default CheckoutForm;