import { Icons } from "../../assets";
import { ArrowButton } from "../NewButtons";
import { WebPayButton } from "../WebpayButton";


export default function PaymentStep({ onDelete, onContinue, cards, type, loading, isCard }) {
  return (
    <div>
      <div className='mt-[1vh]'>
        {isCard ?
          <Option
            className='pb-[3vh] border-b-2'
            title={'Pagar con tarjeta registrada'}
            text={
              <div onClick={onDelete} className='flex'>
                {`${String(cards[0]?.CreditCardType).toUpperCase()} ....${cards[0]?.CreditCardLastNumbers}`}
                <img className='ml-2 h-[18px]' src={Icons.bin} />
              </div>
            }
            loading={loading && type == 2}
            onClick={() => onContinue(2)}
          />
          : <Option
            className='pb-[3vh] border-b-2'
            title={'Registrar tarjeta '}
            text={'¡Facilita tus próximos pagos!'}
            loading={loading && type == 3}
            onClick={() => onContinue(3)}
          />}
        <WebPayButton
          loading={loading && type == 1}
          onClick={() => onContinue(1)}
          title={<div>Pagar <u>sin</u> registrar tarjeta</div>}
        />
      </div>
    </div >
  )
}

const Option = ({ title, text, onClick, loading, className }) => {
  return (
    <div className={`w-full flex mt-5 ${className}`}>
      <div className='grid grid-cols-2 w-full '>
        <div className='text-base font-interBold col-span-2 leading-6'>
          {title}
        </div>
        {text && (
          <p className='col-span-3 text-[.7rem] font-interRegular'>{text}</p>
        )}
      </div>
      <div className='ml-2'>
        <ArrowButton loading={loading} onClick={onClick} />
      </div>
    </div>
  );
};