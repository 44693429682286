// firebase.js
import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { getAuth } from 'firebase/auth';
import 'firebase/firestore'; // or any other Firebase products you are using
import { getFirestore } from 'firebase/firestore';
import config from './config';

initializeApp(config.FIREBASE_CONFIG)
const app = initializeApp(config.FIREBASE_CONFIG)
export const auth = getAuth(app);
export const db = getFirestore(app);