import React, { useEffect } from 'react';

const Timer = ({ timer, setTimer }) => {
  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      console.log('Timer ended');
    }
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return <div style={{ fontSize: '1rem' }}>{formatTime(timer)}</div>;
};

export default Timer;
