import { Link, useNavigate, useParams } from "react-router-dom";


import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { playerPath, videoPlayerPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import Descriptions from "../../components/Landing/Descriptions";
import { useEffect, useState } from "react";
import VideoBackground from "../../components/Landing/VideoBackground";
import { BackIcon, MenuDotsIcon, Rotate } from "../../components/Icons";
import PlayButton from "../../components/Landing/PlayButton";
import ExpiredButton from "../../components/Landing/ExpiredButton";
import OrientationDetector from "../../utils/getOrientation";
import { VideoDescriptions } from "../../components/Landing";


const OPTIONS = { auto: false }

function VideoPlayerMonthly() {
  const navigate = useNavigate()
  const { name, slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [reproductions, setReproductions] = useState(null);
  const profile = playerPath(name, slug)

  const [isVertical, setVertical] = useState(true);
  const { orientation } = OrientationDetector()

  const isAutoLandscape = orientation == 'landscape' && isVertical

  const { asyncFetch: fetchData, data } = useApi(resources['singleCreator'].replace(':id', name), OPTIONS);
  const { asyncFetch: fetchReproductions } = useApi(resources['getReproductions'], OPTIONS);
  const { asyncFetch: fetchCheckout } = useApi(resources['checkout'].replace(':slug', slug), OPTIONS);
  const { data: dataList } = useApi(resources['searchBySlug'].replace(':slug', slug));

  // const video = data?.videos && data?.videos[1]
  const video = dataList?.data

  const refreshReproductions = async () => {
    const res = await fetchReproductions(undefined, slug);
    // console.log('[fetchReproductions]', res);
    setReproductions(res);
    /*if (res.remaining_reproductions == 1 && res.subscription_type === 'ppv') {
      setIsForthReproduction(true)
    }*/
  }

  // console.log('[reproductions]', reproductions)
  const initialFetch = async () => {
    try {
      const res = await fetchData();
      if (res) {
        await refreshReproductions()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    initialFetch()
  }, [])

  const handleButton = () => navigate(videoPlayerPath(name, slug))

  const isPlayable = reproductions?.subscription_type == 'monthly' && reproductions?.remaining_days > 0

  const Horizontal = () => {
    return (
      <div>
        <div className="absolute top-0 right-0 left-0">
          <Header onFlip={() => !isAutoLandscape && setVertical(!isVertical)}
            onClick={() => {
              if (orientation == 'landscape' && isVertical) {
                navigate(profile)
              } else {
                setVertical(true)
              }
            }} />
        </div>
        <div className="px-8 absolute bottom-8 right-0 left-0 text-white">
          {/* <div>
            <div className="px-10 text-white">
              <div className="flex items-center">
                <PlayButton playing={false} onClick={handleButton} />
                {<div className='ml-3 italic text-sm'>
                  {4 - reproductions?.remaining_reproductions}/4
                </div>}
              </div>
              <div className="mt-2 italic">¡Te quedan {reproductions?.remaining_reproductions} reproducciones!</div>
            </div>
          </div> */}
          <div className="text-white flex justify-between items-center">
            {isPlayable && <VideoDescriptions name={data?.user?.first_name} title={video?.title} description={video?.description} />}
            {!isPlayable && <div className="w-72 text-center">
              <ExpiredButton
                // onClick={handleBuy}
                loading={false}
                expired={!isPlayable} />
              <div className="font-bold italic mt-4">¡Sigue escuchándola!</div>
            </div>}
          </div>
        </div>
      </div>
    )
  }

  const Vertical = () => {
    return (
      <div>
        <div className="absolute top-0 right-0 left-0">
          <Header onFlip={() => {
            if (orientation == 'portrait')
              setVertical(!isVertical)
          }}
            onClick={() => { navigate(profile) }} />
        </div>
        <div className="px-8 absolute bottom-8 right-0 left-0 text-white">
          {/* <Descriptions name={data?.user?.first_name} title={video?.title} description={video?.description} />
          <div className="mt-4">
            <div className="flex items-center">
              <PlayButton playing={false} onClick={handleButton} />
              {<div className='ml-3 italic text-sm'>
                {4 - reproductions?.remaining_reproductions}/4
              </div>}
            </div>
          </div> */}
          {/* {!expired && <div className="mt-10 italic">¡Te quedan {reproductions?.remaining_reproductions} reproducciones!</div>} */}
          {!isPlayable && <div className="mt-2 text-center">
            <ExpiredButton
              // onClick={handleBuy}
              loading={false}
              expired={!isPlayable}
            />
            <div className="text-[16px] font-interSemiBold mt-4">¡Sigue escuchándola!</div>
          </div>}
        </div>
      </div>
    )
  }

  if (loading) {
    return <CenterSpinner />
  }

  const auto = !isVertical
  return (
    <div
      className={`${auto && 'absolute top-0 left-0 overflow-hidden w-[100dvh] h-[100dvw]'}  `}
      style={{ zIndex: 100, transform: auto && 'rotate(90deg) translateY(-100%)', transformOrigin: auto && 'top left' }}>
      <VideoBackground onClick={handleButton} src={video?.file_short_version} img={data?.photo} />
      {(isVertical && orientation == 'landscape') && <Horizontal />}
      {(isVertical && orientation != 'landscape') && <Vertical />}
      {!isVertical && <Horizontal />}
    </div>
  );
}

const Header = ({ onClick, onFlip }) => (
  <div style={{ zIndex: 10000 }} className="p-6 flex justify-between">
    <div style={{ zIndex: 10000 }} onClick={onClick}>
      <BackIcon />
    </div>
    <button style={{ zIndex: 10000 }} onClick={onFlip}>
      <Rotate />
    </button>
  </div>
)

export default VideoPlayerMonthly




// return (
//   <div className="absolute top-0 left-0 overflow-hidden w-[100dvh] h-[100dvw]" style={{ transform: 'rotate(90deg) translateY(-100%)', transformOrigin: 'top left' }}>
//     <VideoBackground src={video?.file_short_version} />
//     <div className="relative h-[100dvw] flex flex-col justify-between z-10">
//       <div>
//         <Header1 onClick={() => { navigate(profile) }} />
//         <div className="px-10 text-white">
//           <div className="flex items-center">
//             <PlayButton playing={false} onClick={handleButton} />
//           </div>
//           <div className="mt-2 italic">¡Tienes {reproductions?.remaining_days} días para escuchar esta canción!</div>
//         </div>
//       </div>
//       <div className="px-10 text-white flex justify-between items-center">
//         <Descriptions name={data?.user?.first_name} title={video?.title} description={video?.description} />
//         {!isPlayable && <div className="w-72 text-center">
//           <ExpiredButton loading={false} expired={true} />
//           <div className="font-bold italic  mt-4">¡Sigue escuchándola!</div>
//         </div>}
//       </div>
//     </div>
//   </div>
// );