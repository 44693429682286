import React, { useState, useEffect } from 'react';

function OrientationDetector() {
    const [orientation, setOrientation] = useState(getOrientation());

    useEffect(() => {
        function handleOrientationChange() {
            setOrientation(getOrientation());
        }

        window.addEventListener('orientationchange', handleOrientationChange);
        window.addEventListener('resize', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    function getOrientation() {
        return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
    }

    return {
        orientation
    }
}

export default OrientationDetector;
