import React, { useRef, useState } from 'react';

const SENSITIVE = 30


const Swipe = ({ children, onSwipe }) => {
  const [swipeStartX, setSwipeStartX] = useState(null);
  const [swipeEndX, setSwipeEndX] = useState(null);
  const swipeRef = useRef(null);

  const handleSwipeStart = (event) => {
    setSwipeStartX(event.touches[0].clientX);
  };

  const handleSwipeMove = (event) => {
    setSwipeEndX(event.touches[0].clientX);
  };

  const handleSwipeEnd = () => {
    if (swipeStartX && swipeEndX) {
      const swipeDistance = swipeEndX - swipeStartX;
      if (swipeDistance < -SENSITIVE) {
        // Swipe towards the left
        //console.log('Swiped left!');
        onSwipe && onSwipe()
      }
    }
    // Reset swipe values
    setSwipeStartX(null);
    setSwipeEndX(null);
  };

  const getAnimationStyles = () => {
    if (swipeStartX && swipeEndX) {
      const swipeDistance = swipeEndX - swipeStartX;
      if (swipeDistance < 0) {
        // Calculate the animation translation based on the swipe distance
        const translateX = Math.abs(swipeDistance);
        return {
          transform: `translateX(-${translateX}px)`,
          transition: 'transform 0.3s ease-out',
        };
      }
    }
    return null;
  };

  return (
    <div
      ref={swipeRef}
      onTouchStart={handleSwipeStart}
      onTouchMove={handleSwipeMove}
      onTouchEnd={handleSwipeEnd}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          ...getAnimationStyles(),
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Swipe;
