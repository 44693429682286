import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { GoogleOAuthProvider } from '@react-oauth/google';

import reportWebVitals from './reportWebVitals';
import Store from './reducer/Store';
import App from './App';

import './index.css';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'swiper/css/virtual';

const persistor = persistStore(Store);
const root = ReactDOM.createRoot(document.getElementById('root'));

// redirectInstagram()

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId='105196880822-r1gkfvjag4h71fsus44sivisfb7h9ekq.apps.googleusercontent.com'>
          <App />
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

/** possible fix for instagram in-browser */
function redirectInstagram() {
  try {
    var ua = navigator.userAgent;
    let link = window.location.href
    console.log('link', link)
    var isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;
    if (isInstagram) {
      if (/iPad|iPhone|iPod/.test(ua)) {
        window.location.href = link.replace('https', 'googlechrome');
        // window.location.href = 'googlechrome://web.mintapp.info/';
        return;
      }
      window.location.href = 'intent:https://web.mintapp.info/#Intent;end';
      return;
    }
  } catch (e) {

  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
