import React, { useEffect } from 'react';
import { s_payment_params, setSuccessFlag } from '../../reducer/PaymentReducer';
import { useDispatch, useSelector } from 'react-redux';
import NewCard from '../../components/NewCard';
import CountDown from '../../components/CountDown';
import { BasicButton, ButtonGrey } from '../../components/NewButtons';
// import { ampli } from '../../ampli';
import { useNavigate, useParams } from 'react-router-dom';
import { ROOT, codePurchasedPath, landingPreviewVideoPlayerPath, playerPath, purchasedAudioPath, purchasedVideoPath } from '../../utils/Routes';
import {
  TRACK_PAY_4_REPRO,
  TRACK_PAY_MONTHLY,
} from '../../utils/trackingNames';
import ReactGA from 'react-ga4';
import { Steps } from '../../components/Landing/Steps';

function PaymentSuccess() {
  const { route, slug } = useParams()
  const navigate = useNavigate();
  //const dispatch = useDispatch();
  const {
    creatorId,
    slugField,
    paymentMethod,
    paymentOption,
    creatorName,
    creator_photo,
    content_title,
    content_description,
    purchaseCode,
    inner_photo,
  } = useSelector(s_payment_params);


  const dataCard = {
    name: creatorName,
    photo: creator_photo,
    id: creatorId,
    title: content_title,
    description: content_description,
    inner_photo: inner_photo,
  };



  /*const HandleClick = () => {
    // console.log('creatorName::', creatorName)
    // console.log('slugField', slugField)
    ReactGA.event('item_paid', {
      event_name: 'paid',
      creator_name: creatorName,
      creator_id: creatorId,
      item_name: slugField,
      payment_method: paymentMethod,
      payment_option:
        paymentOption === 0 ? TRACK_PAY_4_REPRO : TRACK_PAY_MONTHLY,
    });

    ampli
      .paid({
        name: slugField,
        creator_id: creatorId,
        creator_name: creatorName,
        payment_method: paymentMethod,
        payment_option:
          paymentOption === 0 ? TRACK_PAY_4_REPRO : TRACK_PAY_MONTHLY,
      })
      .promise.then(MoveToScreens());
    dispatch(setSuccessFlag(true));
  };

  const playerRoute = purchasedVideoPath(creatorName, slug)
  const audioRoute = purchasedAudioPath(creatorName, slug)
  const MoveToScreens = () => {
    if (route == 'video') {
      navigate(playerRoute)
    }
    else if (route == 'audio') {
      navigate(audioRoute)
    }
    else {
      navigate(`/${creatorName}`)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      // HandleClick();
    }, 3100);
  }, []);*/

  const handleClick = () => {
    navigate(codePurchasedPath(creatorName, slug))
  }

  useEffect(() => {
    ReactGA.event('item_paid_useEffect', {
      event_name: 'paid',
      creator_name: creatorName,
      creator_id: creatorId,
      item_name: slugField,
      payment_method: paymentMethod,
      payment_option:
        paymentOption === 0 ? TRACK_PAY_4_REPRO : TRACK_PAY_MONTHLY,
    });

    setTimeout(handleClick, 3000)
  }, []);

  return (
    <div className='white-bg h-screen'>
      <NewCard data={dataCard} to={ROOT} />
      <div className='pl-8 pr-8 pb-[2vh]'>
        <Steps selected={2} />
        <h2 className='text-xl font-interRegular font-bold'>
          ¡Pago exitoso!
        </h2>
        <div className='my-10 flex flex-col justify-between '>
          <p className='text-[1rem] w-60 font-medium font-interRegular'>
            Tu pago ha sido aceptado, ya puedes disfrutar de tu compra.
          </p>
        </div>
        <div className='w-full flex justify-center mt-5'>
          <ButtonGrey onClick={handleClick} label="Reproducir en la App MINT" />
        </div>
      </div>
    </div>
  );
}

export default PaymentSuccess;
