import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";


import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { CenterSpinner } from "../../components/Spinner";
import { BackIcon, MenuDotsIcon } from "../../components/Icons";
import { landingPreviewVideoPlayerPath, landingVideoPlayerPath } from "../../utils/Routes";


const OPTIONS = { auto: false }

function VideoPlayer() {
  const { name, slug } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const [reproductions, setReproductions] = useState(null);
  const [currentTime, setCurrentTime] = useState(0)

  const { asyncFetch: fetchData, data } = useApi(resources['singleCreator'].replace(':id', name), OPTIONS);
  const { asyncFetch: fetchReproductions } = useApi(resources['getReproductions'], OPTIONS);
  const { asyncFetch: fetchCheckout } = useApi(resources['checkout'].replace(':slug', slug), OPTIONS);
  const { data: dataList } = useApi(resources['searchBySlug'].replace(':slug', slug));

  const {
    asyncFetch: fetchUseReproduction,
    loading: loadingUseReproduction
  } = useApi(resources['useReproductions'].replace(':slug', slug), OPTIONS);

  const video = dataList?.data
  // const video = data?.videos && data?.videos[1]
  // console.log(name, slug)
  const refreshReproductions = async () => {
    const res = await fetchReproductions(undefined, slug);
    // console.log('[fetchReproductions]', res);
    setReproductions(res);
    /*if (res.remaining_reproductions == 1 && res.subscription_type === 'ppv') {
      setIsForthReproduction(true)
    }*/
  }

  const decreaseReproduction = async () => {
    console.log('[decreaseReproduction]', currentTime)
    await fetchUseReproduction()
    await refreshReproductions(slug)
  }

  //console.log('[reproductions]', reproductions)
  const initialFetch = async () => {
    try {
      const res = await fetchData();
      if (res) {
        const repro = await refreshReproductions()
        const checkres = await fetchCheckout()
        // console.log('[checkres]', checkres)
        if (checkres?.next_step !== 'play') {
          if (repro?.subscription_type === 'ppv') {
            navigate(landingVideoPlayerPath(name, slug))
          } else {
            navigate(landingPreviewVideoPlayerPath(name, slug))
          }
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    initialFetch()
  }, [])

  useEffect(() => {
    // decrease only for ppv
    if (reproductions?.subscription_type === 'ppv' && currentTime === 15 && !loadingUseReproduction) {
      decreaseReproduction()
    }
  }, [currentTime]);

  const handleProgress = (e) => {
    setCurrentTime(Math.floor(e.target.currentTime))
  }

  if (loading) {
    return <CenterSpinner />
  }

  return (
    <div className="absolute top-0 left-0 overflow-hidden w-full h-screen">
      <video
        playsInline
        preload="true"
        controls
        className="absolute top-0 left-0 w-full h-[100dvh] object-cover"
        onTimeUpdate={handleProgress}
        src={video?.file} />
      <div className="relative z-10">
        <div>
          <Header />
        </div>
      </div>
    </div>
  );
}

const Header = () => (
  <div style={{ zIndex: 10000 }} className="w-full p-6 flex justify-between">
    <Link to={-1}>
      <BackIcon />
    </Link>
    <MenuDotsIcon />
  </div>
)

export default VideoPlayer