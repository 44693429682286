import { useNavigate, useParams } from 'react-router-dom';
import './Payment.css';
import {
  PAYMENT_METHOD,
  ROOT,
  paymentMethodPath,
  playerPath,
} from '../../utils/Routes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  s_payment_params,
  setPaymentId,
  setPaymentOption,
} from '../../reducer/PaymentReducer';
import { useApi } from '../../network/hooks/useApi';
import { resources } from '../../network/resources';
import NewCard from '../../components/NewCard';
import { ArrowButton } from '../../components/NewButtons';
import { Steps } from '../../components/Landing/Steps';
import { UserDataModal } from '../../components/CardDataModal';
import ErrorModal from '../../components/ErrorModal';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import CheckoutForm from './CheckoutForm';
import { PaymentModal } from '../../components/PaymentModal';
import { usePayments } from './hooks';

function PaymentSelection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    creatorId,
    slugField,
    paymentMethod,
    paymentOption,
    creatorName,
    creator_photo,
    content_title,
    content_description,
    inner_photo,
    price,
    productId
  } = useSelector(s_payment_params);
  const { checkProductSubscription } = usePayments()

  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [infoModal, setInfoModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false)
  const [authLoading, setAuthLoading] = useState(false);
  const [codeVisible, setCodeVisible] = useState(false);
  const [prod, setProduct] = useState('');

  const handleAuth = async (email, password, phone) => {
    try {
      setAuthLoading(true)
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
          .catch(async err => {
            console.log(err?.code)
            if (err?.code == 'auth/invalid-credential') {
              let u = await createUserWithEmailAndPassword(auth, email, password)
              if (u?.user) {
                await SaveData(u?.user, phone)
                await HandlePay(u?.user.uid)
              }
            }
          })
        // User logged in
        if (userCredential) {
          await HandlePay(userCredential?.user?.uid)
          // console.log('kjbk', userCredential?.user)
        }
        setAuthLoading(false)
        // Redirect user or show success message
      } catch (error) {
        setAuthLoading(false)
        console.error("Error logging in: ", error.message);
      }
    } catch (e) {
      console.error('[HANDLE AUTH NO OTP]', e)
    } finally {
      setAuthLoading(false)
    }
  }

  const HandlePay = async (uid) => {
    let res = await checkProductSubscription(uid, productId)
    closeInfoModal()
    if (res) {
      setProduct(res)
      setCodeVisible(true)
    }
    else {
      setPaymentModal(true)
    }
  }

  const SaveData = async (user, phone) => {
    let _uid = user.uid
    const _data = {
      userId: _uid,
      email: user.email,
      isCreator: false,
      isVerified: false,
      phone: phone,
      name: '',
      bio: '',
      photo: '',
      balance: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
    }
    try {
      if (_uid) {
        const userRef = await doc(db, 'users', _uid);
        await setDoc(userRef, _data, { merge: true }).then(res => { }).catch(err => { });
      }
    } catch (error) {
      console.error('Error adding user to Firestore:', error);
    }
  }

  const showInfoModal = (index) => {
    setSelectedIndex(index)
    setInfoModal(true)
  }

  const closeInfoModal = () => {
    setSelectedIndex(-1)
    setInfoModal(false)
  }

  const dataCard = {
    name: creatorName,
    photo: creator_photo,
    id: creatorId,
    title: content_title,
    description: content_description,
    inner_photo: inner_photo,
  };

  return (
    <div className='bg-white h-screen'>
      <NewCard data={dataCard} to={ROOT} />
      <div className='pl-8 pr-8'>
        <Steps selected={0} />
        <h2 className='text-xl font-interRegular font-bold'>Elige tu plan</h2>
        <div>
          {/* {!optionsLoading ? (
            data?.options?.map((item, idx) => {
              return ( */}
          <div
            // key={idx}
            className={`flex justify-between mt-6 'pb-6 border-b-2'
              }`}
          >
            {/*TODO change styles when name change on backend*/}
            <div>
              <p className='w-48 text-[1rem] font-interRegular leading-5'>
                {'Desbloquea un mes por'}
              </p>
              <div className='text-[1.1rem] font-interBold leading-5'>{`${price}`}</div>
            </div>
            <ArrowButton
              onClick={(e) => {
                showInfoModal();
              }}
            // loading={selectedIndex === idx}
            />
          </div>
          {/* );
            })
          ) : (
            <div className='w-full flex justify-center'>
              <Spinner />
            </div>
          )} */}
        </div>
      </div>
      {paymentModal && (
        <PaymentModal onContinue={handleAuth} onClose={() => setPaymentModal(false)} loading={authLoading} >
          <>
            {<CheckoutForm
              amount={price}
              title={content_title}
              setPaymentModal={setPaymentModal}
              uid={auth?.currentUser?.uid}
              navigate={navigate}
              creatorName={creatorName}
            // paymentKey={paymentKey}
            />}
          </>
        </PaymentModal>
      )}
      {codeVisible && (
        <PaymentModal onClose={() => setCodeVisible(false)}  >
          <div>
            <div style={{ textAlign: 'center', }}>Ya compraste este producto con el siguiente código de compra:</div>
            <div style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold', marginTop: 20 }}>{prod?.code}</div>
          </div>
        </PaymentModal>
      )}
      {infoModal && (
        <UserDataModal onContinue={handleAuth} onClose={closeInfoModal} loading={authLoading} />
      )}
      {errorModal && <ErrorModal onClose={() => setErrorModal(false)} />}
    </div>
  );
}

export default PaymentSelection;