import { Link } from 'react-router-dom';
import { Header } from '../../components/Headers';
import { ArrowButton, ArrowButtonLink } from '../../components/NewButtons';
import { CREATE } from '../../utils/Routes';

const menu = [
  {
    label: 'Canción Inédita',
    route: `${CREATE}/audio`,
  },
  {
    label: 'Video Inédito',
    route: `${CREATE}/video`,
  },
  // {
  //   label: 'Videos Promocionales ',
  //   route: '',
  // },
  {
    label: 'Entradas',
    route: `${CREATE}/tickets`,
  },
  // {
  //   label: 'Videollamadas',
  //   route: '',
  // },
  {
    label: 'Shows en Línea',
    route: `${CREATE}/online`,
  },
  {
    label: 'Merchandising',
    route: `${CREATE}/merchandising`,
  },
];

function Create() {
  return (
    <div className='bg-white h-screen overflow-auto'>
      <Header title={''} />
      <div className='px-8 pt-4'>
        <div className='text-2xl font-bold font-interRegular mb-2'>
          Sube tu material
        </div>
        {/* <p className='text-base'>
          Debe ser material que únicamente se encuentre en esta plataforma.
        </p> */}
        <ul className='mt-7'>
          {menu.map((item, index) => (
            <li key={index} className=' border-b border-gray-300 w-full flex justify-between items-center pr-2 pb-5 mb-5'>
              <p className='font-bold font-interRegular text-base'>
                {item.label}
              </p>
              <ArrowButton key={item.route} link={item.route} />
            </li>
          ))}
        </ul>
      </div>{' '}
    </div>
  );
}

export default Create;
