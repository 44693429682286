import CurrencyInput from 'react-currency-input-field';
import './Inputs.css';
import { forwardRef } from 'react';

export function Input({ errorMessage, ...props }) {
  return (
    <div className='basic-input-wrapper'>
      <input className='basic-input' {...props} />
      {errorMessage ? (
        <p className='error-message-input'>{errorMessage}</p>
      ) : null}
    </div>
  );
}

export function Checkbox({ label, isChecked, setIsChecked }) {
  return (
    <div className='checkboxWrapper'>
      <input
        type='checkbox'
        className={isChecked ? 'checked' : ''}
        onChange={() => setIsChecked((prev) => !prev)}
      />
      <span>{label}</span>
    </div>
  );
}

export function UploadFile({
  label,
  handleFileChange,
  large,
  video,
  img,
  audio,
}) {
  let acceptFileType = '';

  if (video) {
    acceptFileType = 'video/*';
  } else if (img) {
    acceptFileType = 'image/*,.heic';
  } else if (audio) {
    acceptFileType = 'audio/*';
  }

  return (
    <div
      className={`file-select ${large ? 'large' : 'small'}`}
      data-content={label}
    >
      <input
        type='file'
        name='src-file1'
        onChange={handleFileChange}
        accept={acceptFileType}
      />
    </div>
  );
}

export function PriceInput({ setValue }) {
  const suffix = ' CLP';
  return (
    <CurrencyInput
      className='basic-input input-margin'
      suffix={suffix}
      placeholder='0000 CLP - peso chileno'
      allowDecimals={false}
      onValueChange={setValue}
    />
  );
}

export function DateTimeInput({
  errorMessage,
  handleChange,
  type,
  placeholder,
}) {
  return (
    <div className='basic-input-wrapper'>
      <input
        onChange={handleChange}
        onFocus={(e) => (e.target.type = type)}
        onTouchStart={(e) => (e.target.type = type)}
        type='text'
        placeholder={placeholder}
        className='basic-input'
      />
      {errorMessage ? (
        <p className='error-message-input'>{errorMessage}</p>
      ) : null}
    </div>
  );
}

export function TextArea({ messageErrorText, handleChangeTextArea }) {
  return (
    <div className='textarea-wrapper'>
      <textarea
        className='textarea'
        onChange={(e) => handleChangeTextArea(e.target.value)}
      />
      {messageErrorText ? (
        <p className='error-message-textArea'>{messageErrorText}</p>
      ) : null}
    </div>
  );
}

export const SmallInput = forwardRef((props, ref) => (
  <input className='basic-input small-input' {...props} ref={ref} />
))

export function TransparentInput(props) {
  return <input className='transparent-input' {...props} />;
}
