import { useSelector } from 'react-redux';
import { s_payment_params } from '../../reducer/PaymentReducer';
import NewCard from '../../components/NewCard';
import { BasicButton } from '../../components/NewButtons';
import { resources } from '../../network/resources';
import { useApi } from '../../network/hooks/useApi';
// import { ampli } from '../../ampli';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { LoadingLogo } from '../../components/Landing/LoadingLogo';

function PaymentUnsuccessfull() {
  const { slug } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [product, setProduct] = useState('');

  const {
    creatorId,
    slugField,
    paymentOption,
    creatorName,
    creator_photo,
    content_title,
    content_description,
    purchaseCode,
  } = useSelector(s_payment_params);

  const {
    asyncFetch: asyncFetchFlowPay,
    loading: flowPayloading,
    error: flowPayerror,
  } = useApi(resources['pay'].replace(':purchaseCode', purchaseCode), {
    method: 'GET',
    auto: false,
  });

  const dataCard = {
    name: creatorName,
    photo: creator_photo,
    id: creatorId,
    title: content_title,
    description: content_description,
  };

  useEffect(() => {
    getProductById(slug)
  }, [slug])

  async function getProductById(docId) {
    const docRef = doc(db, "FeedsVertical", docId);
    try {
      setLoading(true)
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const productData = docSnap.data();
        setProduct(productData)
        setLoading(false)
        // return { ...productData, }; // Returns the document data
      } else {
        console.log("No such document!");
        setLoading(false)
        return null; // Handle the case when the document doesn't exist
      }
    } catch (error) {
      setLoading(false)
      console.error("Error getting document:", error);
      return null; // Handle error appropriately
    }
  }

  const handleContinuar = async () => {
    setLoading(true);
    const reply = await asyncFetchFlowPay();
    setLoading(false);
    if (reply) {
      //amplitude tracking
      // ampli.paymentMethod({
      //   selected: 0,
      //   name: slugField,
      //   creator_id: creatorId,
      //   creator_name: creatorName,
      // });
      // ampli.paymentOption({
      //   selected: paymentOption,
      //   name: slugField,
      //   creator_id: creatorId,
      //   creator_name: creatorName,
      // });

      // ampli.paid({
      //   name: slugField,
      //   creator_id: creatorId,
      //   creator_name: creatorName,
      // });

      //redirect
      window.location.replace(reply.url);
    }
  };

  const OnPay = async () => {
    // await handleContinuar();
  };

  if (isLoading) {
    return <LoadingLogo />
  }

  return (
    <div className='white-bg'>
      <NewCard data={product} />
      <div className='m-10'>
        {/* <ul className='flex justify-between mb-7 text-[0.6rem]'>
          <li className='text-black/[.3] text-[.7rem]'>1. Planes</li>
          <li className='font-semibold text-[.7rem]'>2. Medio de Pago</li>
          <li className='text-black/[.3] text-[.7rem]'>
            3. ¡Escucha tu canción!
          </li>
        </ul> */}
        <h2 className='text-2xl font-semibold w-56 mb-10'>
          No pudimos procesar tu pago
        </h2>
        <div className='flex justify-between flex-col h-44'>
          <p className='text-base'>
            Reintenta el pago o contáctate con tu institución financiera.{' '}
          </p>
          <div className='w-full flex justify-center'>
            <BasicButton
              className={'w-3/4'}
              onClick={OnPay}
              loading={flowPayloading}
            >
              Reintentar pago
            </BasicButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentUnsuccessfull;
