import { Navigate, Outlet } from 'react-router-dom';
import { ROOT } from '../utils/Routes';
import { useSelector } from 'react-redux';
import { s_is_auth } from '../reducer/SessionReducer';


const ProtectedRoutes = () => {
  const is_auth = useSelector(s_is_auth);
  if (!is_auth) {
    return <Navigate to={ROOT} />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
