import { MdVolumeOff, MdVolumeUp } from 'react-icons/md';
import { BiArrowBack, BiDotsVerticalRounded } from 'react-icons/bi';
import { FaGoogle, FaApple, FaRegHeart } from 'react-icons/fa';
import { IoMdAlert } from 'react-icons/io';

import Videos from '../assets/MenuCreateIcons/icons_video.png';
import Audio from '../assets/MenuCreateIcons/icons_sound.png';
import Images from '../assets/MenuCreateIcons/icons_image.png';
import Text from '../assets/MenuCreateIcons/icons_text.png';
import Store from '../assets/MenuCreateIcons/icons-store.png';
import Ticket from '../assets/MenuCreateIcons/icons_tickets.png';
import VideoCall from '../assets/MenuCreateIcons/icons_desktop.png';
import VideoAudio from '../assets/MenuCreateIcons/icons_laptop.png';
import Onlineshow from '../assets/MenuCreateIcons/icons_pc.png';
import Merch from '../assets/MenuCreateIcons/icons_dress.png';
import BackArrow from '../assets/MenuCreateIcons/icons8-half-turn-64.png';
import PaysIcon from '../assets/icons8-dollar.png';
import PromosIcon from '../assets/icons8-papel-satinado.png';
import MsgIcon from '../assets/icons8-mensaje.png';
import CheckIcons from '../assets/icons-check.png';
import LogOutIcon from '../assets/icons8-log.png';
import PhotosIcon from '../assets/icons8-nombre.png';
import Success from '../assets/icons8-success.png';
import PayBag from '../assets/money-bag.png';
import Transaction from '../assets/transactions.png';
import Visa from '../assets/icon-visa.png';
import Visa2 from '../assets/visa.png';
import Visa3 from '../assets/visa-light.png';
import Close from '../assets/close.png';
import Mach from '../assets/mach.png';
import Webpay from '../assets/webpay.png';
import Onepay from '../assets/onepay.png';
import Flow from '../assets/flow.png';
import Tiktok from '../assets/tiktok-logo.png';
import Google from '../assets/google-logo.png';
import Instagram from '../assets/instagram-logo.png';
import Logo from '../assets/Logo.png';
import LogoDark from '../assets/Logo-dark.png';
import { IoMenuSharp } from 'react-icons/io5';
import MIcon from '../assets/MIcon.svg';
import MIconWhite from '../assets/MiconWhite.svg';
import Garbage from '../assets/garbage.png';
import Add from '../assets/add.png';
import Edit from '../assets/icon-edit.png';
import Fullscreen from '../assets/fullscreen.png';
import HorizontalVideo from '../assets/horizontal-video.png';
import Chromecast from '../assets/chromecast.svg'
import Cart from '../assets/cart.png'
import PlayBlack from '../assets/play_black.png'
import PlayAlt from '../assets/play_alt.png'
import PlayStore from '../assets/play-store-icon.png'

import { ReactComponent as SoundWhite } from '../assets/soundWhite.svg'
import { ReactComponent as VideoWhite } from '../assets/videoWhite.svg'
import { ReactComponent as Bag } from '../assets/bag.svg'
import { ReactComponent as Content } from '../assets/content-icon.svg'
import { ReactComponent as Publications } from '../assets/icon-publications.svg'
import { ReactComponent as PublicationOutline } from '../assets/publicationoutline.svg'
import { ReactComponent as PurchasesOutline } from '../assets/puchases-out.svg'
import { ReactComponent as PurchasesFill } from '../assets/purchasesFill.svg'
import { ReactComponent as StarFill } from '../assets/starFil.svg'
import { ReactComponent as StarOutline } from '../assets/starOutline.svg'
import { ReactComponent as SoundBlack } from '../assets/sound.svg'
import { ReactComponent as LiveIcon } from '../assets/live.svg'
import { ReactComponent as LiveBlack } from '../assets/live_black.svg'

import './Icons.css';
import { Icons } from '../assets';

export function VideoIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Videos}
        alt='Video'
      />
    </div>
  );
}

export function AudioIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Audio}
        alt='Audio'
      />
    </div>
  );
}

export function ImagesIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Images}
        alt='Images'
      />
    </div>
  );
}

export function TextIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Text}
        alt='Text'
      />
    </div>
  );
}

export function TiendaIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Store}
        alt='Tienda'
      />
    </div>
  );
}

export function TicketsIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Ticket}
        alt='Tienda'
      />
    </div>
  );
}

export function VideoCallIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={VideoCall}
        alt='Tienda'
      />
    </div>
  );
}

export function AudioVideoIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={VideoAudio}
        alt='Tienda'
      />
    </div>
  );
}

export function OnlineShowIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Onlineshow}
        alt='Tienda'
      />
    </div>
  );
}

export function MerchIcon({ square, medium }) {
  return (
    <div
      className={`${medium ? 'medium-icon-container' : 'icon-container'} ${square && 'square'
        }`}
    >
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Merch}
        alt='Tienda'
      />
    </div>
  );
}

export function PayBagIcon({ medium }) {
  return (
    <div className={`${medium ? 'medium-icon-container' : 'icon-container2'} `}>
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={PayBag}
        alt='Tienda'
      />
    </div>
  );
}

export function TransactionIcon({ medium }) {
  return (
    <div className={`${medium ? 'medium-icon-container' : 'icon-container2'} `}>
      <img
        className={`${medium ? 'medium-icon' : 'default-icon'}`}
        src={Transaction}
        alt='Tienda'
      />
    </div>
  );
}

export function VolumeIcon({ mute, size = 25 }) {
  if (mute) {
    return <MdVolumeOff size={size} color='white' />;
  }
  return <MdVolumeUp size={size} color='white' />;
}

export function BackArrowIcon() {
  return (
    <div className='circle'>
      <img className='small-icon' src={BackArrow} alt='BackArrow' />
    </div>
  );
}

export function MenuIcon({ color, size = 25 }) {
  return <IoMenuSharp size={size} color={color ?? 'black'} />;
}

export function PayIcon() {
  return <img src={PaysIcon} className='menu-icon' alt='pay' />;
}

export function PromoIcon() {
  return <img src={PromosIcon} className='menu-icon' alt='promo' />;
}

export function MessageIcon() {
  return <img src={MsgIcon} className='menu-icon' alt='message' />;
}

export function PhotoIcon() {
  return <img src={PhotosIcon} className='menu-icon' alt='photos' />;
}

export function CheckIcon() {
  return <img src={CheckIcons} className='menu-icon' alt='check' />;
}

export function LogoutIcon() {
  return <img src={LogOutIcon} className='menu-icon' alt='logout' />;
}

export function SuccessIcon() {
  return <img src={Success} alt='success-icon' className='large-icon' />;
}

export function VisaIcon() {
  return <img src={Visa} alt='visa-icon' className='default-icon' />;
}

export function VisaIcon2() {
  return <img src={Visa2} alt='visa2-icon' className='small-icon' />;
}

export function BackIcon(props) {
  return <BiArrowBack color='white' size={props?.size ?? 34} {...props} />;
}

export function CloseIcon() {
  return <img src={Close} alt='visa2-icon' style={{ width: '1.6rem' }} />;
}

export function FlowIcon() {
  return <img src={Flow} alt='flow' />;
}
export function WebpayIcon({ smallBreak = 'w-10' }) {
  return <img src={Webpay} alt='webpay' className={`w-8 sm:${smallBreak}`} />;
}

export function MachIcon() {
  return <img src={Mach} alt='mach' className='w-14 sm:w-18' />;
}

export function OnepayIcon() {
  return <img src={Onepay} alt='onepay' className='w-16 sm:w-20' />;
}

export function TiktokIcon() {
  return <img className='w-7 h-7' src={Tiktok} alt='tiktok' />;
}

export function InstagramIcon() {
  return <img className='w-7 h-7' src={Instagram} alt='tiktok' />;
}

export function GoogleIcon() {
  //return <img className='w-7 h-7' src={Google} alt='tiktok' />;
  return <FaGoogle color='black' size={32} />;
}

export function MintLogo({ dark }) {
  const logo = dark ? LogoDark : Logo;
  return <img className='w-[8.5rem] h-[2.25rem]' src={logo} alt='MINT' />;
}

export function MintIcon({ white, size = 30, className }) {
  return <img className={className} style={{ height: size, width: size }} src={white ? MIconWhite : MIcon} alt='Mint' />;
}

export function GarbageIcon() {
  return <img className='w-5 h-6' src={Garbage} alt='garbage' />;
}

export function AddIcon() {
  return <img className='w-9 h-9' src={Add} alt='garbage' />;
}
export function CheckCircle({ white }) {
  return (
    <img
      style={{
        backgroundColor: '#372D90',
        height: 35,
        width: 35,
        padding: 8,
        borderRadius: 20,
      }}
      src={Icons.check}
    />
  );
}

export function AppleIcon({ size = 38, color = "black" }) {
  return <FaApple color={color} size={size} />;
}

export function MenuDotsIcon() {
  return <BiDotsVerticalRounded color='white' size={34} />;
}

export function EditIcon() {
  return <img src={Edit} alt="edit" />
}

export function FullscreenIcon() {
  return <img src={Fullscreen} alt="fullscreen" />
}

export function HorizontalVideoIcon() {
  return <img src={HorizontalVideo} alt="fullscreen" />
}
export function FlipHorizontal() {
  return <img src={Icons.arrowHorizontal} className='h-6' alt="fullscreen" />
}
export function Rotate() {
  return <img src={Icons.rotate} className='h-6' alt="fullscreen" />
}

export function ChromecastIcon() {
  return <img src={Chromecast} alt="chromecast" />
}

export function CartIcon() {
  return <img src={Cart} alt="cart" />
}

export function PlayIcon() {
  return <img src={PlayAlt} alt="play" />
}

export function PlayBlackIcon() {
  return <img
    className='w-[30px] h-[30px]'
    src={Icons.playActive} alt="play" />
}

export function PlayInactive() {
  return <img
    className='w-[30px] h-[30px]'
    src={Icons.playInactive} alt="play" />
}

export function BagIcon() {
  return <Bag stroke="black" />
}

export function BagBlackIcon() {
  return <Bag fill="black" stroke="white" />
}

export function MuteIcon() {
  return <img className='w-[2rem] h-[2rem]' src={Icons.mute} alt='garbage' />;
}
export function UnMuteIcon() {
  return <img className='w-[2rem] h-[2rem] mb-1 mr-[2px]' src={Icons.unmute} alt='garbage' />;
}

export function AlertIcon() {
  return <IoMdAlert size={24} color='red' />
}

export function ContentIcon({ dark }) {
  return <Content fill={dark ? "black" : 'white'} />
}

export function VisaCircleIcon() {
  return (
    <div className='w-10 h-10 bg-[#D9D9D9] rounded-full flex items-center justify-center'>
      <img src={Visa3} alt="Visa Icon" className='' />
    </div>
  )
}

export function PlayStoreIcon({ size = 32 }) {
  return <img className={`w-[${size}px] h-[${size}px]`} src={PlayStore} alt='play store' />;
}

export function HeartIcon({ size = 16 }) {
  return <FaRegHeart size={size} />
}

export function PublicationIcon({ dark }) {
  return <Publications fill={dark ? "black" : 'white'} />
}

export function PublicationOutlineIcon() {
  return <PublicationOutline />
}

export function PurchasesOutlineIcon() {
  return <PurchasesOutline />
}

export function PurchasesFillIcon() {
  return <PurchasesFill />
}

export function StarOutlineIcon() {
  return <StarOutline />
}

export function StarFillIcon() {
  return <StarFill />
}

export function SoundWhiteIcon() {
  return <SoundWhite />
}

export function VideoWhiteIcon() {
  return <VideoWhite />
}
export function SoundBlackIcon() {
  return <SoundBlack />
}
export function LiveIcon1({ height = 20, width = 20, fill }) {
  return <LiveIcon height={height} width={width} fill={fill} />
}

export function VideoBlackIcon() {
  return <img src={Icons.videoBlack} alt="video-black" className='w-6 h-6' />
}

export function LiveBlackIcon() {
  return <LiveBlack />
}

