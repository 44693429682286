import { BasicButton } from "../NewButtons"
import { SmallSpinner } from "../Spinner"
import LandingButton from "./LandingButton"


export default function ExpiredButton({ expired, loading, onClick }) {
  if (!expired) {
    return null
  }

  return <LandingButton label='Un mes por $999 CLP' loading={loading} onClick={onClick} />
}