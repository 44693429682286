import { useState } from 'react';
import { Link } from 'react-router-dom';

import SwiperFeed from '../../components/swiper/SwiperFeed'
import { useApi } from '../../network/hooks/useApi';
import { resources } from '../../network/resources';
import { BackIcon, MenuDotsIcon, VolumeIcon } from '../../components/Icons';
import { ROOT } from '../../utils/Routes';



function HorizontalFeed() {
  const { asyncFetch, data, loading } = useApi(resources['trends'], { append: true });
  const [mute, setMute] = useState(true);

  const handleSlideChange = async (e) => {
    if ((data.length - e.activeIndex) < 3) {
      //console.log('[handleSlideChange]', data.length, e.activeIndex, data.length - e.activeIndex )
      await asyncFetch()
    }
  }

  const handleMute = () => {
    setMute(!mute)
  }

  return (
    <div className="h-[100dvh] absolute top-0 left-0 w-full">
      <SwiperFeed data={data} mute={mute} onSlideChange={handleSlideChange} />
      <div style={{ zIndex: 10000 }} className="absolute top-0 left-0 w-full p-6 flex justify-between">
        <Link to={ROOT}>
          <BackIcon />
        </Link>
        {/*<MenuDotsIcon />*/}
      </div>
      <div style={{ zIndex: 10000 }} className="absolute bottom-0 right-0 p-6" onClick={handleMute}>
        <VolumeIcon mute={mute} size={34} />
      </div>
      {/*!videoLoaded && <Loading />*/}
    </div>
  )
}

export default HorizontalFeed