export const Icons = {
    AudioSource: require('./icons8-audio-wave.gif'),
    Play: require('./play.png'),
    Pause: require('./pause.png'),
    check: require('./check.png'),
    paypal: require('./paypal.png'),
    wunion: require('./wunion.png'),
    tb: require('./transferenciaBancaria.png'),
    whatsapp: require('./whatsapp.png'),
    bin: require('./bin.png'),
    arrowHorizontal: require('./arrowHor.png'),
    arrowVertical: require('./arrowVert.png'),
    mute: require('./mute.png'),
    unmute: require('./unmute.png'),
    rotate: require('./rotate.png'),
    playActive: require('./playActive.png'),
    playInactive: require('./playInactive.png'),
    emptyUser: require('./user-gray.png'),
    videoBlack: require('./icon-video-edit.png'),
}
export const IMAGES = {
    PROFILE: require('./temp-avatar.png'),
    SHISOSSA: require('./shisossa.png'),
    // TICKETSBUY: require('./TicketBuy.jpg'),
    TICKETSBUY: require('./TicketsBuy.jpg'),
    TICKETS: require('./TicketLanding.jpg'),
    FLYER: require('./Flyer.jpg'),
    ERROR: require('./error.png'),
    ONLINE_SHOW: require('./onlineticket-show.png')
}