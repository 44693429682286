import React, { useEffect, useState } from 'react'
import { Header } from '../../../../components/Headers'
import { useNavigate, useParams } from 'react-router-dom'
import { useApi } from '../../../../network/hooks/useApi';
import { resources } from '../../../../network/resources';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import { s_session_token } from '../../../../reducer/SessionReducer';
import { CenterSpinner } from '../../../../components/Spinner';
import { ROOT, ROUTE_ONLINESHOW_COUNTDOWN } from '../../../../utils/Routes';
import { IMAGES } from '../../../../assets';
import { FormatMoney } from '../../../../utils';


async function fetchTickets(token) {
    try {
        const reply = await fetch(`${config.API_URL}/store/event/my-tickets/`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        return await reply.json();
    } catch (error) {
        return false
    }
}

export const OnlinieTickets = ({ online }) => {
    const naviagte = useNavigate()
    const d = useParams()
    const accessToken = useSelector(s_session_token);

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [ticket, setTicket] = useState('')
    const [friends, setFriends] = useState([])

    const handleShow = () => naviagte(ROUTE_ONLINESHOW_COUNTDOWN)

    useEffect(() => {
        GetMyTickets()
    }, [])


    const GetMyTickets = async () => {
        try {
            setLoading(true)
            let reply = await fetchTickets(accessToken)
            console.log(reply)
            if (reply) {
                if (reply.is_paid) {
                    setData(reply)
                    setTicket(reply.ticket)
                    setFriends(reply.friends)
                }
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }


    return (
        <div className='h-full w-full bg-white overflow-hidden'>
            <Header onBack={() => naviagte(ROOT)} />
            {loading ? <CenterSpinner />
                : <div className='px-6'>
                    {data?.is_paid && <>
                        <div className='text-[1.12rem] font-interSemiBold mb-5'>Shisosa & Amigos</div>
                        <div className='flex flex-row justify-between w-[18rem]'>
                            <TitleWithText
                                title={'Fecha'}
                                text={'17/08/2023'}
                            />
                            <TitleWithText
                                title={'Total'}
                                text={`$${FormatMoney(Math.trunc(data?.price))} CLP`}
                            />
                            
                        </div>
                        <div className='flex flex-row justify-between w-[18rem] my-4'>
                            <TitleWithText
                                title={'Hora de inicio'}
                                text={friends[0]?.ticket?.event?.start_time}
                            />
                        </div>
                        <div className='overflow-hidden'>
                            {friends?.length && friends?.map((item, idx) => {
                                return (
                                    <>
                                        <div className='bg-[#D9D9D9] h-[1px] my-[3vh]' />
                                        <div>
                                            <div className='text-base font-interSemiBold mb-3'>Entrada {idx + 1}</div>
                                            <div className='text-base font-interSemiBold mb-[.5]'>Nombre</div>
                                            <div className='text-base font-interRegular w-[90%]'>{item?.name}</div>
                                        </div>
                                        <div className='mt-3 text-sm italic'>
                                            Accede directamente desde la sección mis  compras de tu perfil
                                        </div>
                                        <div className='relative my-4' onClick={handleShow}>
                                            <img src={IMAGES.ONLINE_SHOW} className='object-cover' />
                                            <div className='absolute top-5 left-3'>
                                                <div className='text-[10px] bg-white text-black w-[6rem] text-center py-[.10rem] rounded-full'>SHOW EN LÍNEA</div>
                                            </div>
                                            <div className='absolute left-5 bottom-3 font-bold text-lg text-white'>
                                                <u>Accede</u> a tu show en línea
                                            </div>
                                        </div>
                                        <p className='text-sm text-[#1C1C1C] mt-6 w-[16rem] italic'>{'En caso de duda escríbenos a contacto@mintapp.info'}</p>
                                    </>
                                )
                            })}

                        </div>
                        <div className='mb-[6vh]' />
                    </>}
                </div>}
        </div>
    )
}

function TitleWithText({ title, text }) {
    return (
        <div>
            <div className='text-base font-interSemiBold mb-[.5]'>{title}</div>
            <div className='text-base font-interRegular w-[8rem]'>{text}</div>
        </div>
    )
}

export default OnlinieTickets

