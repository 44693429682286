import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ROOT,
  SwiperFeedsPath,
  WELCOME,
  playerPath,
  ticketsPath,
} from '../../utils/Routes';
import ReactGA from 'react-ga4';
import { useEffect, useRef, useState } from 'react';
import config from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { login, s_is_auth, setLoginSessionCode } from '../../reducer/SessionReducer';
import { str_not_empty } from '../../utils';
import Timer from '../../components/Timer';
// import { ampli } from '../../ampli';
import { TRACK_SMS } from '../../utils/trackingNames';
import * as EmailValidator from 'email-validator';
import { Input } from '../../components/NewInputs';
import { Button } from '../../components/NewButtons';
import { useValidatorNumber } from '../../utils/customHooks/useValidatorNumber';
import InputError from '../../components/InputError';
import NewHeader from '../../components/NewHeader';
import CountrySelect from '../../components/CountryPicker';
import en from "react-phone-number-input/locale/en.json";



function loginWithAppleClickHandler(name, slug) {
  //console.log('loginWithAppleClickHandler')
  const href = `https://appleid.apple.com/auth/authorize?response_type=code%20id_token&response_mode=form_post&client_id=info.mintapp.web&redirect_uri=https://api.mintapp.info/auth/apple/result&scope=name%20email&state=${name},${slug}`
  window.location.href = href
}

async function sendCode(email, code, session) {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, code, session }),
  };

  const response = await fetch(config.API_URL + '/auth/email-login/', options);
  return await response.json();
}

async function sendSms(email, phone) {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, phone }),
  };

  const response = await fetch(config.API_URL + '/auth/send-sms/', options);
  return await response.json();
}

function Login() {
  const navigate = useNavigate();
  const loc = useLocation();
  const From = loc?.state?.from ?? false
  const is_auth = useSelector(s_is_auth);
  const { name, slug } = useParams();
  const dispatch = useDispatch();
  const [codeStep, setCodeStep] = useState(0);


  const [session, setSession] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [busy, setBusy] = useState(false);
  const [timer, setTimer] = useState(120);

  const [email, setEmail] = useState('');
  /* we use a ref because the otp() hold the initial useState value and do not update */
  const stateRef = useRef({ session: '', email: '' })

  stateRef.current.session = session
  stateRef.current.email = email

  //sms
  const [phone, handleChangePhone, messageErrorPhone] = useValidatorNumber(12);
  const [inputEmailError, setInputEmailError] = useState('');
  const [inputPhoneError, setInputPhoneError] = useState('');
  const [phoneError, setPhoneError] = useState(false)
  const [country, setCountryCode] = useState('+56')
  const [emailEmptyError, setEmailEmptyError] = useState(false)

  const handleEmail = (value) => {
    setEmail(value.trim().toLowerCase());
  };

  //for sms logic
  const handleContinueSms = async () => {
    // ampli.buttonClicked({ button: TRACK_SMS });
    ReactGA.event('button', {
      event_name: 'button_clicked',
      item_name: TRACK_SMS,
      creator_name: name,
    });
    console.log('[handleContinueSms]', email, `${country}${phone}`);

    setPhoneError(false)
    setEmailEmptyError(false)
    setInputPhoneError('')
    setInputEmailError('')

    if (phone.length === 0) {
      setPhoneError(true);
      return;
    }
    if (phone.length < 9) {
      setInputPhoneError('El número de teléfono no es valido')
      return
    }
    if (email.length === 0) {
      setEmailEmptyError(true)
      return
    }
    if (!EmailValidator.validate(email)) {
      setInputEmailError('El correo no es valido');
      return;
    }

    try {
      setBusy(true);
      setError('');
      setCode('');
      setTimer(120);
      const reply = await sendSms(email, country + phone);
      // console.log('[handleContinueSms]', reply);
      if (reply.session) {
        setSession(reply.session);
        setCodeStep(1);

        dispatch(setLoginSessionCode({ session: reply.session, email: email }));
      } else {
        setError(reply.email);
      }
    } catch (e) {
      setError(e.message);
    } finally {
      setBusy(false);
    }
  };
  const playerRoute = SwiperFeedsPath(name, slug)
  const handleCodeContinue = async (forceCode) => {
    try {
      const _code = forceCode || code;
      setBusy(true);
      setError('');
      //console.log('handleCodeContinue', stateRef.current);
      const reply = await sendCode(stateRef.current.email, _code, stateRef.current.session);
      // console.log('[sendCode reply]', reply);
      if (reply.detail) {
        setError(reply.detail);
      } else if (reply.success === true) {
        //tracking google analytics
        ReactGA.event('app_login', {
          event_name: 'login',
          creator_name: name,
          login_method: TRACK_SMS,
        });

        //tracking amplitude
        // ampli
        //   .login({ login_method: TRACK_SMS, creator_name: name })
        //   .promise.then(
        //     dispatch(
        //       login({
        //         accessToken: reply.tokens.access,
        //         refreshToken: reply.tokens.refresh,
        //         email: email,
        //       })
        //     )
        //   );
      }
    } catch (e) {
      console.error('[handleContinuar]', e);
      setError(e.message);
    } finally {
      setBusy(false);
    }
  };

  const handleAppleLogin = () => {
    loginWithAppleClickHandler(name, slug)
  };
  const TicketsPath = ticketsPath(name)
  useEffect(() => {
    if (is_auth) {
      if (From === 'login') {
        navigate(ROOT)
      }
      else if (From === 'create') {
        navigate(WELCOME)
      }
      else if (From === 'preview') {
        navigate(playerRoute, { state: { to: 'play' } })
      }
      else if (From === 'tickets') {
        navigate(TicketsPath)
      } else if (From === 'back') {
        navigate(-1)
      }
      else {
        const route = loc?.state?.route
        navigate(route || ROOT)
      }
      //navigate(`..${PAYMENT_SELECTION}`);
      // navigate(paymentSelectionPath(name, slug));
    }
  }, [is_auth]);

  const otp = async () => {
    const ac = new AbortController();
    try {
      const reply = await navigator.credentials.get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      });

      setCode(reply.code);
      handleCodeContinue(reply.code);
    } catch (e) {
      console.log(e);
    } finally {
      ac.abort();
    }
  };

  useEffect(() => {
    if ('OTPCredential' in window) {
      otp();
    }
  }, []);

  return (
    <div className='bg-white h-screen overflow-auto'>
      <NewHeader back={-1} hideMenu dark />
      {codeStep === 0 && (
        <LoginStep
          email={email}
          setEmail={handleEmail}
          onContinue={handleContinueSms}
          loading={busy}
          phone={phone}
          handleChangeCode={setCountryCode}
          country={country}
          handleChangePhone={handleChangePhone}
          inputEmailError={inputEmailError}
          name={name}
          phoneError={phoneError}
          emailEmptyError={emailEmptyError}
          inputPhoneError={inputPhoneError}
          onAppleLogin={handleAppleLogin}
        />
      )}
      {codeStep === 1 && (
        <CodeStep
          onContinue={(e, code) => handleCodeContinue(code)}
          code={code}
          setCode={setCode}
          loading={busy}
          timer={timer}
          setTimer={setTimer}
          onResend={handleContinueSms}
          error={error}
        />
      )}
    </div>
  );
}

/** phone number 9 digits max */
function LoginStep({
  email,
  setEmail,
  onContinue,
  loading,
  phone,
  handleChangePhone,
  handleChangeCode,
  country,
  inputEmailError,
  name,
  phoneError,
  emailEmptyError,
  inputPhoneError,
  onAppleLogin,
}) {

  return (
    <div>
      <div className='text-black mx-8'>
        <div className='mt-16 mb-4 text-start font-interSemiBold'>¡Bienvenido!</div>
        {/* <div className='h-10 flex flex-col justify-center'>
          {inputEmailError && <InputError message={inputEmailError} />}
          {inputPhoneError && <InputError message={inputPhoneError} />}
        </div> */}
        <div className='rounded-3xl w-full'>
          <div className={`flex border rounded-xl items-center bg-transparent h-12`}>
            <span className='pl-4 pr-2 text-sm'>
              <CountrySelect
                labels={en}
                onChange={(event) => handleChangeCode(event.target.value)}
                name="country"
                value={country}
              />
            </span>
            <div className='border-l border-[#d9d9d9] h-9'></div>
            <Input
              type='number'
              className={`bg-transparent rounded-full text-black text-sm px-2 w-full ${phoneError && 'placeholder:text-red-500 placeholder:text-[10px] placeholder:italic placeholder:font-semibold'}`}
              value={phone}
              onChange={(e) => handleChangePhone(e.target.value)}
              placeholder={phoneError ? "Completa este campo para continuar" : ''}
            />
          </div>
          <div className='flex border mt-2 rounded-xl items-center bg-transparent h-12'>
            <Input
              className={`bg-transparent text-black w-full h-14 text-sm ${emailEmptyError ? 'placeholder:text-red-500 placeholder:text-[10px] placeholder:italic placeholder:font-semibold' : 'placeholder:text-[#9D9D9D]'}`}
              placeholder={emailEmptyError ? 'Completa este campo para continuar' : 'Correo electrónico'}
              value={email}
              type='email'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <p className={`font-interRegular text-[.48rem] text-center w-full py-2`}>
          <i>* Se enviará un código de verificación a tu correo electrónico y por SMS </i>
        </p>
        <Button
          className={`bg-[#1C1C1C] text-white rounded-xl w-full mt-4 mb-[4vh]`}
          onClick={onContinue}
          //disabled={disabledSms}
          loading={loading}
        >
          <div className='text-white'>
            Continuar
          </div>
        </Button>
        <EmailAndWhatsapp />
      </div>
    </div>
  );
}

function CodeStep({
  onContinue,
  code,
  setCode,
  loading,
  timer,
  setTimer,
  onResend,
  error,
}) {
  const disabled = timer === 0;

  const codeChange = (e) => {
    const value = e.target.value;
    if (value.length <= 4) {
      setCode(value);
    }
    if (value.length === 4) {
      onContinue(e, value);
    }
  };

  return (
    <div className='text-black px-8'>
      <div className='mt-16 mb-4 text-start font-interSemiBold'>¡Bienvenido!</div>
      <div className='text-center'>
        <div className='flex justify-center'>
          {/* Para continuar ingresa los 4 dígitos enviados por SMS. */}
          <p className='w-[20rem] text-left text-[15px]'>
            Para continuar ingresa los 4 dígitos enviados a tu correo y SMS.
          </p>
        </div>
        {str_not_empty(error) && (
          <div className='my-6 flex justify-center'>
            <InputError message={error} />
          </div>
        )}
        <div className='my-4'>
          {
            <Input
              className='w-full text-black text-center bg-[#ECECEC] font-interRegular text-lg'
              type='number'
              value={code}
              onChange={codeChange}
              autoFocus
              autoComplete='one-time-code'
            />
          }
        </div>
        <Timer timer={timer} setTimer={setTimer} />
        {timer <= 0 && (
          <div className='mt-6 text-[10px]'>
            No recibí el código.{' '}
            <strong>
              <u>
                <a onClick={onResend}>Volver a enviar</a>
              </u>
            </strong>
          </div>
        )}
        <Button
          className='bg-black w-full rounded-xl text-white my-8'
          onClick={onContinue}
          disabled={disabled}
          loading={loading}>
          Ingresar
        </Button>
      </div>
      <EmailAndWhatsapp />
    </div>
  );
}

export default Login;


const EmailAndWhatsapp = () => {

  const handlePressWhatsapp = () => {
    let phone = '+56996996794'
    let message = 'Hola! Estoy experimentando problemas técnicos.'
    const url = `https://wa.me/${phone}?text=${message}`;
    window.open(url, '_blank');
  };

  return (
    <div className='mt-[3vh] justify-center flex flex-col items-center text-center'>
      <button onClick={handlePressWhatsapp}>
        <div className='text-sm italic underline'>
          Ayuda
        </div>
      </button>
    </div>

  )
}