import { MdOutlineMenu } from 'react-icons/md';
import Logo from '../assets/Logo.png';
import LogoDark from '../assets/Logo-dark.png';
import './NewHeader.css';
import { Link, useNavigate } from 'react-router-dom';
import { MENU, ROOT } from '../utils/Routes';
import { useSelector } from 'react-redux';
import { s_is_auth } from '../reducer/SessionReducer';
import { BackIcon, MintLogo } from './Icons';
import MenuModal from './MenuModal';
import { useState } from 'react';


function NewHeader({ to = -1, dark, back, noLogo, onBack, hideMenu, LeftComponent, TitleComponent }) {
  const [menu, setMenu] = useState(false);
  const is_auth = useSelector(s_is_auth);
  const color = dark ? 'black' : 'white';
  const logo = dark ? LogoDark : Logo;

  const toggleMenu = () => setMenu(!menu);

  const navigate = useNavigate()
  const handleBack = () => {
    if (onBack) {
      onBack()
    } else {
      navigate(to)
    }
  }

  return (
    <div className='newHeader-wrapper'>
      <div className={`newHeader-content w-full px-6 pt-6 justify-between`}>
        <div className='newHeader-backWrapper'>
          {back && (
            <div onClick={handleBack} style={{ height: 50, width: 50, backgroundColor: '#00000030', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10, borderRadius: 100 }}>
              <BackIcon size={34} color={color} />
            </div>
          )}
          {LeftComponent && <LeftComponent />}
        </div>
        {!noLogo && (
          <Link to={ROOT}>
            <MintLogo dark={dark} />
          </Link>
        )}
        {TitleComponent}
        <div className='newHeader-backWrapper'>
          {!hideMenu && <div onClick={toggleMenu} className='cursor-pointer'>
            <MdOutlineMenu size={34} color={color} />
          </div>}
        </div>
      </div>
      {menu && <MenuModal onClose={toggleMenu} />}
    </div>
  );
}

export default NewHeader;
