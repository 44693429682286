import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../../../components/Headers';
import { BasicButton } from '../../../../components/NewButtons';
import { useApi } from '../../../../network/hooks/useApi';
import { resources } from '../../../../network/resources';
import Step1 from './Step1';
import Step2 from './Step2';
import { useSelector } from 'react-redux';
import { s_user_info } from '../../../../reducer/SessionReducer';
import { ROUTE_CREATE_SUCCESS } from '../../../../utils/Routes';
import { AlertIcon } from '../../../../components/Icons';


const ItemState = {
  slug_field: '',
  name: '',
  price: '',
  quantity: '',
  sizes: '',
  image: null
}

const PaymentInformationState = {
  accountName: '',
  accountNumber: '',
  bankName: '',
  accountType: '',
  email: '',
  phone: '',
  fullName: '',
  city: '',
  country: ''
}

function Merchandising() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1)
  const [products, setProducts] = useState([{ ...ItemState }])
  const [paymentInfo, setPaymentInfo] = useState(PaymentInformationState)
  const [selectedCard, setSelectedCard] = useState(0)
  const { userId } = useSelector(s_user_info)

  const { asyncFetch, error, loading, clearError } = useApi(resources['createMerch'], {
    auto: false,
    method: 'POST',
    upload: true,
  });


  //console.log(userId, 'error', error)
  const handleUpload = async () => {
    try {
      clearError()
      const { accountName, accountNumber, accountType,
        bankName, city, country, email, fullName, phone } = paymentInfo

      const bank_information = `${accountName},${accountNumber},${accountType},${bankName},${city},${country},${email},${fullName},${phone}`

      const form = new FormData();
      form.append('user', userId);
      form.append('bank_information', bank_information);
      form.append('picture', products[0].image);

      for (let i = 0; i < products.length; i++) {
        let v = products[i]
        form.append(`products[${i}]slug_field`, '')
        form.append(`products[${i}]product_name`, v.name)
        form.append(`products[${i}]price`, parseFloat(v.price))
        form.append(`products[${i}]available_size`, v.quantity)
        form.append(`products[${i}]picture`, v.image)
      }

      const reply = await asyncFetch(form);
      console.log('[reply]:', reply);

      if (reply) {
        navigate(ROUTE_CREATE_SUCCESS);
      }
    } catch (e) {
      console.error('[handleUpload]', e)
    }
  };

  const handleAddProduct = () => {
    setProducts([...products, { ...ItemState }])
  }

  const updateProducts = (index, field, value) => {
    const newState = [...products]
    const product = products[index]

    product[field] = value
    newState[index] = product
    setProducts(newState)
  }

  const handleInfoChange = (value, field) => {
    setPaymentInfo({ ...paymentInfo, [field]: value })
  }

  const handleContinue = () => {
    if (step === 2) {
      handleUpload()
    } else {
      setStep(step + 1)
    }
  }

  const handleBack = () => {
    setStep(step - 1)
    clearError()
  }

  const disabled = (step === 1 && disabledProducts(products)) ||
    (step === 2 && disabledBankInfo(selectedCard, paymentInfo)) ||
    loading

  return (
    <div className='bg-white h-screen overflow-scroll font-interRegular'>
      <Header onBack={step === 2 && handleBack} />
      <div className='px-8 pt-4 flex-1 flex flex-col'>
        {error && <div className='bg-red-200 text-xs p-2 rounded-lg flex items-center mb-4'>
          <div className='mr-4'>
            <AlertIcon />
          </div>
          En este momento el sistema se encuentra muy ocupado favor intente mas tarde.
  </div>}
        <div className='text-2xl font-bold font-interRegular mb-2'>Merchandising</div>
        <Steps step={step} />
        <div className='flex-1 flex flex-col justify-between'>
          {step === 1 &&
            <Step1
              products={products}
              updateProducts={updateProducts}
              onAddProduct={handleAddProduct}
            />}
          {step === 2 &&
            <Step2
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              onInfoChange={handleInfoChange}
              {...paymentInfo}
            />
          }
          <div className='mt-10 mb-8'>
            <BasicButton className='w-full text-base h-12' onClick={handleContinue} disabled={disabled} loading={loading}>
              Continuar
            </BasicButton>
          </div>
        </div>
      </div>
    </div>
  );
}

function Steps({ step }) {
  return (
    <ul className='flex my-6 text-[0.6rem]'>
      <li
        className={`mr-6 ${step === 1 ? 'font-semibold' : 'text-black/[.3]'
          } text-[.62rem] font-interRegular`}
      >
        1. Definir productos
      </li>
      <li
        className={`${step === 3 ? 'font-semibold' : 'text-black/[.3]'
          } text-[.62rem] font-interRegular`}
      >
        2. Pagos
      </li>
    </ul>
  );
}

function disabledProducts(products) {
  return (
    products[0].image === null || products[0].name === '' || products[0].price === ''
  )
}

function disabledBankInfo(selectedCard, paymentInfo) {
  if (selectedCard === 0) {
    return (
      paymentInfo.accountName === '' ||
      paymentInfo.accountNumber === '' ||
      paymentInfo.bankName === '' ||
      paymentInfo.accountType === '' ||
      paymentInfo.email === '' ||
      paymentInfo.phone === ''
    )
  } else if (selectedCard === 1) {
    return paymentInfo.email === ''
  } else if (selectedCard === 2) {
    return (
      paymentInfo.email === '' ||
      paymentInfo.phone === '' ||
      paymentInfo.fullName === '' ||
      paymentInfo.city === '' ||
      paymentInfo.country === ''
    )
  }
}

export default Merchandising;
