import useHeight from '../../utils/height';
import { ButtonGrey } from '../NewButtons';
import './ErrorModal.css';


export default function ErrorModal({ onClose }) {
  const { height } = useHeight()

  const handleClose = (e) => {
    if (onClose && e.target === document.getElementById('error-modal')) {
      onClose();
    }
  };

  return (
    <div className='error-modal-background font-interRegular' style={{ height }} id='error-modal' onClick={handleClose}>
      <div className='error-modal-container w-full mx-10 p-4 px-8 text-center relative'>
        <div className='leading-tight mx-4'>
          Ya compraste este producto
        </div>
        {/*<div className='font-bold my-4'>3y12fgda</div>*/}
        <div className='text-xs my-4'>Puedes ver el detalle en tu historial de compras</div>
        {/*<button className='font-bold bg-[#DCDCDC] px-4 py-2 w-full rounded-full' onClick={onClose}>Aceptar</button>*/}
        <ButtonGrey label="Aceptar" onClick={onClose} />
      </div>
    </div>
  );
}
