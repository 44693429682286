import { useEffect, useRef, useState } from 'react'
import './Landing.css'
import { useSwiperSlide } from 'swiper/react';
import { isSafari } from '../../utils';
import { VideoJS } from '../VideoJS';

function LandingVideoBG({ src, onLoad, onProgress, img }) {
    const videoParentRef = useRef(null)
    const swiperSlide = useSwiperSlide();
    const [shouldUseImage, setShouldUseImage] = useState(false);

    useEffect(() => {
        Play()
    }, [swiperSlide.isActive]);

    const Play = () => {
        if (videoParentRef.current) {
            const Player = videoParentRef.current//?.children[0]
            if (Player) {
                Player.controls = false;
                Player.playsinline = true;
                Player.muted = true;
                Player.setAttribute("muted", "");
                Player.autoplay = true;
                if (swiperSlide?.isActive) {
                    Player.play()
                }
                if (!swiperSlide.isActive) {
                    Player.pause();
                }
            }
        }
    }

    return (
        <EmbedVideo
            poster={img}
            src={src}
            className="absolute top-0 left-0 w-full h-[100dvh] object-cover"
            innerRef={videoParentRef}
        />
    );
}

export default LandingVideoBG


/*export var EmbedVideo = function ({ src, className, innerRef, onEnded, poster }) {
    return (
        <div ref={innerRef}
            dangerouslySetInnerHTML={{
                __html:
                    `<video poster='${poster}'loop muted autoplay preload="metadata" playsinline
            src="${src}"
            class="${className}"
        />,
      ` }}>
        </div>
    )
}*/

export function EmbedVideo({ src, className, innerRef, onError, onEnded, poster, onReady, autoplay = false, loop = false, muted }) {

    const handleEnded = (e) => {
        //e.target.play()
        onEnded && onEnded()
    }
    //console.log('embeded video', loop)
    // return (
    //     <video poster={poster} autoPlay preload="metadata" playsInline
    //         src={src}
    //         className={className}
    //         ref={innerRef}
    //         onEnded={handleEnded}
    //         type="application/x-mpegURL"
    //     />
    // )

    const options = {
        autoplay: true,
        // sources: [{
        //     src,
        //     type: 'application/x-mpegURL'
        // }],
        sources: src,
        muted: muted,
        controls: false,
        // fluid: true,
        preload: 'auto',
        userActions: {
            click: false,
            doubleClick: false
        },
        playsinline: true,
        loop: loop,
        // techOrder: ['hls', 'auto']
    }
    return (
        <div className={className}>
            <VideoJS source={src} onError={onError} onReady={onReady} options={options} onEnd={handleEnded} />
        </div>
    )
}