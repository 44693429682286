import React from 'react'
import { IMAGES, Icons } from '../assets'
import { MintLogo } from '../components/Icons'
import Logo from '../assets/Logo.png';

export default function ErrorState() {
    const handlePressWhatsapp = () => {
        let phone = '+56996996794'
        const url = `https://wa.me/${phone}`;
        window.open(url, '_blank');
    };
    return (
        <div style={{ backgroundImage: `url(${IMAGES.ERROR})` }}
            className='h-[100svh] bg-contain bg-no-repeat bg-center flex bg-[black]'>
            <div style={{ zIndex: 10000, opacity: .7, position: 'absolute' }}
                className='h-[100svh] bg-[#1C1C1C] w-full' />
            <div style={{ zIndex: 10000, }}
                className='h-[100svh] flex items-center justify-center flex-col w-full' >
                <div className='flex text-center text-white items-center justify-center flex-col w-[80%]'>
                    <img src={Logo} className='w-[60%]' />
                    <div className='border-b-2 w-10 my-10' />
                    <div className=' text-lg'>
                        Estamos experimentando un alto nivel de tráfico usuario en estos momentos.
                    </div>
                    <div className='mt-[3vh] font-interSemiBold text-lg'>
                        Por favor vuelve a intentarlo en unos minutos.
                    </div>
                    <div className='text-xs  mt-[16vh]'>
                        Escríbenos a nuestra línea de ayuda 24/7 si este problema no se soluciona en los próximos minutos.
                    </div>
                    <button
                        onClick={handlePressWhatsapp}
                        className='flex rounded-md justify-center w-[12rem] mt-[2vh] items-center'>
                        <img src={Icons.whatsapp} className=' w-[2rem]' />
                        <div className='text-sm italic'>
                            + 56 9 9699 6794
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}
