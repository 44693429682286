import React from 'react'
import { MintIcon } from '../Icons';
import './LoadingLogo.css'

export const LoadingLogo = () => {
    return (
        <div className="logo-container center">
            <MintIcon className='breathing-logo' white size={50} />
        </div>
    )
}
