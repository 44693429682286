import React, { useState, useEffect } from 'react';

function useHeight() {
    const [height, setScreenHeight] = useState(window.innerHeight);

    useEffect(() => {
        // Function to update screen height on resize
        const updateScreenHeight = () => {
            setScreenHeight(window.innerHeight);
        };

        // Attach event listener for window resize
        window.addEventListener('resize', updateScreenHeight);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateScreenHeight);
        };
    }, []);

    return {
        height
    }
}

export default useHeight;
