import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";


import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { SwiperFeedsPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import VideoBackground from "../../components/Landing/VideoBackground";
import { BackIcon } from "../../components/Icons";
import { FooterInfo } from "../../components/Landing";

function VideoPurchased() {
    const navigate = useNavigate()
    const { name, slug } = useParams();
    const swiperProfile = SwiperFeedsPath(name, slug)

    const { data, loading: dataLoading } = useApi(resources['singleCreator'].replace(':id', name));
    const { data: dataList, loading: isLoading } = useApi(resources['searchBySlug'].replace(':slug', slug));

    const video = dataList?.data

    if (dataLoading || isLoading) {
        return <CenterSpinner />
    }

    return (
        <div style={{ zIndex: 100 }}>
            <VideoBackground
                src={video?.file_short_version} img={data?.photo} />
            <div>
                <div className="absolute top-0 right-0 left-0">
                    <Header onClick={() => { navigate(swiperProfile) }} />
                </div>
                <FooterInfo
                    name={data?.user?.first_name}
                    title={video?.title}
                    description={video?.description}
                    slug={data?.slug_field}
                />
            </div>
        </div>
    );
}

const Header = ({ onClick, onFlip }) => (
    <div style={{ zIndex: 10000 }} className="p-6 flex justify-between">
        <div onClick={onClick}>
            <BackIcon />
        </div>
    </div>
)

export default VideoPurchased