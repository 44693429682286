import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual } from 'swiper/modules';
import './Swiper.css'
import VideoPlayer from "./VideoPlayer";


const virtual = {
  addSlidesAfter: 4,
  addSlidesBefore: 2
}

function SwiperFeed({ data, mute, onSlideChange }) {
  return (
    <Swiper
      slidesPerView={1}
      grabCursor={true}
      direction="vertical"
      className="swiper-container"
      modules={[Virtual]}
      virtual={virtual}
      onSlideChange={onSlideChange}>

      {data?.map((v, i) => (
        <SwiperSlide key={i} virtualIndex={i}>
          <VideoPlayer src={v.file} mute={mute} index={i} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default SwiperFeed