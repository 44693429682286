import { useEffect, useRef, useState } from "react"
import { useSwiper, useSwiperSlide } from "swiper/react";

import './preview.css'
import Descriptions from "../../components/Landing/Descriptions";
import NewHeader from "../../components/NewHeader";


function Video({ data, src, mute, onLoaded }) {
    const videoRef = useRef(null)
    const swiperSlide = useSwiperSlide();
    const swiper = useSwiper()

    const handlePlay = () => {
        if (videoRef.current.paused) {
            videoRef.current.play()
        } else {
            videoRef.current.pause()
        }
    }

    const handleEnded = () => swiper?.slideNext()

    const handleLoadedData = () => {
        if (swiperSlide.isActive) {
            videoRef.current.play()
            onLoaded && onLoaded()
        }
    }

    useEffect(() => {
        if (videoRef.current !== null) {
            if (!swiperSlide.isActive) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
            } else if (!videoRef.current.ended) {
                videoRef.current.play()
            }
        }
    }, [swiperSlide.isActive]);

    return (
        <div id="video-player" style={{ zIndex: 10 }} className={`w-full h-[100svh]`}>
            <video
                ref={videoRef}
                src={data?.file}
                className="w-full h-[100svh] object-cover"
                preload="auto"
                style={{ zIndex: -10 }}
                onLoadedData={handleLoadedData}
                onEnded={handleEnded}
                playsInline
                muted={mute}
                onClick={handlePlay}
            />
            <div style={{ position: 'absolute', top: 10, width: '100%' }}>
                <NewHeader />
            </div>
            <div style={{ position: 'absolute', bottom: 10, left: '5%' }}
                className="text-white">
                <Descriptions name={data?.userName} title={data?.title} description={data?.description} />
            </div>
        </div>
    )
}

export default Video