import { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Virtual } from 'swiper/modules';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import './preview.css'
import { ROOT } from "../../utils/Routes";
import VideoPlayer from "../../components/swiper/VideoPlayer";
import { ProfileTickets } from "../Profile";
import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { CenterSpinner } from "../../components/Spinner";
import Song from "./song";
import { Previews } from "./json";
import Video from "./video";
import Event from "./event";


export function PreviewFeeds({ onSlideChange, onClick, component }) {
    const { name } = useParams();
    const loc = useLocation();
    const navigate = useNavigate()
    // const { data: trendsData, loading } = useApi(resources['feed']);

    const virtual = {
        addSlidesAfter: 6,
        addSlidesBefore: 6
    }

    const OnBack = () => {
        navigate(-1)
    }

    return (
        <Swiper slidesPerView={1}
            grabCursor={true} direction="vertical" className="swiper-container h-[100svh]"
            modules={[Virtual]}
            loop onSlideChange={onSlideChange}>
            {Previews?.map((v, i) => {
                return (
                    <SwiperSlide
                        style={{ height: '100svh' }}
                        key={i} virtualIndex={i}>
                        <ContentType {...v} item={v} idx={i} onClick={onClick} onBack={OnBack} />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

function ContentType({ idx, item, onBack }) {
    if (item.type == 'audio') return (<Song data={item} />)
    if (item.type == 'video') return (<Video data={item} onBack={onBack} />)
    // if (idx == 0) return (<Song data={item} />)
    // if (idx == 1) return (<ProfileTickets />)
    // if (item.type == 'event') return (<Event data={item} />)
}

export default PreviewFeeds