import { forwardRef } from 'react';
import RH5AudioPlayer from 'react-h5-audio-player';

import './Player.css'

const AudioPlayer = forwardRef(({ src, startTime, endTime, onEnded, onListen, onLoadedData }, ref) => (
  <RH5AudioPlayer
    ref={ref}
    autoPlay={false}
    onLoadedData={onLoadedData}
    autoPlayAfterSrcChange={false}
    showFilledVolume={false}
    customAdditionalControls={[]}
    customControlsSection={[]}
    defaultDuration={endTime}
    defaultCurrentTime={startTime}
    style={{
      backgroundColor: 'transparent',
      border: 0,
      boxShadow: 'none',
    }}
    src={src}
    onEnded={onEnded}
    onListen={onListen}
  />
))

export default AudioPlayer