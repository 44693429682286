import { GoogleLogin } from '@react-oauth/google';

import config from '../../config';
import { useState } from 'react';
import { parseJwt } from '../../utils';
import { isSuccess } from '../../network/hooks/useApi';
import { useDispatch } from 'react-redux';
import { login } from '../../reducer/SessionReducer';
import { updateUserInfo } from '../../reducer/SessionReducer';
// import { ampli } from '../../ampli';
import { TRACK_GOOGLE } from '../../utils/trackingNames';
import ReactGA from 'react-ga4';

async function googleLoginApi(token) {
  const body = {
    token,
    client: 'WEB',
  };
  //console.log('[googleLoginApi]', body);
  const response = await fetch(config.API_URL + '/auth/google/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  });
  //console.log('[googleLoginApi] status ', response.status);
  return await response.json();
}

async function fetchUserInfo(token) {
  const reply = await fetch(`${config.API_URL}/auth/me/`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return await reply.json();
}

const buttonClicked = (name) => {
  //amplitude tracking
  // ampli.buttonClicked({ button: TRACK_GOOGLE });
  ReactGA.event('button', {
    event_name: 'button_clicked',
    item_name: TRACK_GOOGLE,
    creator_name: name,
  });
};

//el google hook no retorna el token id
function GoogleLoginButton({ onLoginSuccess, type, shape, name, onError }) {
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const responseGoogle = async (response) => {
    //track amplitude

    if (!response || !response.credential) {
      setError('Error de autenticación, favor contactar a soporte tecnico');
      return;
    }

    const { email } = parseJwt(response.credential);
    const reply = await googleLoginApi(response.credential, email);
    //console.log('[googleLoginApi]', reply);

    if (!isSuccess(reply)) {
      setError(reply.data);
      return;
    }

    const data = reply?.data;
    const { access_token, refresh_token } = data?.token;
    const { exp } = parseJwt(access_token);

    dispatch(
      login({
        accessToken: access_token,
        refreshToken: refresh_token,
        exp: exp,
        userId: data?.user_id,
        email: data?.email,
        name: data?.name,
        photo: data?.picture,
      })
    );

    const replyUserInfo = await fetchUserInfo(access_token);
    //console.log('[fetchUserInfo]', replyUserInfo);

    if (replyUserInfo !== null) {
      ReactGA.event('app_login', {
        event_name: 'login',
        creator_name: name,
        login_method: TRACK_GOOGLE,
      });

      // ampli
      //   .login({ login_method: TRACK_GOOGLE, creator_name: name })
      //   .promise.then(
      //     dispatch(
      //       updateUserInfo({
      //         creator_id: replyUserInfo?.creator_id,
      //         is_creator: replyUserInfo?.is_creator,
      //         photo: replyUserInfo?.photo,
      //         inner_photo: replyUserInfo?.inner_photo,
      //       })
      //     )
      //   );
    }

    onLoginSuccess && onLoginSuccess();
  };

  return (
    <GoogleLogin
      text={'continue_with'}
      onSuccess={responseGoogle}
      onError={responseGoogle}
      type={type}
      shape={shape}
      size={'large'}
      logo_alignment='left'
      click_listener={buttonClicked(name)}
    //ux_mode="redirect"
    //login_uri='https://web.mintapp.info/shisosa/payment'
    />
  );
}

export default GoogleLoginButton;
