import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import NewHeader from "../../components/NewHeader";
import { ROOT, paymentMethodPath, paymentSelectionPath, playerPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import AudioPlayer from "../../components/Landing/AudioPlayer";
import Descriptions from "../../components/Landing/Descriptions";
import ImageBackground from "../../components/Landing/ImageBackground";
import PlayButton from "../../components/Landing/PlayButton";
import ExpiredButton from "../../components/Landing/ExpiredButton";
import { useDispatch } from "react-redux";
import { setCreatorId, setPaymentOption } from "../../reducer/PaymentReducer";
import useHeight from "../../utils/height";


function SongPlayerMonthly() {
  const { name, slug } = useParams();
  const { height } = useHeight()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const audioRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [reproductions, setReproductions] = useState(null);
  const [loading, setLoading] = useState(true)
  const routeMethod = paymentMethodPath(name, slug);
  const routeSelection = paymentSelectionPath(name, slug);

  const {
    asyncFetch: fetchData,
    data,
  } = useApi(resources['singleCreator'].replace(':id', name), { auto: false });
  const {
    asyncFetch: fetchReproductions,
  } = useApi(resources['getReproductions'], { auto: false });
  const {
    asyncFetch: fetchCheckout,
  } = useApi(resources['checkout'].replace(':slug', slug), {
    method: 'GET',
    auto: false,
  });

  const refreshReproductions = async (slug_field) => {
    const reprod = await fetchReproductions(undefined, slug_field);
    //console.log('[asyncFetchCheckRep]', reprod);
    setReproductions(reprod);
  }

  //console.log('[data]', data)
  const initialFetch = async () => {
    try {
      const res = await fetchData();
      if (res) {
        await refreshReproductions(res.audios[0].slug_field)
        const checkres = await fetchCheckout()
        //console.log('[checkres]', checkres)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  const playAvailable = async () => {
    const res = await fetchCheckout()
    //console.log('[playAvailable]', res)
    return res?.next_step === 'play'
  }

  const handleBuy = async () => {
    const res = await fetchCheckout()

    if (res?.next_step !== 'checkout') {
      window.location.reload();
    } else {
      const repro = await fetchReproductions(undefined, slug);
      const { price, purchase_code, name: description } = res.options[1];

      dispatch(setCreatorId({
        creatorId: data?.pk,
        creatorName: name,
        slugField: slug,
        creator_photo: data?.photo,
        inner_photo: data.inner_photo,
        content_title: audio[0]?.title,
        content_description: audio[0]?.description,
      }));
      dispatch(setPaymentOption({
        option: 1,
        purchaseCode: purchase_code,
        price: price,
        option_description: description,
      }));

      if (repro?.expired_subscriptions) {
        navigate(routeMethod);
      } else {
        navigate(routeSelection);
      }
    }
  }

  useEffect(() => {
    initialFetch()
  }, [])

  useEffect(() => {
    if (reproductions?.subscription_type === 'ppv') {
      navigate(playerPath(name, slug))
    }
  }, [reproductions])

  const handlePlay = async () => {
    const available = await playAvailable()

    if (!available) {
      await refreshReproductions(slug)
      return
    }

    await handleAudio()
  }

  const handleAudio = async () => {
    const ref = audioRef.current.audio.current
    if (ref.paused) {
      await ref.play()
    } else {
      await ref.pause()
    }
    setPlaying(!ref.paused)
  }


  const handleEnded = () => {
    setPlaying(!audioRef.current.audio.current.paused)
  }

  const audio = data?.audios && data?.audios[0]
  const expired = reproductions?.remaining_days === 0 || reproductions?.subscription_type === 'none'

  if (loading) {
    return <CenterSpinner />
  }

  return (
    <div className="relative">
      <ImageBackground src={data?.photo} />
      <div style={{ height: height }}
        className="relative h-[100dvh] flex flex-col justify-between">
        <NewHeader back to={ROOT} />
        <div className="px-10 mb-[8vh] text-white">
          <Descriptions name={data?.user?.first_name} title={audio?.title} description={audio?.description} />
          <AudioPlayer ref={audioRef} src={audio?.file} onEnded={handleEnded} />
          <div className="flex items-center justify-between">
            <PlayButton playing={playing} onClick={expired ? undefined : handlePlay} />
            {expired && <div className='text-white w-32 text-right mr-8 italic leading-none font-interLight'>
              Agotaste tus reproducciones
            </div>}
          </div>
          <div className="my-6">
            {!expired && <div className='text-white italic text-center mb-2 font-sm'>
              ¡Tienes {reproductions?.remaining_days} días para escuchar
              esta canción!
            </div>}
            {expired && <div className='text-white italic mb-2 font-semibold text-center'>
              ¡Sigue escuchándola!
            </div>}
          </div>
          <ExpiredButton loading={loading} expired={expired} onClick={handleBuy} />
        </div>
      </div>
    </div>
  );
}

export default SongPlayerMonthly;
