export const ROOT = '/';
export const FEED = '/feed';
export const HORIZONTAL_FEED = '/videos/horizontal';
export const CREATE = '/crear';
export const LOGIN = '/auth/login';
export const PROFILE_VIDEO = '/:name/videos/:presale';
export const PROFILE_AUDIO = '/creador/:name/audios/:presale';
export const PROFILE_IMAGE = '/creador/:name/images/:presale';
export const PROFILE_TEXT = '/creador/:name/texts/:presale';
export const VIDEO_PLAYER = '/videoplayer/:id';
export const PROFILE_TEXT_DETAIL = '/texts/detail/:id';
export const PROFILE_TEXT_FULL = '/texts/full/:id';
export const STORE_MERCHANDISING = '/creador/:name/merchandising';
export const STORE_ONLINE_SHOWS = '/creador/:name/onlineshows';
export const STORE_VIDEOCALLS = '/creador/:name/videocalls';
export const MENU = '/menu';
export const PROFILE_IMAGE_DETAIL = '/images/detail/:id';
export const PROFILE_IMAGE_FULL = '/images/full/:id';
export const CREATOR_PROFILE = '/creador/:name';
export const PAYMENT = 'payment';
export const SUCCESS = '/success';
export const PAYMENT_SELECTION = '/payment/selection';
export const PAYMENT_METHOD = '/payment/method';
export const PAYMENT_MENU = 'payment';
export const CARD_PAYMENTS = 'payment';
export const TRANSACTION_HISTORY = '/transaction/history';
export const CREATE_AUDIO = '/crear/audio';
export const WELCOME = '/bienvenido';

export const STORE = '/store';
export const STORE_TICKETS = '/store/tickets';

export const ROUTE_LOGIN = '/:name/login/:slug/begin';
export const ROUTE_TICKETS = '/:name/entradas';
export const ROUTE_PLAYER = '/:name/player/:slug';
export const ROUTE_LANDING = '/:name/landing/:slug';
export const ROUTE_PAYMENT_SELECTION = '/:name/plans/:slug';
export const ROUTE_PAYMENT_METHOD = '/:name/payment-method/:slug';
export const ROUTE_SWIPER = '/:name/:slug';
export const ROUTE_CREATE_SUCCESS = '/crear/uploadsuccess';

export const ROUTE_ONLINESHOW_COUNTDOWN = '/store/countdown'
export const ROUTE_MIS_COMPRAS = '/profile/mis-compras'
export const ROUTE_CONFIGURATION = '/profile/configuration'
export const PURCHASE_DONE = '/:name/code/purchased/:slug'

export function loginPath(name, slug) {
  return ROUTE_LOGIN.replace(':name', name).replace(':slug', slug);
}
export function purchasePath(name, slug) {
  return PURCHASE_DONE.replace(':name', name).replace(':slug', slug);
}
export function ticketsPath(name) {
  return ROUTE_TICKETS.replace(':name', name)
}

export function SwiperFeedsPath(name, slug) {
  return ROUTE_SWIPER.replace(':name', name).replace(':slug', slug)
}

export function paymentSelectionPath(name, slug) {
  return ROUTE_PAYMENT_SELECTION.replace(':name', name).replace(':slug', slug);
}

export function paymentMethodPath(name, slug) {
  return ROUTE_PAYMENT_METHOD.replace(':name', name).replace(':slug', slug);
}

export function playerPath(name, slug) {
  return ROUTE_PLAYER.replace(':name', name).replace(':slug', slug);
}

export function landingPath(name, slug) {
  return ROUTE_LOGIN.replace(':name', name).replace(':slug', slug);
}

export function previewPath(name, slug) {
  return `/${name}/preview/${slug}`
}

export function playerMonthlyPath(name, slug) {
  return `/${name}/player/monthly/${slug}`
}

export function purchasedAudioPath(name, slug) {
  return `/${name}/audio/${slug}`
}
export function purchasedVideoPath(name, slug) {
  return `/${name}/video/${slug}`
}

export function landingVideoPlayerPath(name, slug) {
  return `/${name}/player/video/${slug}`
}

export function landingPreviewVideoPlayerPath(name, slug) {
  return `/${name}/player/video/preview/${slug}`
}

export function videoPlayerPath(name, slug) {
  return `/${name}/player/video/play/${slug}`
}

export function videoMonthlyPath(name, slug) {
  return `/${name}/player/video/monthly/${slug}`
}

export function onlineShowsBuyPath(name) {
  return `/${name}/online/buy`
}

export function merchStorePath(name) {
  return `/${name}/merch/store`
}

export function merchStoreCheckoutPath(name) {
  return `/${name}/merch/store/checkout`
}

export function creatorProfilePath(name) {
  return `/${name}/profile`
}

export function merchVoucherPath(name) {
  return `/${name}/merch/store/voucher`
}

export function codePurchasedPath(name, slug) {
  return `/${name}/code/purchased/${slug}`
}