import { useEffect, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Virtual } from 'swiper/modules';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import './Swiper.css'
import { ROOT } from "../../utils/Routes";
import VideoPlayer from "../../components/swiper/VideoPlayer";
import { ProfileTickets } from "../Profile";
import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { CenterSpinner } from "../../components/Spinner";


function VerticalFeeds({ onClick }) {
    const { name } = useParams();
    // const name = 'shisosa'
    const loc = useLocation();
    const navigate = useNavigate()
    const param = loc?.state?.to ?? false
    const { data: trendsData, loading } = useApi(resources['trends']);
    const [trends, setTrends] = useState([])

    const toPlayer = param == 'play'

    const virtual = {
        addSlidesAfter: 6,
        addSlidesBefore: 6
    }

    useEffect(() => {
        if (name == 'shisosa') {
            let data = [1, 2, ...trendsData]
            setTrends(data)
        }
        if (name != 'shisosa') {
            setTrends([1])
        }
    }, [trendsData])

    // const arrr = name == 'shisosa' ?  : 
    const OnBack = () => {
        navigate(-1)
    }

    const HandleSlideChange = () => {
        // console.log('slide chnaged')
    }

    return (
        <Swiper slidesPerView={1}
            initialSlide={trends?.length > 1 && toPlayer ? 1 : 0}
            grabCursor={true} loop direction="vertical" className="swiper-container h-[100svh]"
            modules={[Virtual]}
            onSlideChange={HandleSlideChange}>
            {trends?.map((v, i) => {
                return (
                    <SwiperSlide
                        className="h-[100svh]"
                        key={i} virtualIndex={i}>
                        <ContentType {...v} idx={i} onClick={onClick} name={name} onBack={OnBack} />
                    </SwiperSlide>
                )
            })}
        </Swiper>

    )
}

function ContentType({ idx, name, file, description, title, onClick, onBack }) {
    if (idx == 0 && name == 'shisosa') return (<ProfileTickets />)
    //if (idx == 0 && name != 'shisosa') return (<Profile />)
    //if (idx == 1) return (<Profile />)
    if (idx !== 0 || idx !== 1) return (
        <VideoPlayer src={file} onBack={onBack} onClick={onClick} description={description} title={title} />
    )
}

export default VerticalFeeds