import { Separator } from "../../../../components";
import Counter from "../../../../components/Counter";
import { BasicButton } from "../../../../components/NewButtons";
import { InputBordered } from "../../../../components/NewInputs";


export default function Step2({ onContinue }) {
  return (
    <>
      <div className='text-lg font-interBold '>Datos de entrega</div>
      <div className='mt-[2svh]' />
      <InputBordered placeholder='Nombre Completo *' />
      <InputBordered placeholder='RUT *'  />
      <InputBordered placeholder='Correo Electrónico o Número de Teléfono *' />
      <div className="flex">
        <InputBordered placeholder='Dirección *' />
        <InputBordered className="ml-2" placeholder='Código Postal *' />
      </div>
      <div className='flex justify-center' >
        <BasicButton className='mt-[2svh] w-full' onClick={onContinue}>
          <div className='text-2xl'>Continuar</div>
        </BasicButton>
      </div>
    </>
  )
}

