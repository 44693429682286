import { Link } from "react-router-dom";
import { creatorProfilePath } from "../../utils/Routes";
import { LiveIcon1, SoundWhiteIcon, VideoWhiteIcon } from "../Icons";

export function VideoDescriptions({ title, description, producer, slug, uid, isAudio, type }) {
    const lowerName = slug?.toLowerCase()
    let description1 = description ? `Ft. ${description}` : ''
    let producer1 = producer ? `${description ? ' - ' : ''}Prod. ${producer}` : ''
    return (
        <div>
            <div className='flex items-center justify-center'>
                {type == 'live' ?
                    <LiveIcon1 height={25} width={25} fill='white' />
                    : isAudio ? <SoundWhiteIcon /> :
                        <div className="mb-2">
                            <VideoWhiteIcon />
                        </div>
                }
                <div className='ml-2' />
                <p className='text-[22px] font-interBold leading-[2rem] w-full mb-1'>
                    {title}
                </p>
            </div>
            <p className='text-[18px] italic font-interExtraLight font-thin leading-5'>
                {description1}{producer1}
            </p>
            <Link to={creatorProfilePath(uid)}>
                <p className='text-[18px] font-interNormal mt-5'>
                    @{lowerName}
                </p>
            </Link>
        </div>
    )
}