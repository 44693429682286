import { useNavigate } from 'react-router-dom';
import { BackIcon } from '../../components/Icons';
import Logo from '../../assets/Logo.png';
import NewHeader from '../../components/NewHeader';

function Privacy() {
  const navigate = useNavigate();
  return (
    <div className='bg-white'>
      <div className=' pb-10 pt-5 flex justify-center bg-[#372D90]'>
        <NewHeader back />
      </div>
      <div className='p-6'>
        <h1 className='font-interBold text-xl pb-5'>
          Política de Términos y Condiciones
        </h1>
        <p className='font-interRegular text-sm'>
          Bienvenido a MintApp. Estos términos y condiciones describen las
          reglas y regulaciones para el uso del sitio web
          http://web.mintapp.info.
        </p>
        <br />
        <p className='font-interBold text-sm'>
          MintApp se encuentra domiciliada en Don Carlos 2970, of 703, Las
          Condes, Santiago de Chile.
        </p>
        <br />
        <p className='font-interRegular text-sm'>
          Al acceder a este sitio web, asumimos que aceptas estos términos y
          condiciones en su totalidad. No continúes usando este sitio web si no
          aceptas todos los términos y condiciones establecidos en esta página.
        </p>
        <br />
        <p className='font-interRegular text-sm'>
          La siguiente terminología se aplica a estos Términos y Condiciones,
          Declaración de Privacidad y Aviso legal y cualquiera o todos los
          Acuerdos: el Cliente, Usted y Su se refieren a usted, la persona que
          accede a este sitio web y acepta los términos y condiciones de la
          Compañía. La Compañía, Nosotros mismos, Nosotros y Nuestro, se refiere
          a nuestra Compañía. Parte, Partes o Nosotros, se refiere en conjunto
          al Cliente y a nosotros mismos, o al Cliente o a nosotros mismos.
        </p>
        <br />
        <p className='font-interEgular text-sm'>
          Todos los términos se refieren a la oferta, aceptación y consideración
          del pago necesario para efectuar el proceso de nuestra asistencia al
          Cliente de la manera más adecuada, ya sea mediante reuniones formales
          de una duración fija, o por cualquier otro medio, con el propósito
          expreso de conocer las necesidades del Cliente con respecto a la
          provisión de los servicios/productos declarados de la Compañía, de
          acuerdo con y sujeto a la ley vigente chilena
        </p>
        <br />
        <p className='font-interEgular text-sm'>
          Cualquier uso de la terminología anterior u otras palabras en
          singular, plural, mayúsculas y/o, él/ella o ellos, se consideran
          intercambiables y, por lo tanto, se refieren a lo mismo.
        </p>
        <br />
        <h2 className='font-interBold text-base'>Cookies</h2>
        <br />
        <p className='font-interRegular text-sm'>
          Empleamos el uso de cookies. Al utilizar el sitio web de MintApp usted
          acepta el uso de cookies de acuerdo con la política de privacidad de
          MintApp. La mayoría de los modernos sitios web interactivos de hoy en
          día usan cookies para permitirnos recuperar los detalles del usuario
          para cada visita.
        </p>
        <br />
        <p className='font-interRegular text-sm'>
          Las cookies se utilizan en algunas áreas de nuestro sitio para
          habilitar la funcionalidad de esta área y la facilidad de uso para las
          personas que lo visitan.
        </p>
        <br />
        <h2 className='font-interBold text-base'>Licencia</h2>
        <br />
        <p className='font-interRegular text-sm'>
          Todos los materiales publicados en el sitio cuentan con una licencia
          de uso por parte de MintApp, y su propiedad intelectual está reservada
          por parte de sus respectivos creadores. Está prohibido copiar,
          descargar o intentar reproducir estos materiales por cualquier medio
          que no sean los provistos por la aplicación.
        </p>
        <br />
        <h2 className='font-interBold text-base'>Aviso legal</h2>
        <br />
        <p className='font-interRegular text-sm'>
          En la medida máxima permitida por la ley aplicable, excluimos todas
          las representaciones, garantías y condiciones relacionadas con nuestro
          sitio web y el uso de este sitio web (incluyendo, sin limitación,
          cualquier garantía implícita por la ley con respecto a la calidad
          satisfactoria, idoneidad para el propósito y/o el uso de cuidado y
          habilidad razonables).
        </p>
        <br />
        <p className='font-interRegular text-sm'>Nada en este aviso legal:</p>
        <br />
        <ul className='list-disc pl-5 font-interRegular text-sm'>
          <li>
            Limita o excluye nuestra o su responsabilidad por muerte o lesiones
            personales resultantes de negligencia.
          </li>
          <li>
            Limita o excluye nuestra o su responsabilidad por fraude o
            tergiversación fraudulenta.
          </li>
          <li>
            Limita cualquiera de nuestras o sus responsabilidades de cualquier
            manera que no esté permitida por la ley aplicable.
          </li>
          <li>
            Excluye cualquiera de nuestras o sus responsabilidades que no pueden
            ser excluidas bajo la ley aplicable.
          </li>
        </ul>
        <br />
        <p className='font-interRegular text-sm'>
          Las limitaciones y exclusiones de responsabilidad establecidas en esta
          Sección y en otras partes de este aviso legal:
        </p>
        <br />
        <ol className='list-decimal font-interRegular text-sm pl-5'>
          <li>Están sujetas al párrafo anterior; y</li>
          <li>
            rigen todas las responsabilidades que surjan bajo la exención de
            responsabilidad o en relación con el objeto de esta exención de
            responsabilidad, incluidas las responsabilidades que surjan en
            contrato, agravio (incluyendo negligencia) y por incumplimiento del
            deber legal.
          </li>
        </ol>
        <br />
        <p className='font-interRegular text-sm'>
          En la medida en que el sitio web y la información y los servicios en
          el sitio web se proporcionen de forma gratuita, no seremos
          responsables de ninguna pérdida o daño de ningún tipo.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
