import React from 'react'
import { Icons } from '../../assets'
import './paymentCard.css'

export const PaymentCard = ({ selected, icon, ...props }) => {
    return (
        <div {...props}
            style={{ backgroundColor: selected ? '#D9D9D9' : '', borderColor: selected ? '#D9D9D9' : '' }}
            className={`card_wrapper`} >
            <img className='card_image w-16' src={icon} />
        </div>
    )
}
