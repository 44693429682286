import { Swiper, SwiperSlide } from "swiper/react";

import { Header } from "../../../../components/Headers";

import merch1 from '../../../../assets/tmp/image 26.png'
import merch2 from '../../../../assets/tmp/image 29.png'
import merch3 from '../../../../assets/tmp/image 36.png'
import Counter from "../../../../components/Counter";
import { BasicButton } from "../../../../components/NewButtons";
import { CartIcon } from "../../../../components/Icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { merchStoreCheckoutPath } from "../../../../utils/Routes";
import { useApi } from "../../../../network/hooks/useApi";
import { resources } from "../../../../network/resources";
import { FormatMoney } from "../../../../utils";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProducts, s_merch_products } from "../../../../reducer/MerchReducer";

const dummy = {
  products: [{
    id: '1',
  picture: merch1,
  product_name: 'Test 1',
  price: 1990
}, {
    id: '2',
  picture:  merch2,
  product_name: 'Test 2',
  price: 5990
}, {
    id: '3',
  picture: merch3,
  product_name: 'Test 3',
  price: 10990
}]}


export default function Merch() {
  const { name } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [products, setProducts] = useState({})
  const merchProducts = useSelector(s_merch_products)
  const { state: productsData } = useLocation()

  const { data, error, loading } = useApi(resources['merchProducts'].replace(':id', 1));

  console.log('products', productsData)
  const handleContinue = () => {
    navigate(merchStoreCheckoutPath(name))
  }

  const handleAddProduct = () => {
    dispatch(addProducts(Object.values(products)))
    setProducts({})
  }

  const handleAdd = (product) => {
    const newState = { ...products }
    const exist = newState[product.id]

    if (exist) {
      exist.quantity += 1
    } else {
      newState[product.id] = {
        ...product,
        quantity: 1
      }
    }
    setProducts(newState)
  }

  const handleRemove = (product) => {
    const newState = { ...products }
    const exist = newState[product.id]

    if (exist) {
      exist.quantity = Math.max(exist.quantity - 1, 0)
    }
    setProducts(newState)
  }

  const totalQuantity = merchProducts.reduce(calcQuantity, 0)

  return (
    <div className="bg-white w-full h-[100dvh]">
      <Header title={''} />
      <div className="relative px-10">
        <div className="absolute right-10 top-0 z-10">
          <div className="relative bg-[#D9D9D9] p-3 rounded-full" onClick={handleContinue}>
            <CartIcon />
            <div className="absolute top-[-8px] right-0 text-xs text-white rounded-full bg-[#372D90] w-5 h-5 flex items-center justify-center">{totalQuantity}</div>
          </div>
        </div>
        <div className="">
          <Swiper slidesPerView={1} direction="vertical" className="h-[70dvh]" onActiveIndexChange={v => setActiveIndex(v.activeIndex)}>
            {productsData?.map((v, index) => (
              <SwiperSlide key={index}>
                <Slide product={v} quantityProduct={products[v.id]} onAdd={handleAdd} onRemove={handleRemove} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div>
          <BasicButton className="w-full" onClick={handleAddProduct}>
            Agregar a mi carrito
          </BasicButton>
          <BasicButton className="w-full mt-4" onClick={handleContinue}>
            Continuar
          </BasicButton>
        </div>
      </div>
    </div>
  )
}

function Slide({ product, onAdd, quantityProduct, onRemove }) {
  const handleAdd = () => onAdd(product)
  const handleRemove = () => onRemove(product)

  return (
    <div className="flex flex-col items-center mt-6">
      <img src={product.picture} alt={product.product_name} className="object-contain h-[40dvh]" />
      <div className="text-center mt-6">
        <div className="font-bold">{product.product_name}</div>
        <div className="text-sm">${FormatMoney(product.price)}</div>
      </div>
      <div className="my-6">
        <Counter value={quantityProduct ? quantityProduct.quantity : 0} onAdd={handleAdd} onSubtract={handleRemove}/>
      </div>
    </div>
  )
}

function calcQuantity(t, v) {
  return t + v.quantity
}