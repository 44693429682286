// const API_URL = process.env.REACT_APP_API_URL || 'https://dev1.api.mintapp.info';
const API_URL = process.env.REACT_APP_API_URL || 'https://us-central1-mint-99dbe.cloudfunctions.net';
const CDN_BASE_URL = process.env.CDN_BASE_URL

// const API_AMPLITUDE = process.env.REACT_APP_AMPLITUDE;
// const API_AMPLITUDE = '2fe047b9ffd6fed14adb00d207e2b38c';
const API_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GA;


const STRIPE_KAY_TEST = process.env.STRIPE_KAY_TEST || 'pk_test_51IRN8QL4V50seAIFhJk5br7bD5WFuevXCRVxtJpsHN6hOBn20ltY2Ut5n7l1xWUbfKZtxvinyaWcqfh27mnQC2St00HDyhYXvS'
const DEV_FIREBASE_URL = 'https://us-central1-mint-dev-d054f.cloudfunctions.net'
const CDN_DEV = 'https://d3hk1fson3ernp.cloudfront.net/'

const STRIPE_KAY_LIVE = process.env.STRIPE_KAY_LIVE || 'pk_live_51IRN8QL4V50seAIF4vaUPdS29E4Y96sINTbpoG9auHLha5nsGw1Booj7z3kCJvyMWeFKx4YVxHkVhAIPcdD8twlI00aIydLlV2';
const CDN_LIVE = `https://d33heirl6jmsf6.cloudfront.net/`
const LIVE_FIREBASE_URL = 'https://us-central1-mint-99dbe.cloudfunctions.net'


const firebaseConfig = {
  apiKey: "AIzaSyC8rIY_g3oDJCV19hR9Kn9dxCKAZItz2VM",
  authDomain: "mint-99dbe.firebaseapp.com",
  projectId: "mint-99dbe",
  storageBucket: "mint-99dbe.appspot.com",
  messagingSenderId: "677566672200",
  appId: "1:677566672200:web:37077a53dcaf84ea90577d",
  measurementId: "G-34RSGQRL7Q"
};

const firebaseConfigDev = {
  apiKey: "AIzaSyCQH62YQzetTn6rvRr9u_d6nkOHp90jeSo",
  authDomain: "mint-dev-d054f.firebaseapp.com",
  projectId: "mint-dev-d054f",
  storageBucket: "mint-dev-d054f.appspot.com",
  messagingSenderId: "173627373005",
  appId: "1:173627373005:web:4dfbf9f6dd9ff835998084",
  measurementId: "G-1RWJLG04FG"
};

//live
const APP_URL = 'https://web.mintapp.info';
const FUNCTIONS_URL = LIVE_FIREBASE_URL
const CDN = CDN_LIVE
const STRIPE_KAY = STRIPE_KAY_LIVE
const FIREBASE_CONFIG = firebaseConfig
const API_AMPLITUDE = 'eb786e11e7c27d9e99e445efd612deda';

//dev
// const APP_URL = 'https://dev.web.mintapp.info';
// const FUNCTIONS_URL = DEV_FIREBASE_URL
// const CDN = CDN_DEV
// const STRIPE_KAY = STRIPE_KAY_TEST
// const FIREBASE_CONFIG = firebaseConfigDev
// const API_AMPLITUDE = '2fe047b9ffd6fed14adb00d207e2b38c';

export default {
  API_URL,
  API_AMPLITUDE,
  API_ENVIRONMENT,
  GOOGLE_ANALYTICS_KEY,
  FUNCTIONS_URL,
  APP_URL,
  CDN,
  STRIPE_KAY,
  FIREBASE_CONFIG,
};
