import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import NewHeader from "../../components/NewHeader";
import { ROOT, paymentMethodPath, paymentSelectionPath, playerMonthlyPath } from "../../utils/Routes";
import { CenterSpinner, SmallSpinner } from "../../components/Spinner";
import AudioPlayer from "../../components/Landing/AudioPlayer";
import Descriptions from "../../components/Landing/Descriptions";
import ImageBackground from "../../components/Landing/ImageBackground";
import PlayButton from "../../components/Landing/PlayButton";
import ExpiredButton from "../../components/Landing/ExpiredButton";
import { setCreatorId, setPaymentOption } from "../../reducer/PaymentReducer";
import { useDispatch } from "react-redux";
import useHeight from "../../utils/height";


/**
 * Song player
 */
function SongPlayer() {
  const { name, slug } = useParams();
  const { height } = useHeight()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const audioRef = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [reproductions, setReproductions] = useState(null);
  const [loading, setLoading] = useState(true)
  const [currentTime, setCurrentTime] = useState(0.0)
  const [isForthReproduction, setIsForthReproduction] = useState(false);
  const [playEnded, setPlayEnded] = useState(false);
  const routeMethod = paymentMethodPath(name, slug);
  const routeSelection = paymentSelectionPath(name, slug);

  const {
    asyncFetch: fetchData,
    data,
  } = useApi(resources['singleCreator'].replace(':id', name), { auto: false });
  const {
    asyncFetch: fetchReproductions,
  } = useApi(resources['getReproductions'], { auto: false });
  const {
    asyncFetch: fetchCheckout,
  } = useApi(resources['checkout'].replace(':slug', slug), {
    method: 'GET',
    auto: false,
  });
  const {
    asyncFetch: fetchUseReproduction,
    loading: loadingUseReproduction
  } = useApi(resources['useReproductions'].replace(':slug', slug), {
    method: 'GET',
    auto: false,
  });

  const refreshReproductions = async (slug_field) => {
    const res = await fetchReproductions(undefined, slug_field);
    //console.log('[fetchReproductions]', res);
    setReproductions(res);
    if (res.remaining_reproductions == 1 && res.subscription_type === 'ppv') {
      setIsForthReproduction(true)
    }
  }

  //console.log('[reproductions]', reproductions)
  const initialFetch = async () => {
    try {
      const res = await fetchData();
      if (res) {
        await refreshReproductions(res.audios[0].slug_field)
        const checkres = await fetchCheckout()
        console.log('[checkres]', checkres)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  const decreaseReproduction = async () => {
    await fetchUseReproduction()
    await refreshReproductions(slug)
  }

  const playAvailable = async () => {
    const res = await fetchCheckout()
    return res?.next_step === 'play'
  }

  const handleBuy = async () => {
    const res = await fetchCheckout()

    if (res?.next_step !== 'checkout') {
      window.location.reload();
    } else {
      const repro = await fetchReproductions(undefined, slug);
      const { price, purchase_code, name: description } = res.options[0];

      dispatch(setCreatorId({
        creatorId: data?.pk,
        creatorName: name,
        slugField: slug,
        creator_photo: data?.photo,
        inner_photo: data.inner_photo,
        content_title: audio[0]?.title,
        content_description: audio[0]?.description,
      }));
      dispatch(setPaymentOption({
        option: 1,
        purchaseCode: purchase_code,
        price: price,
        option_description: description,
      }));

      if (repro?.expired_subscriptions) {
        navigate(routeMethod);
      } else {
        navigate(routeSelection);
      }
    }
  }

  useEffect(() => {
    initialFetch()
  }, [])

  useEffect(() => {
    if (reproductions?.subscription_type === 'monthly') {
      navigate(playerMonthlyPath(name, slug))
    }
  }, [reproductions])

  useEffect(() => {
    if (Math.round(currentTime) === 15 && !loadingUseReproduction) {
      decreaseReproduction()
    }
  }, [currentTime]);

  if (loading) {
    return <CenterSpinner />
  }

  const handlePlay = async () => {
    if (isForthReproduction) {
      await handleAudio()
      return
    }

    const available = await playAvailable()
    if (!available) {
      return
    }

    await handleAudio()
  }

  const handleAudio = async () => {
    const ref = audioRef.current.audio.current
    if (ref.paused) {
      await ref.play()
      setPlayEnded(false)
    } else {
      await ref.pause()
    }
    setPlaying(!ref.paused)
  }

  const handleEnded = () => {
    setPlaying(!audioRef.current.audio.current.paused)
    setPlayEnded(true)
  }

  const handleListen = (e) => {
    setCurrentTime(e.target.currentTime)
  }

  const audio = data?.audios && data?.audios[0]
  const playingFour = isForthReproduction && !playEnded
  const expired = (reproductions?.remaining_reproductions === 0 || reproductions?.subscription_type === 'none') && !playingFour

  return (
    <div className="relative">
      <ImageBackground src={data?.photo} />
      <div
        style={{ height: height }}
        className="relative h-[100dvh] flex flex-col justify-between">
        <NewHeader back to={ROOT} />
        <div className="px-10 mb-[8vh] text-white">
          <Descriptions name={data?.user?.first_name} title={audio?.title} description={audio?.description} />
          <AudioPlayer ref={audioRef} src={audio?.file} onEnded={handleEnded} onListen={handleListen} />
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <PlayButton playing={playing} onClick={expired ? undefined : handlePlay} />
              {!expired && <div className='text-white ml-3 italic text-sm'>
                {4 - reproductions?.remaining_reproductions}/4
              </div>}
            </div>
            {expired && <div className='text-white w-32 text-right mr-8 italic leading-none font-interLight'>
              Agotaste tus reproducciones
            </div>}
          </div>
          <div className="my-4">
            {!expired && <div className='font-light text-center'>
              Dale <strong className='underline underline-offset-4 font-bold'>play</strong> para escuchar tu canción
            </div>}
            {expired && <div className='text-white italic font-semibold text-center'>
              ¡Sigue escuchándola!
            </div>}
          </div>
          <ExpiredButton loading={loading} expired={expired} onClick={handleBuy} />
        </div>
      </div>
    </div>
  );
}

export default SongPlayer;
