import React from 'react'
import useHeight from '../../../utils/height'
import NewHeader from '../../../components/NewHeader'
import { BasicButton } from '../../../components/NewButtons'
import { useNavigate, useParams } from 'react-router-dom'
import { ROOT } from '../../../utils/Routes'

export default function UnSuccess() {
    const { height } = useHeight()
    const navigate = useNavigate();
    return (
        <div style={{ height: height }}
            className=' bg-[#fff] '>
            <NewHeader dark />
            <div className='px-[5%] mt-[15dvh]'>
                <div className='text-[32px] font-interSemiBold'>
                    No pudimos procesar tu pago
                </div>
                <div className='text-18px] my-[8dvh]'>
                    Reintenta el pago o contáctate con tu institución financiera.
                </div>
                <BasicButton onClick={() => navigate(ROOT)}>
                    Reintentar pago
                </BasicButton>
            </div>
        </div>
    )
}
