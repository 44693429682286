import React, { useEffect } from "react";
import {
    getCountries,
    getCountryCallingCode,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";

const CountrySelect = ({
    value,
    setSelectedCountry,
    onChange,
    labels,
    errors,
    touched,
    labelType = "full",
    countryCode,
    ...rest
}) => {
    return (
        <select
            style={{ maxWidth: value?.length == 2 ? 50 : value?.length == 3 ? 55 : value?.length == 4 ? 65 : 70/*, backgroundColor: '#fff'*/ }}
            {...rest}
            value={value}
            onChange={onChange}
        >
            <option value={value}>
                {value ?? "Country"}
            </option>
            {getCountries().map((country) => (
                <option
                    key={country}
                    value={`+${getCountryCallingCode(country)}`}
                >
                    {labels[country]} +{getCountryCallingCode(country)}
                </option>
            ))}
        </select>
    );
};

export default CountrySelect;