import React, { useMemo, useState } from 'react'
import NewCard from '../../../../components/NewCard'
import { ROOT, STORE } from '../../../../utils/Routes';
import { IMAGES } from '../../../../assets';
import { ArrowButton, BasicButton } from '../../../../components/NewButtons';
import { useNavigate } from 'react-router-dom';
import { InputBordered, InputSimple, InputUnderline, NewCheckbox } from '../../../../components/NewInputs';
import { MachIcon, OnepayIcon, WebpayIcon } from '../../../../components/Icons';
import { useApi } from '../../../../network/hooks/useApi';
import { resources } from '../../../../network/resources';
import { empty } from '../../../../utils';
import { Checkbox } from '../../../../components/Inputs';
import { useSelector } from 'react-redux';
import { s_is_auth, s_session_token } from '../../../../reducer/SessionReducer';
import config from '../../../../config';
import './tickets.css'

export const BuyTickets = () => {
    const navigate = useNavigate();
    const accessToken = useSelector(s_session_token);

    const [step, setStep] = useState(1)
    const [preVenta1, setPreVenta1] = useState(0)
    // const [vip, setVIP] = useState(0)
    const [preVenta2, setPreVenta2] = useState(0)
    const [general, setGeneral] = useState(0)

    const [fullName, setFullName] = useState('')
    const [emailOrPhone, setEmailOrPhone] = useState('')
    const [rut, setRut] = useState('')
    const [isChecked, setIsChecked] = useState(false)

    const [myArr, setMyArr] = useState([]);
    const [ndLoading, setndLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null);
    const [startValue, setStart] = useState(3);

    let totlaTickets = preVenta1
    const count = totlaTickets;

    useMemo(() => {
        const myArray = Array.from({ length: count }, (_, index) => ({ name: '', rut: '', contact_info: '', ticket_layer: 'preventa', }));
        if (!isChecked) myArray.pop(1)
        setMyArr(myArray)
    }, [count, isChecked])

    async function buyTicket(endpoint, body) {
        try {
            // setLoading(true)
            const reply = await fetch(`${config.API_URL}/store/event/buy_tickets/shisosa-party/${endpoint}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
                body: JSON.stringify(body)
            });
            return await reply.json();
        } catch (error) {
            setLoading(false)
        }
    }

    const Payment = async (type) => {
        try {
            setLoading(true)
            let endpoint = type == 1 ? 'flow' : 'addCreditCard'
            console.log('endpoint', endpoint)
            const body = {
                buyer: {
                    name: fullName,
                    contact_info: emailOrPhone,
                    rut: rut,
                    ticket_layer: 'preventa',
                },
                friends: myArr,
            }
            let reply = await buyTicket(endpoint, body)
            console.log('reply', reply)
            if (reply) {
                window.location.replace(reply.payment_url)
            }
            else {
                navigate(-1)
                alert("Something went wrong please start the process again")
            }
            setLoading(false)
            setType(null)
        } catch (error) {
            console.log('error', error)
        }
    }

    const handleBack = () => {
        if (step === 1) {
            navigate(ROOT)
        } else {
            setStep(Math.max(1, step - 1))
        }
    }

    const handleGuestChange = (index, field, value) => {
        const updatedGuests = [...myArr];
        updatedGuests[index][field] = value;
        setMyArr(updatedGuests);
    };

    const HandleContinue = (type) => {
        // navigate(`${STORE}/success`)
        setType(type)
        Payment(type)
    }

    const dataCard = {
        photo: IMAGES.TICKETSBUY,
        title: '',
    };

    let arraySteps = startValue - 1 + myArr?.length
    const ShowArray = step >= startValue && step <= arraySteps

    return (
        <div className='bg-white h-screen'>
            <NewCard
                className={'h-[45svh] bg-top'}
                data={dataCard} onBack={handleBack} />
            <div className='px-6'>
                <Steps step={step}
                    ShowArray={ShowArray}
                    showPayment={Number(myArr?.length) + startValue == step} />
                {step == 1 &&
                    <Step1
                        preVenta1={preVenta1}
                        setPreVenta1={setPreVenta1}
                        // setVIP={setVIP}
                        // vip={vip}
                        totlaTickets={totlaTickets}
                        onContinue={() => setStep(2)}
                    // onContinue={Payment}
                    />}
                {step == 2 &&
                    <Step2
                        rut={rut}
                        fullName={fullName}
                        emailOrPhone={emailOrPhone}
                        onChangeFullName={e => setFullName(e.target.value)}
                        onChangeEmailOrPhone={e => setEmailOrPhone(e.target.value)}
                        onChnageRut={e => setRut(e.target.value)}
                        // isChecked={isChecked}
                        // setIsChecked={setIsChecked}
                        onContinue={() => setStep(3)}
                    />}
                {Boolean(myArr?.length) && <>
                    {ShowArray && <>
                        <ArrayOfSteps
                            step={step}
                            myArr={myArr}
                            handleGuestChange={handleGuestChange}
                            count={isChecked ? step - 2 : step - 1}
                            onContinue={() => setStep(step + 1)} />
                    </>}
                </>}

                {step == Number(myArr?.length) + startValue &&
                    <PaymentStep
                        type={type}
                        loading={loading}
                        pplCount={myArr.length}
                        isChecked={isChecked}
                        onContinue={(type) => HandleContinue(type)}
                    />}
            </div>
        </div>
    )
}

export default BuyTickets

function CounterWithTitle({ title, price = '20', value = 0, onSubtract, onAdd }) {
    return (
        <div className='my-3 flex justify-between items-center'>
            <div>
                <dev className={'text-sm font-interMedium'}>
                    {title}
                </dev>
                <div className={'text-[.75rem]'}>
                    $ {price} CLP
                </div>
            </div>
            <div className='flex justify-between items-center w-20 h-[4.5vh] border border-[black] rounded-full'>
                <button onClick={onSubtract}
                    disabled={value == 0}
                    className={`${value == 0 && 'text-[#D9D9D9]'} py-2 px-2`}>-</button>
                <div className={`${value == 0 && 'text-[#D9D9D9]'}`}>{value}</div>
                <button onClick={onAdd}
                    disabled={value == 200}
                    className={`${value == 200 && 'text-[#D9D9D9]'} py-2 px-2`}>+</button>
            </div>
        </div>
    )
}

function Step1({ setPreVenta1, preVenta1, setVIP, vip, totlaTickets, onContinue }) {

    return (
        <div className=''>
            <div className='font-interBold text-[1.25rem]'>
                Elige tus entradas
            </div>
            <CounterWithTitle
                title={'Pre Venta'}
                price='5.999'
                value={preVenta1}
                onAdd={() => setPreVenta1(preVenta1 + 1)}
                onSubtract={() => setPreVenta1(preVenta1 - 1)} />
            {/* <CounterWithTitle
                title={'VIP'}
                price='15.999'
                value={vip}
                onAdd={() => setVIP(vip + 1)}
                onSubtract={() => setVIP(vip - 1)} /> */}
            {/* <div className='italic text-sm mt-[6vh] w-[16rem]'> */}
            <div className='italic text-sm mt-[6vh] w-[16rem]'>
                {'En caso de duda escríbenos a contacto@mintapp.info'}
            </div>
            <div className='flex justify-center mt-[4vh]' >
                <BasicButton
                    disabled={totlaTickets <= 0}
                    className={'mt-[3vh]'}
                    onClick={onContinue}>
                    <div className='text-2xl'>{'Continuar'}</div>
                </BasicButton>
            </div>
        </div>
    )
}

function Step2({ onChangeFullName, fullName, rut, onChnageRut, onChangeEmailOrPhone, emailOrPhone, onContinue }) {
    const disabled = empty(fullName) || empty(rut) || empty(emailOrPhone)
    return (
        <div >
            <div className='text-lg font-interBold mb-1'>Datos del Comprador</div>
            <p className='text-xs italic text-[#1C1C1C] w-4/5 mb-4'>{'Las entradas se enviarán al correo electrónico del comprador.'}</p>
            {/* <NewCheckbox
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                small
                idCheckbox={'checkbox1'}
                label={<p className='ml-[-8px] mt-3 italic text-[.8rem]'>Estas entradas <strong>no</strong> son para mí</p>}
            /> */}
            <div className='mt-2' />
            <InputBordered placeholder='Nombre Completo *' value={fullName} onChange={onChangeFullName} />
            <InputBordered placeholder='RUT *' value={rut} onChange={onChnageRut} />
            <InputBordered placeholder='Correo Electrónico o Número de Teléfono *' value={emailOrPhone} onChange={onChangeEmailOrPhone} />
            <div className='flex justify-center' >
                <BasicButton
                    disabled={disabled}
                    className={'mt-[1vh]'}
                    onClick={onContinue}>
                    <div className='text-2xl'>Continuar</div>
                </BasicButton>
            </div>
        </div>
    )
}

function ArrayOfSteps({ handleGuestChange, onContinue, step, myArr, count }) {
    let index = step - 3
    const disabled = empty(myArr[index]?.name)

    return (
        <div >
            <div className='text-lg font-interBold mb-1'>Datos del Comprador</div>
            <p className='text-xs italic text-[#1C1C1C] w-4/5 mb-2'>{'Las entradas se enviarán al correo electrónico del comprador.'}</p>
            <div className='text-base font-interBold mt-5 mb-2'>{`Asistente ${count} *`}</div>
            <div className='mt-2' />
            <InputBordered
                placeholder='Nombre Completo *'
                value={myArr[index]?.name}
                onChange={(e) => handleGuestChange(index, 'name', e.target.value)} />
            <InputBordered
                placeholder='RUT' value={myArr[index]?.rut}
                onChange={(e) => handleGuestChange(index, 'rut', e.target.value)} />
            <InputBordered
                placeholder='Correo Electrónico o Número de Teléfono'
                value={myArr[index]?.contact_info}
                onChange={(e) => handleGuestChange(index, 'contact_info', e.target.value)} />
            <div className='flex justify-center' >
                <BasicButton
                    disabled={disabled}
                    className={'mt-[1vh]'}
                    onClick={onContinue}>
                    <div className='text-2xl'>Continuar</div>
                </BasicButton>
            </div>
        </div>
    )
}

function PaymentStep({ pplCount, isChecked, type, loading, onContinue }) {
    const Count = isChecked ? pplCount : pplCount + 1
    const TTL = Count * 5999
    const replaceDot = str => {
        return str?.replace(/\,/g, '.');
    }
    return (
        <div>
            <h2 className='text-xl font-interRegular font-bold'>Medio de Pago</h2>
            <div className='mt-[1vh]'>
                {/*TODO wait for backend to change text*/}
                <p className='w-full h-[4vh] rounded-md bg-[#D9D9D9] flex font-interMedium items-center text-xs pl-4 '>
                    {/* {`${option_description} $ ${price} CLP`} */}
                    {`${Count} entradas por $${replaceDot(TTL.toLocaleString())} CLP`}
                </p>
                {/* <Option
                    className='pb-[3vh] border-b-2'
                    title={'Pagar y registrar tarjeta '}
                    text={'¡Facilita tus próximos pagos!'}
                    loading={loading && type == 1}
                    // onClick={onCreditCard}
                    onClick={() => onContinue(1)}
                /> */}
                <Option
                    // loading={flowPayloading && ndLoading}
                    title={
                        <div>
                            Pagar <u>sin</u> registrar tarjeta
                        </div>
                    }
                    // text={'Vuelve a pagar cada vez'}
                    loading={loading && type == 1}
                // onClick={() => onContinue(1)}
                />
            </div>
            <div onClick={() => onContinue(1)}
                className='border flex items-center border-[#D9D9D9] py-2 justify-between rounded-md mt-[3vh]'>
                <div className='flex justify-between w-[80%]'>
                    <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                        <WebpayIcon />
                    </div>
                    <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                        <MachIcon />
                    </div>
                    <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                        <OnepayIcon />
                    </div>
                </div>
                <div className='flex flex-col self-center mr-2'>
                    <ArrowButton loading={loading && type == 1} onClick={() => onContinue(1)} />
                </div>
            </div>
        </div>
        //   {
        //     modalVisible && (
        //         <CardDataModal
        //             loading={isCardLoading}
        //             setName={setName}
        //             handleContinue={OnConntinueModal}
        //             handleClose={() => setModalVisible(false)}
        //         />
        //     )
        // }
    )
}

function Steps({ step, ShowArray, showPayment }) {
    return (
        <ul className='flex justify-between mt-6 mb-4 text-[0.6rem]'>
            <li className={`${step === 1 || step == 2
                || ShowArray ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                1. Entradas
            </li>
            <li className={`${showPayment ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                2. Medio de Pago
            </li>
            <li className={`text-black/[.3] text-[.62rem] font-interRegular`}>
                3. Acceso con QR
            </li>
        </ul>
    )
}

const Option = ({ title, text, onClick, loading, className }) => {
    return (
        <div className={`w-full flex mt-5 ${className}`}>
            <div className='grid grid-cols-2 w-full '>
                <div className='text-base font-interBold col-span-2 leading-6'>
                    {title}
                </div>
                {text && (
                    <p className='col-span-3 text-[.7rem] font-interRegular'>{text}</p>
                )}
            </div>
            {/* <div className='ml-2'>
                <ArrowButton loading={loading} onClick={onClick} />
            </div> */}
        </div>
    );
};