import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineMenu } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { MENU, ROOT } from '../utils/Routes';
import { s_is_auth } from '../reducer/SessionReducer';
import { BackIcon, MintIcon } from './Icons';

import './Headers.css';

export function Header({ to = -1, onBack, title, white }) {
  const navigate = useNavigate()

  const handleBack = () => {
    if (onBack) {
      onBack()
    } else {
      navigate(to)
    }
  }

  const navigateHome = () => {
    navigate(ROOT)
  }

  return (
    <div className='flex justify-between items-center p-6'>
      <div onClick={handleBack}>
        <BackIcon color={white ? 'white' : 'black'} size={34} />
      </div>
      <div className={`text-base ${white ? 'text-white' : 'text-black'}`}>{title}</div>
      <div className='cursor-pointer' onClick={navigateHome}>
        <MintIcon white={white && white} />
      </div>
    </div>
  );
}

export function HeaderMenu() {
  const is_auth = useSelector(s_is_auth);
  if (!is_auth) {
    return null;
  }

  return (
    <div className='header-menu'>
      <Link to={MENU}>
        <MdOutlineMenu size={32} color='black' />
      </Link>
    </div>
  );
}
