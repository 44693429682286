import { useNavigate } from "react-router-dom"
import { FormatMoney } from "../../../../utils"
import { Header } from "../../../../components/Headers"
import { ROOT } from "../../../../utils/Routes"
import { CenterSpinner } from "../../../../components/Spinner"
import { WebpayIcon } from "../../../../components/Icons"

import merch1 from '../../../../assets/tmp/image 26.png';

const data = {
  price: '9999',
  products: [{
    photo: merch1,
    qr: '',
    title: 'Buzo Gira 2023',
    code: 'CODIGO #32423/1'
  }]
}

export default function MerchVoucher() {
  const navigate = useNavigate()
  const loading = false


  return (
    <div className='h-full w-full bg-white overflow-hidden overflow-y-auto text-base font-interRegular'>
      <Header onBack={() => navigate(ROOT)} />
      {loading ? <CenterSpinner />
        :<div className='px-6'>

          <div className='text-[1.12rem] font-interSemiBold mb-5'>Comprobante de Compra</div>
          <div className='flex flex-row justify-between mb-8'>
            <TitleWithText
              title={'Fecha'}
              text={'17/08/2023'}
            />
            <TitleWithText
              title={'Total'}
              text={`$${FormatMoney(Math.trunc(data?.price))} CLP`}
            />
          </div>
          <TitleWithText className="mb-8" title={'Número de seguimiento'} text="#546456" />
          <TitleWithText title={'Dirección'} text="Ayacucho 616, Beccar, Santiago.546456 Código Postal: 1243112" />
          <div className='overflow-hidden'>
            {data.products?.map((item, idx) => (
                <div key={idx}>
                  <div className='bg-[#D9D9D9] h-[1px] my-[3vh]' />
                  <div>
                    <div className='font-interSemiBold mb-3'>Producto</div>
                    <img src={item.photo} className="w-[130px]" />
                    <div>{item.title}</div>
                    <div>{item.code}</div>
                  </div>
                </div>
            ))}
          </div>
          <div className='font-interSemiBold mt-10 mb-4'>Detalle del pago</div>    
          <div className="flex">
            <div className="rounded-full bg-[#D9D9D9] w-12 h-12 flex items-center justify-center">
              <WebpayIcon smallBreak="w-6"/>
            </div>
            <div className="ml-4">
              <div>Visa **** 2345</div>
              <div>Pago aprobado</div>
            </div>
          </div>
          <div className='mb-[6vh]' />
        </div>}
    </div>
  )
}

function TitleWithText({ title, text, className }) {
  return (
    <div className={className}>
      <div className='text-base font-interSemiBold mb-2'>{title}</div>
      <div className='text-base font-interRegular'>{text}</div>
    </div>
  )
}