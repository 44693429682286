import React from 'react'
import NewHeader from '../../../../components/NewHeader'
import { LOGIN, ROOT, STORE, loginPath, onlineShowsBuyPath, ticketsPath } from '../../../../utils/Routes'
import { IMAGES } from '../../../../assets'
import { CenterSpinner, SmallSpinner } from '../../../../components/Spinner'
import { BasicButton } from '../../../../components/NewButtons'
import { ProfileTitles } from '../../../../components'
import { useNavigate, useParams } from 'react-router-dom'
import { s_is_auth } from '../../../../reducer/SessionReducer'
import { useSelector } from 'react-redux'
import { useApi } from '../../../../network/hooks/useApi'
import { resources } from '../../../../network/resources'
import useHeight from '../../../../utils/height'

export const ProfileTickets = ({ online }) => {
    const { name } = useParams()
    const { height } = useHeight()
    const navigate = useNavigate();
    const is_auth = useSelector(s_is_auth);
    const loading = false

    const { data, error, loading: fetching } = useApi(resources['events']);

    const events = data.filter(v => online ? v.location === 'online' : v.location !== 'online')
    const event = events[0]
    const detail1 = online ? `${event?.start_time}` : `${event?.location}, ${event?.start_time} HS`

    const HandleClick = () => {
        try {
            const path = online ? onlineShowsBuyPath(name) : ticketsPath(name)
            if (is_auth) navigate(path)
            else navigate(LOGIN, { state: { from: 'tickets' } });
        } catch (error) { console.log('Reee', error) }
    }

    return (
        <div style={{ background: "linear-gradient(#000, #000)", height: height }} className='h-[100svh]'>
            {fetching ? <CenterSpinner />
                : <div className='h-[100svh] linear-gradient(#00000060, #000) 1px 5.295px / 100% 107.595% bg-cover bg-no-repeat bg-center'
                    style={{ backgroundImage: `url(${IMAGES.TICKETS})` }}>
                    <NewHeader back to={ROOT} />
                    <div style={{ height: height * .85 }}
                        className=' flex flex-col justify-end'>
                        {/* <div
                        // onClick={() => navigate(`${STORE}/scanner`)}
                        onClick={() => navigate('/store/success')}
                        className='text-sm curser bg-white ml-[1.5rem] mb-10 text-black w-[8rem] text-center py-[.15rem] rounded-full'>
                        VIEW TICKETS
                    </div> */}
                        <ProfileTitles
                            // className={'h-[100svh]'}
                            name={event?.name}
                            detail1={detail1}
                            detail2={event?.address}
                            online={online}
                        />
                        <div className='pt-[3vh] px-8 flex item-center justify-center'>
                            <BasicButton
                                // disabled={disabled || loading}
                                onClick={HandleClick}
                            >
                                <div className='text-[18px] font-interSemiBold'>
                                    {loading ? <SmallSpinner /> : 'Compra tu entrada'}
                                </div>
                            </BasicButton>
                        </div>
                        {/* <div className='text-white text-center mt-[1vh] font-interMedium text-[1rem]'>
                        Sólo disponible en esta plataforma
                    </div> */}
                        {/* <div className='text-[11px] bg-white text-black mx-[1.5rem] text-center py-[.3rem] rounded-full mt-3'>Desliza para escuchar la nueva canción de Shisosa</div> */}
                    </div>
                </div>}
        </div>
    )
}

export default ProfileTickets