import { useDispatch, useSelector } from "react-redux";
import { Separator } from "../../../../components";
import Counter from "../../../../components/Counter";
import { BasicButton } from "../../../../components/NewButtons";
import { addProduct, removeProduct, s_merch_products } from "../../../../reducer/MerchReducer";
import { FormatMoney } from "../../../../utils";


export default function Step1({ data, onContinue }) {
  const products = useSelector(s_merch_products)
  const total = products.reduce(reduceTotal, 0)

  return (
    <>
      <div className="flex-1">
        <div className="font-bold text-lg">Merchandising</div>
        <div className="py-6">
          {products.map((v, index) => (
            <Item key={index} product={v} />
          ))}
        </div>
        <Separator />
        <div className="text-center py-8">
          <div className="font-bold text-lg">Total: ${FormatMoney(total)}</div>
          <div className="text-xs">Costo de envío: $300 CLP</div>
        </div>
      </div>
      <div className="mt-4">
        <BasicButton className="w-full" onClick={onContinue}>
          Finalizar compra
        </BasicButton>
      </div>
    </>
  )
}

function Item({ product }) {
  const dispatch = useDispatch()
  const handleAdd = () => {
    dispatch(addProduct(product))
  }

  const handleRemove = () => {
    dispatch(removeProduct(product))
  }

  return (
    <div className="flex justify-between items-center py-2">
      <img src={product.picture} alt="thumb" className="object-contain h-[50px] w-[50px] mr-3" />
      <div className="flex-1">
        <div className="font-bold">{product.product_name}</div>
        <div className="text-xs">${FormatMoney(product.price)}</div>
      </div>
      <Counter value={product.quantity} onAdd={handleAdd} onSubtract={handleRemove}/>
    </div>
  )
}

function reduceTotal(t, v) {
  return t + (v.price * v.quantity)
}