import { useNavigate, useParams } from "react-router-dom";


import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import NewHeader from "../../components/NewHeader";
import { LOGIN, ROOT, previewPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import AudioPlayer from "../../components/Landing/AudioPlayer";
import LandingButton from "../../components/Landing/LandingButton";
import Descriptions from "../../components/Landing/Descriptions";
import ImageBackground from "../../components/Landing/ImageBackground";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { s_is_auth } from "../../reducer/SessionReducer";
import useHeight from "../../utils/height";


/**
 * Landing without login
 */
function ProfileLanding() {
  const { name } = useParams();
  const navigate = useNavigate()
  const { height } = useHeight()
  const is_auth = useSelector(s_is_auth)
  const { data, loading } = useApi(resources['singleCreator'].replace(':id', name));
  const audio = data?.audios && data?.audios[0]

  const handleButton = () => navigate(LOGIN, { state: { from: 'back' } })

  useEffect(() => {
    // if (is_auth && audio) {
    if (audio) {
      navigate(previewPath(name, audio?.slug_field))
    }
  }, [audio])

  if (loading) {
    return <CenterSpinner />
  }

  return (
    <div className="relative">
      <ImageBackground src={data?.photo} />
      <div style={{ height: height }}
        className="relative h-[100dvh] flex flex-col justify-between">
        <NewHeader back to={ROOT} />
        <div className="px-10 mb-[4vh] text-white">
          <Descriptions name={data?.user?.first_name} title={audio?.title} description={audio?.description} />
          <AudioPlayer startTime="00:00" endTime="3:00" />
          <div className="mt-6">
            <LandingButton label={'¡Quiero escucharla!'} onClick={handleButton} />
          </div>
          <div className='text-center mt-[2vh] font-bold'>
            Sólo disponible en esta plataforma
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileLanding;
