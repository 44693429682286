import { Link, useNavigate, useParams } from "react-router-dom";

import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { ROOT, landingVideoPlayerPath, paymentSelectionPath, playerPath, videoMonthlyPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import LandingButton from "../../components/Landing/LandingButton";
import Descriptions from "../../components/Landing/Descriptions";
import { Children, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import VideoBackground from "../../components/Landing/VideoBackground";
import { BackIcon, FlipHorizontal, MenuDotsIcon } from "../../components/Icons";
import { setCreatorId, setPaymentOption } from "../../reducer/PaymentReducer";
import { Icons } from "../../assets";
import useHeight from "../../utils/height";
import { floatToTime } from "../../utils";
import OrientationDetector from "../../utils/getOrientation";


function VideoPreview() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { height } = useHeight()
  const { name, slug } = useParams();
  const [show, setShow] = useState(false)
  const [isVertical, setVertical] = useState(true)
  const { orientation } = OrientationDetector()

  const { data, loading } = useApi(resources['singleCreator'].replace(':id', name));
  const {
    asyncFetch: fetchCheckout
  } = useApi(resources['checkout'].replace(':slug', slug), { auto: false });
  const { data: dataList } = useApi(resources['searchBySlug'].replace(':slug', slug));
  const {
    data: data_subscription
  } = useApi(resources['subscriptions'].replace(':slug', slug));

  // const video = data?.videos && data?.videos[1]
  const video = dataList?.data
  // console.log(video)
  const routeSelection = paymentSelectionPath(name, slug);
  const profile = playerPath(name, slug)

  const handleProgress = (e) => {
    //setCurrentTime(e.target.currentTime)
    if (e.target.currentTime > 15.0) {
      setShow(true)
    }
  }

  //const handleBuy = () => navigate(landingVideoPlayerPath(name))
  const handleBuy = async () => {
    const res = await fetchCheckout()
    // console.log('[fetchCheckout]', res)

    if (!res) {
      return
    }

    if (res.next_step !== 'checkout') {
      window.location.reload();
    } else {
      const { price, purchase_code, name: description } = res.options[0];

      dispatch(setCreatorId({
        creatorId: data?.pk,
        creatorName: name,
        slugField: slug,
        creator_photo: data?.photo,
        inner_photo: data.inner_photo,
        content_title: video?.title,
        content_description: video?.description,
      }));
      dispatch(setPaymentOption({
        option: 1,
        purchaseCode: purchase_code,
        price: price,
        option_description: description,
      }));

      navigate(routeSelection);
    }
  }

  useEffect(() => {
    // console.log('[subs]', data_subscription)
    if (data_subscription.subscription_type === 'monthly') {
      navigate(videoMonthlyPath(name, slug))
    } else if (data_subscription.subscription_type === 'ppv') {
      navigate(landingVideoPlayerPath(name, slug))
    }
    else if (data_subscription.subscription_type === 'none' && data_subscription.expired_subscriptions) {
      navigate(landingVideoPlayerPath(name, slug))
    }
  }, [data_subscription])

  const Horizontal = () => {
    return (
      <div className="absolute top-0 left-0 overflow-hidden w-[100dvh] h-[100dvw]"
        style={{ transform: 'rotate(90deg) translateY(-100%)', transformOrigin: 'top left' }}>
        <div className="relative h-[100dvw] flex flex-col justify-between z-10">
          <div>
            <Header onFlip={() => setVertical(!isVertical)} onClick={() => { setVertical(true) }} />
            <div className="ml-10 w-[40vh]">
              <LandingButton label="¡Quiero comprarla!" onClick={handleBuy} />
              <div className="text-white font-bold my-2 text-center">Sólo disponible en esta plataforma</div>
              <div className="border-b-2 w-full opacity-20" />
            </div>
          </div>
          <div className="px-10 text-white flex justify-between items-center">
            <Descriptions name={data?.user?.first_name} title={video?.title} description={video?.description} />
          </div>
        </div>
      </div>
    )
  }

  const Vertical = () => {
    return (
      <div className="absolute top-0 left-0 overflow-hidden h-[100dvh] w-full" >
        <div style={{ height: height }} className="relative flex flex-col justify-between">
          <div>
            <Header onFlip={() => setVertical(!isVertical)} onClick={() => { navigate(profile) }} />
          </div>
          <div>
            <div className="px-10 text-white flex mb-6 justify-between items-center">
              <Descriptions name={data?.user?.first_name} title={video?.title} description={video?.description} />
            </div>
            <div className="ml-10 w-[40vh]">
              <LandingButton label="¡Quiero comprarla!" onClick={handleBuy} />
              <div className="text-white font-bold my-2 mb-8 text-center">Sólo disponible en esta plataforma</div>
              {/* <div className="border-b-2 w-full opacity-20" /> */}
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (loading) {
    return <CenterSpinner />
  }

  return (
    <div className="relative">
      <VideoBackground onProgress={handleProgress} src={video?.file_short_version} />
      {isVertical && <Vertical />}
      {!isVertical && <Horizontal />}
    </div>
  );
}

const Header = ({ onClick, onFlip }) => (
  <div style={{ zIndex: 10000 }} className="w-full p-6 flex justify-between">
    <div onClick={onClick}>
      <BackIcon />
    </div>
    <button onClick={onFlip}>
      <FlipHorizontal />
    </button>
  </div>
)

function ProgressBar({ value, max }) {
  const progress = ((value / max) * 100).toFixed(1)
  //TODO click on the bar
  return (
    <div>
      <div className="relative w-full h-4 flex items-center" onClick={e => console.log(e.target.getBoundingClientRect(), e.clientX)}>
        <div className="w-full h-1 bg-[#dddddd] rounded" />
        <div className="h-3 w-3 rounded-full bg-white absolute" style={{ left: `${progress}%` }} />
      </div>
      <div className="flex justify-between text-xs">
        <div>{floatToTime(value)}</div>
        <div>{floatToTime(max)}</div>
      </div>
    </div>
  )
}

export default VideoPreview
