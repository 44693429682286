import React from 'react';
import { Header } from '../../../components/Headers';
import { BasicButton } from '../../../components/NewButtons';
import { useNavigate } from 'react-router-dom';
import { ROOT } from '../../../utils/Routes';

function UploadSuccess() {
  const navigate = useNavigate()

  const handleContinue = () => navigate(ROOT)

  return (
    <div className='bg-white h-screen overflow-auto lg:h-[100%]'>
      <Header title={''}
      />
      <div className='pl-6 pr-6 pt-4'>
        <div className='text-2xl font-bold font-interRegular mb-2'>
          ¡Muchas gracias!
        </div>
        <div className='mt-14 flex justify-between h-[26rem] lg:h-[27rem] flex-col'>
          <div>
            <p className='text-base mb-10'>
              Vamos a revisar el material que subiste. Te contactaremos en un máximo de 72hs.
            </p>
            <p className='text-base'>En caso de dudas o consultas escríbenos a contacto@mintapp.info</p>
          </div>
          <BasicButton className='w-full text-base h-12' onClick={handleContinue}>
            Continuar
          </BasicButton>
        </div>
      </div>
    </div>
  );
}

export default UploadSuccess;
