import jwt_decode from 'jwt-decode';
import moment from 'moment';
import 'moment/locale/es';
import config from '../config';

export function str_not_empty(s) {
  return s !== undefined && s !== null && typeof s === 'string' && s.length > 0;
}

export function empty(s) {
  return !str_not_empty(s)
}

export function int(v) {
  return parseInt(v, 10);
}

export function audio_time(duration) {
  let seconds = Math.floor(duration - Math.floor(duration / 60) * 60);
  let minutes = Math.floor(duration / 60);
  return (
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds
  );
}

export function parseJwt(token) {
  const data = jwt_decode(token);
  //console.log('[parseJwt]', data);
  return data;
}

export function name_from_uri(uri) {
  return uri.split('/').pop();
}

export function limitText(text, number) {
  if (text.length <= 18) {
    return text;
  } else {
    return text.slice(0, number ?? 18) + '...';
  }
}

export function money(value) {
  return `${value} CLP`;
}

export function SplitArrayIntoRandomSizeSubarrays(arr, minSize, maxSize) {
  let result = [];
  let i = 0;
  while (i < arr?.length) {
    let subarraySize = Math.floor(
      Math.random() * (maxSize - minSize + 1) + minSize
    );
    let subarray = arr?.slice(i, i + subarraySize);
    let data = {
      items: subarray,
      id: i,
    };
    result?.push(data);
    i += subarraySize;
  }
  return result;
}

export const formatDateTime = (date, time) => {
  const dateTimeString = `${date}T${time}:00.000Z`;
  const formattedDateTime = new Date(dateTimeString).toISOString();

  return formattedDateTime;
};

export const formatDate = (datetime, typeDate) => {
  const momentObj = moment(datetime);
  momentObj.locale('es');

  switch (typeDate) {
    case 'day':
      return momentObj.format('D [de] MMMM');
    case 'hour':
      return momentObj.format('HH:mm');
    case 'date':
      return momentObj.format('D MMMM YYYY[,] HH:mm [PM]');
    default:
      return momentObj.format('D [de] MMMM HH:mm');
  }
};

/**
 * 
 * @param {*File from an Input field} file 
 * @returns Promise with the width and height
 */
export function calcImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const width = img.width;
      const height = img.height;
      resolve({ width, height });
    };

    img.onerror = function () {
      reject('No se pudo cargar la imagen');
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = function () {
      img.src = reader.result;
    };
  });
}

/**
 * 
 * @param {value in bytes} value 
 * @returns value in kilobytes
 */
export function kb(value) {
  return Math.floor(value / 1024)
}

export function isIos() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent)
}

export function isInstagramUserAgent() {
  var ua = navigator.userAgent;
  return ua.indexOf('Instagram') > -1
}

export function isChrome() {
  console.log('useragent', navigator.userAgent)
  return /Chrome/.test(navigator.userAgent)
}

export function isSafari() {
  const userAgent = navigator.userAgent
  return userAgent.toLowerCase().indexOf('safari/') > -1 && !/Chrome/.test(userAgent)
}

/**
 * request fullscreen for the element depends on the browser
 * @param {DOMElement} element 
 */
export function requestFullScreen(element) {
  var requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

  if (requestMethod) { // Native full screen.
    requestMethod.call(element);
  } else if (typeof window.ActiveXObject !== "undefined") { // Older IE.
    var wscript = new window.ActiveXObject("WScript.Shell");
    if (wscript !== null) {
      wscript.SendKeys("{F11}");
    }
  }
}

export function FormatMoney(num) {
  let mun2 = Math.trunc(num)
  var str = mun2.toString().split('.');
  if (str[0].length >= 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1.');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}

export function floatToTime(floatValue) {
  const totalSeconds = Math.round(floatValue);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}


export const HandleGotopp = () => {
  openAppOrPlayStore()
  return
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const appInstalled = document.cookie.split(';').some((item) => item.trim().startsWith('appInstalled='));
  const deepLink = 'mintapp://';
  let storeLink;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    // iOS - App Store Link
    storeLink = 'https://apps.apple.com/cl/app/mint/id6467195856';
  } else if (/android/i.test(userAgent)) {
    // Android - Google Play Store Link
    storeLink = 'https://play.google.com/store/apps/details?id=info.mintapp';
  }

  // Attempt to redirect to the app using deep link
  window.location.href = deepLink;
  // If the app is not installed, redirect to the store after a timeout
  const redirectTimeout = setTimeout(() => {
    window.location.href = storeLink;
  }, 500); // Adjust timeout as needed
  redirectTimeout()
}

const openAppOrPlayStore = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const appScheme = 'mintapp://';
  let storeLink;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    storeLink = 'https://apps.apple.com/cl/app/mint/id6467195856';
  } else if (/android/i.test(userAgent)) {
    storeLink = 'https://play.google.com/store/apps/details?id=info.mintapp';
  } else {
    storeLink = 'https://play.google.com/store/apps/details?id=info.mintapp';
  }

  // Attempt to open the app using a custom URL scheme
  window.location.href = appScheme;

  // Check if the app is opened within 500ms, otherwise fallback to the store link
  setTimeout(() => {
    if (document.hasFocus()) {
      window.location.href = storeLink;
    }
  }, 500);
};


export function uniqueID() {
  // this.setState({indicator: true});
  function chr4() {
    return Math.random()
      .toString(16)
      .slice(-4);
  }
  return (
    chr4() +
    chr4() +
    '-' +
    chr4() +
    '-' +
    chr4() +
    '-' +
    chr4() +
    '-' +
    chr4() +
    chr4() +
    chr4()
  );
}

export function generateSecureRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  const randomValues = new Uint8Array(length);
  window.crypto.getRandomValues(randomValues);

  for (let i = 0; i < length; i++) {
    result += characters.charAt(randomValues[i] % charactersLength);
  }
  return result;
}

export const SmartUrl = (url) => {
  try {
    const parts = url?.split("/o/");
    const bucketAndPath = parts[1]?.split("?")[0];
    const [encodedPath] = bucketAndPath?.split("?alt=media");
    let decoded = decodeURIComponent(encodedPath);
    return `${config.CDN}${decoded}`
  } catch (error) {
    return url
  }
}