import { useState } from "react";
import { BagBlackIcon, BagIcon, PlayBlackIcon, PlayIcon, PlayInactive } from "../../components/Icons";
import NewHeader from "../../components/NewHeader";
import { Separator, VSeparator } from "../../components";
import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { CenterSpinner } from "../../components/Spinner";
import { NewsCard } from "../../components/Cards";

import tmp1 from '../../assets/onlineticket-show.png'
import tmp2 from '../../assets/tmp/show-online-banner.png'
import tmp3 from '../../assets/tmp/image 26.png'
import { ROOT, purchasedAudioPath } from "../../utils/Routes";
import useHeight from "../../utils/height";

const dummy = [{
  pk: '1',
  img: tmp1,
  label: 'SHOW EN LÍNEA',
  title: 'Jueves 25 de Agosto, 19HS',
  name: 'Shisosa',
  to: '/shisosa'
}, {
  pk: '2',
  img: tmp2,
  label: 'SHOW EN VIVO',
  title: 'Jueves 25 de Agosto, 19 Hrs Pionono 270, Recoleta',
  name: 'Shisosa',
  to: '/shisosa'
}
  , {
  pk: '3',
  right: tmp3,
  label: 'MERCHANDISING',
  title: 'Buzo Gira 2023',
  name: 'Shisosa',
  to: '/shisosa'
}
  , {
  pk: '4',
  right: tmp3,
  label: 'MERCHANDISING',
  title: 'Buzo Gira 2023',
  name: 'Shisosa',
  to: '/shisosa'
}
]


export default function MisCompras() {
  const [menu, setMenu] = useState(0)
  const { height } = useHeight()

  return (
    <div style={{ height: height }}
      className="bg-white h-screen border">
      <div >
        <NewHeader dark back to={ROOT} />
        <div className="px-8 pt-6">
          <div className="flex justify-around">
            <div className="p-4" onClick={() => setMenu(0)}>{menu === 0 ? <PlayBlackIcon /> : <PlayInactive />}</div>
            <VSeparator />
            <div className="p-4" onClick={() => setMenu(1)}>{menu === 1 ? <BagBlackIcon /> : <BagIcon />}</div>
          </div>
          <Separator />
        </div>
      </div>
      <div className="mt-4 px-8">
        {menu === 0 && <MyContent />}
        {menu === 1 && <MyProducts />}
      </div>
    </div>
  )
}

const MyContent = () => {
  const { data, error, loading } = useApi(resources['userPurshased']);
  const { height } = useHeight()

  return (
    <div style={{ height: height * .75 }}
      className="overflow-y-auto overflow-x-hidden">
      <div className="font-bold text-[22px]">Mi contenido</div>
      <div className="mt-4 overflow-scroll">
        {loading && <CenterSpinner />}
        {!loading && data?.data?.map((item, index) => <NewsCard
          key={index}
          name={`${item.user?.first_name ?? ''} ${item.user?.last_name ?? ''}`}
          bgImage={item.homepage_photo}
          to={purchasedAudioPath(item.slug_field, item.media_slug)}
          title={item.media_title}
          description={item.media_description}
        />)}
      </div>
    </div>
  )
}

const MyProducts = () => {
  const { height } = useHeight()
  return (
    <div style={{ height: height * .75 }}
      className="overflow-y-auto overflow-x-hiddenl">
      <div className="font-bold text-[22px]">Mis productos</div>
      <div className="mt-4">
        {dummy.map((item) => (
          <NewsCard
            key={item.pk}
            name={item.name}
            bgImage={item.img}
            to={item.to}
            title={item.title}
            label={item.label}
            right={item.right}
          />
        ))}
      </div>
    </div>
  )
}