export const Previews = [
    {
        id: '1',
        userName: 'Bryartz',
        file: 'https://da9o728pirar3.cloudfront.net/media/preventa/audios/preview_file/2023/08/te-busco-donde-sea-final-1_kteT3vq5.mp3',
        profilePhoto: 'https://da9o728pirar3.cloudfront.net/media/creator/ID%20%7C%203%2C%20Email%20%7C%20bryartz%40mintapp.info/Foto_Bryartz.png',
        type: 'audio',
        description: '',
        title: 'Te busco donde sea'
    },
    {
        id: '00',
        userName: 'Shisosaloud & Amigos',
        file: 'https://da9o728pirar3.cloudfront.net/media/preventa/videos/file/2023/08/shishosa_event.mp4',
        type: 'event',
        name: "Shisosaloud & Amigos",
        organizer: "Shisosa",
        start_time: "21:30:00",
        location: "Discoteca Wau",
        address: "Pionono 270, Recoleta",
    },
    {
        id: '2',
        userName: 'Andre Alpuritano',
        file: 'https://da9o728pirar3.cloudfront.net/media/preventa/audios/preview_file/2023/08/cristal-andre-alpuritano-ft-harry-nach-prod-savage-_8kg6Xzt.mp3',
        profilePhoto: 'https://da9o728pirar3.cloudfront.net/media/creator/ID%20%7C%202%2C%20Email%20%7C%20alpuritano%40mintapp.info/alpuritano.jpg',
        type: 'audio',
        description: 'FT Harry Nach',
        title: 'Cristal'
    },
    {
        id: '0',
        userName: 'Shisosa',
        file: 'https://da9o728pirar3.cloudfront.net/media/preventa/videos/file/2023/08/shishosa_preview.mp4',
        profilePhoto: 'https://da9o728pirar3.cloudfront.net/media/creator/ID%20%7C%201%2C%20Email%20%7C%20patricio.lopez%40andesblockchain.com/shisosa.jpg',
        type: 'video',
        description: 'DEL KILO',
        title: 'FT Drago'
    },
    {
        id: '5',
        userName: 'Andre Alpuritano',
        file: 'https://da9o728pirar3.cloudfront.net/media/promocional/video/alpuritano@mintapp.info/alpuritano-preview-1.mp4',
        profilePhoto: 'https://da9o728pirar3.cloudfront.net/media/creator/ID%20%7C%202%2C%20Email%20%7C%20alpuritano%40mintapp.info/alpuritano.jpg',
        type: 'video',
        description: 'FT Harry Nach',
        title: 'Cristal'
    },
    {
        id: '3',
        userName: 'Shisosa',
        file: 'https://da9o728pirar3.cloudfront.net/media/preventa/audios/preview_file/2023/07/del-kilo-master_preview_2.mp3',
        profilePhoto: 'https://da9o728pirar3.cloudfront.net/media/creator/ID%20%7C%201%2C%20Email%20%7C%20patricio.lopez%40andesblockchain.com/shisosa.jpg',
        type: 'audio',
        description: 'DEL KILO',
        title: 'FT Drago'
    },
    {
        id: '4',
        userName: 'Bryartz',
        file: 'https://da9o728pirar3.cloudfront.net/media/promocional/video/shisosa@mintapp.info/shisosa-preview-1.mp4',
        type: 'video',
        description: '',
        title: 'Te busco donde sea'
    },
]