import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useApi } from '../../../../network/hooks/useApi';
import { resources } from '../../../../network/resources';
import { empty } from '../../../../utils';
import { s_session_token, s_user_info } from '../../../../reducer/SessionReducer';
import { Header } from '../../../../components/Headers';

import { InputSimple, InputUnderline, NewCheckbox, UploadFile } from '../../../../components/NewInputs';
import { BasicButton } from '../../../../components/NewButtons';

import './Audios.css';
import { PaymentCard, Separator } from '../../../../components';
import { Icons } from '../../../../assets';
import { CheckCircle } from '../../../../components/Icons';
import config from '../../../../config';
import useHeight from '../../../../utils/height';

const Prices = [
    { idx: '1', value: '', price: '- ver opciones -' },
    { idx: '2', value: 'no', price: 'No habilitar' },
    { idx: '3', value: '499', price: '$499 CLP' },
    { idx: '4', value: '999', price: '$999 CLP' },
    { idx: '5', value: '1499', price: '$1499 CLP' },
]


function Audios2() {
    const navigate = useNavigate();
    const { height } = useHeight()
    const [busy, setBusy] = useState(false)
    const [step, setStep] = useState(1)
    const [artistic, setArtistic] = useState('')
    const [songName, setSongName] = useState('')
    const [price, setPrice] = useState('')
    const [monthlyPrice, setMonthlyPrice] = useState('')
    const [nameAccount, setNameAccount] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bankName, setBankName] = useState('')
    const [accountType, setAccountType] = useState('')
    const [distributor, setDistributor] = useState('')
    const [comment, setComment] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [paypalEmail, setPaypalEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [fullName, setFullName] = useState('')
    const [wnEmail, setWNEmail] = useState('')
    const [colaborators, setColaborators] = useState('')
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [prices, setPrices] = useState(Prices);

    useEffect(() => {
        console.log(price)
        let ind = Prices.findIndex(i => i.value == price)
        let sliced = Prices.slice(0, 1).concat(price == '1499' ? Prices.slice(ind) : price == 'no' ? Prices.slice(ind) : Prices.slice(ind + 1));
        setPrices(sliced)
        setMonthlyPrice('')
    }, [price])

    const { email } = useSelector(s_user_info);

    const { asyncFetch, error, loading } = useApi(resources['creatorRequest'], {
        auto: false,
        method: 'POST',
        upload: true,
    });

    const accessToken = useSelector(s_session_token);

    async function fetchUserInfo() {
        const reply = await fetch(`${config.API_URL}/users/requests/creator`, {
            method: 'GET',
            headers: {
                // 'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        });
        return await reply.json();
    }

    useEffect(() => {
        // Get()
    }, [])
    const Get = async () => {
        console.log('inside:')
        let rs = await fetchUserInfo()
        console.log("Res:::::::", rs)
    }

    const toggleBusy = () => setBusy(!busy)

    const handleFileAudio = (event) => {
        const file = event.target.files[0];
        setSelectedAudio(file);
    };

    const handleFilePhoto = (event) => {
        const file = event.target.files[0];
        setSelectedPhoto(file);
    };

    const handleUpload = async () => {
        try {
            // ${comment};
            toggleBusy()
            const bankInfoTB = `${bankName};${nameAccount};${accountType};${bankAccount};${accountEmail};${phone}`
            const bankiInfoPaypal = `${paypalEmail}`
            const bankiInfoWunion = `${fullName};${wnEmail};${country};${city};${bankName};`
            const moreData = `${songName};${colaborators};${distributor};${price};${monthlyPrice}`
            const bankInformation = `${nameAccount};${bankAccount};${bankName};${accountType}`
            const form = new FormData();
            form.append('artistic_name', artistic);
            form.append('email', email);
            form.append('phone', '9');
            form.append('bank_information', selectedCard == 0 ? bankInfoTB : selectedCard == 1 ? bankiInfoPaypal : selectedCard == 2 ? bankiInfoWunion : '');
            form.append('song_file', selectedAudio);
            form.append('photo_file', selectedPhoto);
            form.append('more_data', moreData);

            console.log('[handleUpload] ', moreData);
            console.log('[handleUpload] ', bankInformation);
            const reply = await asyncFetch(form);
            console.log('[handleUpload]', reply);
            if (reply) {
                navigate('/crear/uploadsuccess')
            }
        } catch (e) {
            console.error('[handleUpload]', e)
        } finally {
            toggleBusy()
        }

    };

    const handleBack = () => {
        if (step === 1) {
            navigate(-1)
        } else {
            setStep(Math.max(1, step - 1))
        }
    }
    const handlePressCard = (idx) => {
        setSelectedCard(idx)
        setBankName('')
    }
    return (
        <div className='h-full bg-white'>
            <div style={{ height: height }} className=' bg-white overflow-auto flex flex-col'>
                <Header title={''} onBack={handleBack} />
                <div className='px-8 pt-1 flex-1 bg-white flex flex-col'>
                    <div className='text-2xl font-bold font-interRegular mb-2'>{step === 5 ? "¡Último paso!" : "Canción"}</div>
                    <p className='text-base'>{step !== 5 && "Debe ser material que únicamente se encuentre en esta plataforma."}</p>
                    {step !== 5 && <Steps step={step} />}
                    {step === 1 && <StepOne
                        artistic={artistic}
                        onArtisticChange={e => setArtistic(e.target.value)}
                        songName={songName}
                        onSongNameChange={e => setSongName(e.target.value)}
                        colaborators={colaborators}
                        onColaboratorsChange={e => setColaborators(e.target.value)}
                        distributor={distributor}
                        onDistributorChange={e => setDistributor(e.target.value)}
                        onContinue={() => setStep(2)}
                    />}
                    {step === 2 && <StepTwo
                        onPhotoChange={handleFilePhoto}
                        onAudioChange={handleFileAudio}
                        songName={songName}
                        onSongNameChange={e => setSongName(e.target.value)}
                        onContinue={() => setStep(3)}
                        selectedPhoto={selectedPhoto}
                        selectedAudio={selectedAudio}
                    // disabled={}
                    />}
                    {step === 3 && <StepTree
                        nameAccount={nameAccount}
                        onNameAccountChange={e => setNameAccount(e.target.value)}
                        bankAccount={bankAccount}
                        onBankAccountChange={e => setBankAccount(e.target.value)}
                        bankName={bankName}
                        onBankNameChange={e => setBankName(e.target.value)}
                        accountType={accountType}
                        onAccountTypeChange={e => setAccountType(e.target.value)}
                        onContinue={() => setStep(4)}
                        values={Prices}
                        monthlyValues={prices}
                        price={price}
                        onPriceChange={e => setPrice(e.target.value)}
                        monthly={monthlyPrice}
                        onMonthlyChange={e => setMonthlyPrice(e.target.value)}
                    />}
                    {step === 4 && <StepFour
                        nameAccount={nameAccount}
                        onNameAccountChange={e => setNameAccount(e.target.value)}
                        bankAccount={bankAccount}
                        onBankAccountChange={e => setBankAccount(e.target.value)}
                        bankName={bankName}
                        onBankNameChange={e => setBankName(e.target.value)}
                        accountType={accountType}
                        onAccountTypeChange={e => setAccountType(e.target.value)}
                        onContinue={() => setStep(5)}
                        handlePressCard={handlePressCard}
                        selectedCard={selectedCard}
                        onAccountEmailChange={e => setAccountEmail(e.target.value)}
                        accountEmail={accountEmail}
                        onPaypalEmailChange={e => setPaypalEmail(e.target.value)}
                        paypalEmail={paypalEmail}
                        setPhone={e => setPhone(e.target.value)}
                        phone={phone}
                        onChangeFullName={e => setFullName(e.target.value)}
                        fullName={fullName}
                        onWNEmailChange={e => setWNEmail(e.target.value)}
                        wnEmail={wnEmail}
                        onCityChange={e => setCity(e.target.value)}
                        city={city}
                        onCountryChange={e => setCountry(e.target.value)}
                        country={country}
                    // loading={busy}
                    />}
                    {step === 5 && <StepFive
                        checkOne={isChecked}
                        setCheckOne={(setIsChecked)}
                        checkTwo={isChecked1}
                        setCheckTwo={setIsChecked1}
                        checkThree={isChecked2}
                        setCheckThree={setIsChecked2}
                        onContinue={handleUpload}
                        checkFour={isChecked3}
                        setCheckFour={setIsChecked3}
                        loading={busy}
                    />}
                </div>
            </div>
        </div>
    );
}

function StepOne({
    artistic, onArtisticChange, colaborators, onColaboratorsChange, distributor, onDistributorChange,
    onContinue
}) {

    const disabled = empty(artistic)
    return (
        <div className='flex-1 flex flex-col justify-between'>
            <div>
                <div className='font-bold font-interRegular'>
                    Nombre Artístico *
                </div>
                <InputUnderline
                    // id="inputID"
                    placeholder={'Nombre completo'} value={artistic} onChange={onArtisticChange} />
                <div className='font-bold font-interRegular mt-4'>Colaboradores</div>
                <InputUnderline placeholder='Opcional' value={colaborators} onChange={onColaboratorsChange} />
                <div className='font-bold font-interRegular mt-4 text-base'>
                    Nombre de su Distribuidor
                </div>
                <InputUnderline placeholder='Opcional' value={distributor} onChange={onDistributorChange} />
            </div>
            <div className='mt-7 mb-8'>
                <BasicButton
                    style={{ backgroundColor: disabled ? '#D9D9D9' : '#372D90' }}
                    className='w-full text-base h-12' onClick={onContinue} disabled={disabled}>
                    Continuar
                </BasicButton>
            </div>
        </div>
    )
}

function StepTwo({ onPhotoChange, onAudioChange, onContinue, selectedPhoto, selectedAudio,
    onSongNameChange, songName }) {
    const disabled = !selectedAudio || !selectedPhoto || empty(songName)
    return (
        <div className='flex-1 flex flex-col justify-between'>
            <div>
                <div className='font-bold font-interRegular'>
                    Nombre de la Canción *
                </div>
                <InputUnderline
                    // id="inputID"
                    placeholder={'Nombre completo'} value={songName} onChange={onSongNameChange} />

                <div className='font-bold font-interRegular mt-6'>Audio *</div>
                <div className='flex flex-row justify-between'>
                    <div className='text-gray-400 my-[2svh]'>
                        <div className='text-sm'>Debe ser MP3</div>
                    </div>
                    {selectedAudio !== null && <CheckCircle />}
                </div>
                <UploadFile className='w-full h-[6svh]' handleFileChange={onAudioChange} audio label="Subir Audio" />
                <Separator className="mt-6" />
                <div className='font-bold font-interRegular mt-2'>Portada *</div>
                <div className='flex flex-row justify-between'>
                    <div className='text-gray-400 my-4'>
                        <div className='text-sm'>Peso: 50 KB</div>
                    </div>
                    {selectedPhoto !== null && <CheckCircle />}
                </div>
                <UploadFile className='w-full h-[6svh]' handleFileChange={onPhotoChange} img label="Subir imágenes" />
            </div>
            <div className='mt-7 mb-8'>
                <BasicButton className='w-full text-base h-[6svh]' onClick={onContinue} disabled={disabled}>
                    Continuar
                </BasicButton>
            </div>
        </div>
    )
}

function StepTree({ values, monthlyValues, price, onPriceChange, monthly, onMonthlyChange, onContinue }) {
    const disabled = empty(price) || empty(monthly)
    function Dropdown({ data, value, onChange }) {
        return (
            <select className='bg-[#D9D9D9] border-0 rounded-xl	px-4' value={value} onChange={onChange}>
                {data.map((item) => {
                    return <option value={item.value}>{item.price}</option>
                })}
            </select>
        )
    }

    return (
        <div className='flex-1 flex flex-col justify-between'>
            <div>
                <div className='font-bold font-interRegular'>Precio & Planes *</div>
                <div className='text-gray-400 text-xs mt-2'><i>Elige cual de estas opciones quieres habilitar</i></div>
                <div className='text-sm'>
                    <div className='flex justify-between mt-6'>
                        <div className='text-gray-400'>4 reproducciones</div>
                        <Dropdown data={values} value={price} onChange={onPriceChange} />
                    </div>
                    <div className='flex justify-between mt-6'>
                        <div className='text-gray-400'>Desbloquea un mes</div>
                        <Dropdown data={monthlyValues} value={monthly} onChange={onMonthlyChange} />
                    </div>
                </div>
            </div>
            <div className='mt-7 mb-8'>
                <BasicButton className='w-full text-base h-12' onClick={onContinue} disabled={disabled}>
                    Continuar
                </BasicButton>
            </div>
        </div>
    )
}

const paymentCards = [
    { id: '1', icon: Icons.tb },
    { id: '2', icon: Icons.paypal },
    { id: '3', icon: Icons.wunion },
]

function StepFour({ onNameAccountChange, onBankAccountChange, onAccountTypeChange, nameAccount,
    onAccountEmailChange, accountEmail, setPhone, phone,
    onPaypalEmailChange, paypalEmail,
    accountType, bankAccount, bankName, onBankNameChange,
    onChangeFullName, fullName, onWNEmailChange, wnEmail, onCityChange, city, onCountryChange, country,
    handlePressCard, onContinue, selectedCard }) {
    const disabled = empty(bankName) || empty(nameAccount) || empty(accountType) || empty(bankAccount) || empty(accountEmail) || empty(phone)
    const disabled1 = empty(paypalEmail)
    const disabled2 = empty(fullName) || empty(wnEmail) || empty(country) || empty(city) || empty(bankName)

    return (
        <div>
            <div className='font-bold font-interRegular text-base'>
                Datos bancarios y de contacto *
            </div>
            <div className='text-xs text-[#BFBFBF] mt-2 mb-4'>
                Datos del responsable de repartir las ganancias de acuerdo a los contratos vigentes.
            </div>
            <div className='flex justify-between mb-4' >
                {paymentCards.map((item, idx) => {
                    return (
                        <PaymentCard onClick={() => handlePressCard(idx)} selected={selectedCard == idx} icon={item.icon} />
                    )
                })}
            </div>
            {selectedCard == 0 &&
                <>
                    <InputSimple placeholder='Nombre de la cuenta bancaria' value={nameAccount} onChange={onNameAccountChange} />
                    <InputSimple placeholder='N° cuenta bancaria' value={bankAccount} onChange={onBankAccountChange} />
                    <InputSimple placeholder='Banco' value={bankName} onChange={onBankNameChange} />
                    <InputSimple placeholder='Tipo de Cuenta' value={accountType} onChange={onAccountTypeChange} />
                    <InputSimple placeholder='Correo electrónico' value={accountEmail} onChange={onAccountEmailChange} />
                    <InputUnderline placeholder='Número telefónico' value={phone} onChange={setPhone} />
                    <div className='mt-7 mb-8'>
                        <BasicButton
                            className='w-full text-base h-12' onClick={onContinue} disabled={disabled}>
                            Continuar
                        </BasicButton>
                    </div>
                </>}
            {selectedCard == 1 &&
                <>
                    <InputUnderline placeholder='Correo electrónico' value={paypalEmail} onChange={onPaypalEmailChange} />
                    <div className='mt-7 mb-8'>
                        <BasicButton
                            className='w-full text-base h-12' onClick={onContinue} disabled={disabled1}>
                            Continuar
                        </BasicButton>
                    </div>
                </>}
            {selectedCard == 2 &&
                <>
                    <InputSimple placeholder='Nombre Completo' value={fullName} onChange={onChangeFullName} />
                    <InputSimple placeholder='Correo electrónico' value={wnEmail} onChange={onWNEmailChange} />
                    <InputSimple placeholder='Número Telefónico' value={bankName} onChange={onBankNameChange} />
                    <InputSimple placeholder='Ciudad' value={city} onChange={onCityChange} />
                    <InputUnderline placeholder='País' value={country} onChange={onCountryChange} />
                    <div className='mt-7 mb-8'>
                        <BasicButton
                            className='w-full text-base h-12' onClick={onContinue} disabled={disabled2}>
                            Continuar
                        </BasicButton>
                    </div>
                </>}


        </div>
    )
}

function StepFive({ checkOne, setCheckOne, checkTwo, setCheckTwo, checkThree, setCheckThree, checkFour, setCheckFour, onContinue, loading }) {
    const disabled = !checkOne || !checkTwo || !checkThree || !checkFour

    return (
        <div className='pt-10 flex-1 flex flex-col justify-between'>
            <div>
                <div>
                    <NewCheckbox
                        label={'Declaro que el material subido es original'}
                        isChecked={checkOne}
                        setIsChecked={setCheckOne}
                        idCheckbox={'checkbox0'}
                    />
                </div>
                <div className='mt-4'>
                    <NewCheckbox
                        label={
                            'Declaro que el material no esta subido en otras plataformas'
                        }
                        isChecked={checkTwo}
                        setIsChecked={setCheckTwo}
                        idCheckbox={'checkbox1'}
                    />
                </div>
                <div className='mt-4'>
                    <NewCheckbox
                        label={
                            'Me comprometo a retirar el material cuando decida subirlo a otra plataforma'
                        }
                        isChecked={checkThree}
                        setIsChecked={setCheckThree}
                        idCheckbox={'checkbox2'}
                    />
                    <div className='text-[8px] ml-12 italic'>(Ej. Distribuidora, Manager, Editorial y Sellos discográficos)</div>
                </div>
                <div className='mt-4'>
                    <NewCheckbox
                        label={
                            'Estoy asignando la transferencia bancaria de acuerdo a todos los contratos vigentes con terceros'
                        }
                        isChecked={checkFour}
                        setIsChecked={setCheckFour}
                        idCheckbox={'checkbox3'}
                    />
                </div>
                <Separator className={'mt-10'} />
                <div className='font-interMedium mt-10'>
                    Tiempo de publicación
                </div>
                <pre className='text-xs font-interLight mt-2'>
                    {'Puedes retirar este contenido en cualquier \nmomento contactándonos a \ncontacto@mintapp.info'}
                </pre>
            </div>
            <div className='mt-7 mb-8'>
                <BasicButton
                    style={{ backgroundColor: disabled ? '#D9D9D9' : '#372D90' }}
                    className='w-full text-base h-12'
                    onClick={onContinue} disabled={disabled} loading={loading}>
                    Continuar
                </BasicButton>
            </div>
        </div>
    )
}

function Steps({ step }) {
    return (
        <ul className='flex justify-between my-6 text-[0.6rem]'>
            <li className={`${step === 1 ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                1. Información
            </li>
            <li className={`${step === 2 ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                2. Sube el material
            </li>
            <li className={`${step === 3 ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                3. Planes
            </li>
            <li className={`${step === 4 ? 'font-semibold' : 'text-black/[.3]'} text-[.62rem] font-interRegular`}>
                4. Pagos
            </li>
        </ul>
    )
}

export default Audios2;
