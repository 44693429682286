import React, { useEffect, useState } from 'react'
import { Header } from '../../../../components/Headers'
import { useNavigate, useParams } from 'react-router-dom'
import { useApi } from '../../../../network/hooks/useApi';
import { resources } from '../../../../network/resources';
import config from '../../../../config';
import { useSelector } from 'react-redux';
import { s_session_token } from '../../../../reducer/SessionReducer';
import { CenterSpinner } from '../../../../components/Spinner';
import { ROOT } from '../../../../utils/Routes';
import { IMAGES } from '../../../../assets';
import { FormatMoney } from '../../../../utils';


export const QRScanner = () => {
    const naviagte = useNavigate()
    const d = useParams()
    const accessToken = useSelector(s_session_token);

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [ticket, setTicket] = useState('')
   const [friends, setFriends] = useState([])

    async function fetchTickets() {
        try {
            const reply = await fetch(`${config.API_URL}/store/event/my-tickets/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            return await reply.json();
        } catch (error) {
            return false
        }
    }

    useEffect(() => {
        GetMyTickets()
    }, [])


    const GetMyTickets = async () => {
        try {
            setLoading(true)
            let reply = await fetchTickets()
            console.log(reply)
            if (reply) {
                if (reply.is_paid) {
                    setData(reply)
                    setTicket(reply.ticket)
                    setFriends(reply.friends)
                }
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }


    return (
        <div className='h-full w-full bg-white overflow-scroll'>
            <Header onBack={() => naviagte(ROOT)} />
            {loading ? <CenterSpinner />
                : <div className='px-6'>
                    {data?.is_paid && <>
                        <div className='text-[1.12rem] font-interSemiBold mb-5'>Shisosa & Amigos</div>
                        <div className='flex flex-row justify-between w-[18rem]'>
                            <TitleWithText
                                title={'Lugar'}
                                text={friends[0]?.ticket?.event?.location}
                            />
                            <TitleWithText
                                title={'Hora de inicio'}
                                text={friends[0]?.ticket?.event?.start_time}
                            />
                        </div>
                        <div className='flex flex-row justify-between w-[18rem] my-4'>
                            <TitleWithText
                                title={'Fecha'}
                                text={'17/08/2023'}
                            />
                            <TitleWithText
                                title={'Total'}
                                text={`$${FormatMoney(Math.trunc(data?.price))} CLP`}
                            />
                        </div>
                        <div className='overflow-scroll'>
                            {friends?.length && friends?.map((item, idx) => {
                                return (
                                    <>
                                        <div className='bg-[#D9D9D9] h-[1px] my-[3vh]' />
                                        <div>
                                            <div className='text-base font-interSemiBold mb-[.5]'>Nombre</div>
                                            <div className='text-base font-interRegular w-[90%]'>{item?.name}</div>
                                        </div>
                                        <div className='flex items-center justify-between my-4 '>
                                            <img
                                                src={IMAGES.FLYER}
                                                style={{ height: "auto", maxWidth: "45.5%", width: "45.5%", borderRadius: 10 }}
                                            />
                                            <img className='ml-[-2vw]'
                                                src={item.qr_code}
                                                style={{ height: "auto", maxWidth: "54%", width: "54%" }}
                                            />
                                        </div>
                                        {/* <div className='text-sm font-interSemiBold'>CODIGO #32423/1</div> */}
                                        <div className='text-sm font-interRegular italic'>Las entradas son personales e intransferibles</div>
                                        <p className='text-sm text-[#1C1C1C] mt-6 w-[16rem] italic'>{'En caso de duda escríbenos a contacto@mintapp.info'}</p>
                                    </>
                                )
                            })}

                        </div>
                        <div className='mb-[6vh]' />
                    </>}
                </div>}
        </div>
    )
}

export default QRScanner

function TitleWithText({ title, text }) {
    return (
        <div>
            <div className='text-base font-interSemiBold mb-[.5]'>{title}</div>
            <div className='text-base font-interRegular w-[8rem]'>{text}</div>
        </div>
    )
}