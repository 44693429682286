const Selected = 'font-semibold text-[.62rem] font-interRegular'
const NotSelected = 'text-black/[.3] text-[.62rem] font-interRegular'

export function Steps({ selected }) {
  return (
    <ul className='flex justify-between py-6'>
      <li className={selected === 0 ? Selected : NotSelected}>
        1. Planes
      </li>
      <li className={selected === 1 ? Selected : NotSelected}>
        2. Medio de Pago
      </li>
      <li className={selected === 2 ? Selected : NotSelected}>
        3. Acceder
      </li>
    </ul>
  )
}