import { useDispatch, useSelector } from 'react-redux';
// import { ampli } from '../ampli';
import { logout, s_is_auth, s_user_info } from '../reducer/SessionReducer';

import './MenuModal.css';
import { BackIcon, MenuIcon } from './Icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  CREATE_AUDIO,
  LOGIN,
  ROOT,
  ROUTE_MIS_COMPRAS,
  TRANSACTION_HISTORY,
  WELCOME,
} from '../utils/Routes';
import ReactGA from 'react-ga4';
import { empty } from '../utils';

export default function MenuModal({ onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useSelector(s_user_info);
  const is_auth = useSelector(s_is_auth)

  const handleHistorial = () => {
    navigate(TRANSACTION_HISTORY);
  };

  const handleLogout = () => {
    dispatch(logout());
    onClose();

    // ampli.logout();
    navigate(ROOT);
    ReactGA.event('app_logout', { event_name: 'logout' });
    setTimeout(() => {
      // ampli.client.reset();
    }, 500);
  };

  const handleClose = (e) => {
    if (onClose && e.target === document.getElementById('menu-modal')) {
      onClose();
    }
  };

  const handleUploadMaterial = () => navigate(WELCOME);
  const handleLogin = () => navigate(LOGIN, { state: { from: 'login' } })
  const handleLoginCreate = () => navigate(LOGIN, { state: { from: 'create' } })
  const handleCreateArtist = () => {

  }

  return (
    <div
      className='menu-modal-background'
      id='menu-modal'
      onClick={handleClose}
    >
      <div
        className='absolute top-6 left-6 right-6 md:left-8 cursor-pointer flex justify-between'
        onClick={onClose}
      >
        <BackIcon />
      </div>
      <div className='menu-modal-container'>
        <div className='px-10 pt-5 text-lg'>
          {is_auth && <>
            <div className='flex justify-center'>
              <div className='border-2 border-white w-24 rounded'></div>
            </div>
            {/* <div className='text-2xl font-bold mb-8 mt-8 font-interRegular'>
              {empty(name) ? 'Nombre' : name}
            </div> */}
            <div
              className='mt-2 py-4 cursor-pointer font-interRegular'
              onClick={handleUploadMaterial}
            >
              {/* <i className='font-interBold'>Sube tu material</i> */}
              <i className='font-interBold'>Crear perfil artista</i>
            </div>
            <div className='divider w-full'></div>
            <Link to={ROUTE_MIS_COMPRAS}>
              <div className='mt-2 py-4 cursor-pointer font-interRegular' onClick={handleUploadMaterial}>
                Mis Compras
              </div>
            </Link>
            <div className='divider w-full'></div>
            <div
              className='py-4 cursor-pointer font-interRegular'
              onClick={handleHistorial}
            >
              Historial de compras
            </div>
            <div className='divider w-full'></div>
            <div
              className='py-4 cursor-pointer font-interRegular'
              onClick={handleLogout}
            >
              Cerrar sesión
            </div>
          </>}
          {!is_auth && <LoginMenu continueLogin={handleLogin} onCreateArtist={handleLoginCreate} />}
        </div>
      </div>
    </div>
  );
}

const LoginMenu = ({ continueLogin, onCreateArtist }) => (
  <div>
    <div className='text-2xl font-bold mb-8 mt-8 font-interRegular'>¡Bienvenido!</div>
    <div
      className='py-4 cursor-pointer font-interRegular'
      onClick={continueLogin}
    >
      Inicia sesión
    </div>
    <div className='divider w-full'></div>
    {/* <div
      className='py-4 cursor-pointer font-interRegular'
      onClick={onCreateArtist}
    >
      Crea perfil de artista
    </div> */}
  </div>
);
