import React from 'react'
import { auth, db } from '../../../firebase'
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { useSelector } from 'react-redux';
import { s_payment_params } from '../../../reducer/PaymentReducer';
import { generateSecureRandomString, uniqueID } from '../../../utils';
import moment from 'moment';
import config from '../../../config';

export const usePayments = () => {

    const {
        creatorId,
        creatorName,
        productId,
        content_title,
        inner_photo,
    } = useSelector(s_payment_params);

    const AddProduct = async () => {
        let uid = auth?.currentUser?.uid
        if (uid) {
            let res = await checkProductSubscription(uid, productId)
            // console.log(res)
            if (!res) {
                // SAVETODB(uid)
            }
            else {
                console.log(res.code)
            }
            // getUserData(uid)
            // console.log(uid)

        }
    }
    async function getUserData(uid) {
        const userRef = doc(db, "users", uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            // console.log("User data:", userSnap.data());
            return userSnap.data();
        } else {
            // Handle the case where there is no such document
            // console.error("No such document!");
            return null;
        }
    }

    const SaveToDb = async (uid, price) => {
        let _id = uniqueID()
        const secureRandomString = generateSecureRandomString(8);
        const productRef = doc(db, "feeds", productId);
        let purchase_data = {
            id: _id,
            productId: productId,
            product: productRef,
            purchaserId: uid,
            isRedeem: false,
            client: 'web',
            provider: 'stripe',
            price: price,
            currency: 'CLP',
            code: secureRandomString,
            creatorId: creatorId,
            date: new Date(),
            isExpired: false,
            validTo: moment(new Date()).add(1, 'month').toDate()
        }
        // Define document references for both collections with the same ID
        const subscriptionDocRef = doc(db, "subscriptions", _id);
        const salesDocRef = doc(db, "sales", _id);
        try {
            // Save the purchase data to the subscriptions collection
            await setDoc(subscriptionDocRef, purchase_data);
            // Save the purchase data to the sales collection
            await setDoc(salesDocRef, purchase_data);
            SendEMail(uid, purchase_data.code)
            // console.log("Purchase data saved successfully to both collections.");
        } catch (error) {
            console.error("Error saving purchase data:", error);
        }
        return _id
    }

    const SendEMail = (uid, code) => {
        fetch(`${config.FUNCTIONS_URL}/sendEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any additional headers if required
            },
            body: JSON.stringify({
                userId: uid,
                code: code,
                mediaTitle: content_title,
                backgroundImage: inner_photo,
            })
        })
            .then(response => {
                if (response.ok) {
                    // console.log('Email sent successfully');
                } else {
                    console.error('Error sending email');
                }
            })
            .catch(error => {
                // console.log('Error sending email:', error);
            });
    }

    async function checkProductSubscription(purchaserId, productId) {
        // Reference to the 'subscriptions' collection
        const subscriptionsRef = collection(db, "subscriptions");
        // Create a query against the collection
        const q = query(subscriptionsRef, where("purchaserId", "==", purchaserId), where("productId", "==", productId));
        try {
            // Execute the query
            const querySnapshot = await getDocs(q);

            // Check if we got any documents matching the criteria
            if (querySnapshot.empty) {
                // console.log("No matching subscriptions found.");
                return false;
            } else {
                // console.log("Matching subscription found.");
                // console.log(querySnapshot.docs[0].data())
                return querySnapshot.docs[0].data()
            }
        } catch (error) {
            console.error("Error checking subscription:", error);
            return false; // Handle error appropriately in your context
        }
    }


    return {
        AddProduct,
        SaveToDb,
        checkProductSubscription
    }
}
