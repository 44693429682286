import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { login, s_login_session_code } from "../../reducer/SessionReducer"
import { useEffect, useState } from "react";
import config from "../../config";
import { PAYMENT_SELECTION, ROOT, paymentSelectionPath } from "../../utils/Routes";
import { s_payment_params } from "../../reducer/PaymentReducer";
import { CenterSpinner } from "../../components/Spinner";
import { str_not_empty } from "../../utils";
import { BasicButton } from "../../components/NewButtons";
import NewHeader from "../../components/NewHeader";


async function sendCode(email, code, session) {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, code, session }),
  };

  const response = await fetch(config.API_URL + '/auth/email-login/', options);
  return await response.json();
}


function AutoLogin() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { code } = useParams()
  //const { session_code, email } = useSelector(s_login_session_code)
  const { slugField, creatorName } = useSelector(s_payment_params);
  const [error, setError] = useState('')

  console.log('[params]', slugField, creatorName)
  const authLogin = async () => {
    try {
      //console.log('[AUTH_LOGIN]', email, code, session_code, slugField)
      const searchParams = new URLSearchParams(window.location.search);
      const urlSession = searchParams.get("session")
      const urlEmail = searchParams.get("email")

      const reply = await sendCode(urlEmail, code, urlSession)
      //console.log('[AUTH_LOGIN]', reply)
      if (reply.detail) {
        setError(reply.detail);
      } else if (reply.success === true) {
        dispatch(
          login({
            accessToken: reply.tokens.access,
            refreshToken: reply.tokens.refresh,
            email: urlEmail,
          })
        );
        //navigate(paymentSelectionPath(creatorName, slugField))
        navigate(ROOT)
      } else {
        setError('Error')
      }
    } catch (e) {
      console.error('[AUTH_LOGIN]', e)
      setError('Codigo incorrecto')
    }
  }

  useEffect(() => {
    authLogin()
  }, [])

  return (
    <div className="bg-white h-screen font-interRegular">
      <NewHeader dark hideMenu />
      {str_not_empty(error) ? <CodeError /> : <CenterSpinner />}
    </div>
  )
}

const CodeError = () => (
  <div className="px-8">
    <div className="font-bold text-2xl w-[150px] mt-[10vh]">CÓDIGO INCORRECTO O EXPIRADO</div>
    <div className="mt-10 w-[200px]">El código caduca después de 5 minutos.</div>
    <div className="mt-6 font-bold">Vuelve a intentarlo.</div>
    <BasicButton className="mt-10 w-full">Intentarlo nuevamente</BasicButton>
    <div className="text-center mt-6 italic underline">Ayuda</div>
  </div>
)

export default AutoLogin