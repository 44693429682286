import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import sessionReducer from './SessionReducer';
import paymentReducer from './PaymentReducer';
import ticketsReducer from './TicketsReducer';
import merchReducer from './MerchReducer';
import feedReducer from './FeedsReducer';


const reducers = combineReducers({
  session: sessionReducer,
  payment: paymentReducer,
  tickets: ticketsReducer,
  merch: merchReducer,
  feeds: feedReducer,

});

const persistConfig = {
  key: 'root-mint-web-v4',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
