import { BasicButton } from "../NewButtons"
import { SmallSpinner } from "../Spinner"


function LandingButton({ loading, label, onClick, className }) {
  return (
    <BasicButton
      style={{ zIndex: 10000 }}
      className={`w-full h-[40px] ${className}`} onClick={onClick}>
      <div className='text-[18px] font-interSemiBold'>
        {loading ? <SmallSpinner /> : label}
      </div>
    </BasicButton>
  )
}

export default LandingButton