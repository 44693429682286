import React from 'react'
import './deleteModal.css'
import { SmallSpinner, SmallSpinnerWhite } from '../Spinner';

export function DeleteModal({ onClose, onDelete, loading }) {

    const handleClose = (e) => {
        if (onClose && e.target === document.getElementById('delete-modal')) {
            onClose();
        }
    }

    return (
        <div>
            <div className='delete-modal-background' id='delete-modal'
                onClick={handleClose}>
                <div className='delete-modal-container'>
                    <div className='text-[13px] mx-10'>
                        ¿Estas seguro que quieres eliminar la tarjeta registrada?
                    </div>
                    <div className='flex justify-between px-5 mt-4 mb-2'>
                        <Button
                            onClick={() => onClose()}
                            className={'bg-[#DCDCDC]'}
                            text={'Mantener'}
                        />
                        <Button
                            loading={loading}
                            onClick={onDelete}
                            className={'bg-[#000]'}
                            text={'Eliminar'}
                            textColor={'text-[#DCDCDC]'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Button = ({ text, textColor, className, onClick, loading }) => {
    return (
        <button onClick={onClick}
            className={`h-10 w-[45%] flex rounded-lg items-center justify-center ${className}`}>
            {loading && <SmallSpinner color={'white'} />}
            {!loading && <div className={`font-interBold text-[13px] ${textColor}`} >{text}</div>}
        </button>
    )
}