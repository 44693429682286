import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { BackIcon, ChromecastIcon } from '../../../../components/Icons'
import { ROOT } from '../../../../utils/Routes';

import background from '../../../../assets/onlineshow.png'


const targetDate = new Date();
targetDate.setDate(targetDate.getDate() + 3);


export default function CountdownEvent() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(v => calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    return { days, hours, minutes };
  }

  return (
    <div className="absolute top-0 left-0 overflow-hidden w-[100dvh] h-[100dvw]" style={{ transform: 'rotate(90deg) translateY(-100%)', transformOrigin: 'top left' }}>
      <img src={background} className='object-cover'/>
      <div className='absolute top-0 left-0 w-full p-6 flex justify-between z-[100]'>
        <Link to={-1}>
          <BackIcon />
        </Link>
        <ChromecastIcon />
      </div>
      <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center text-white'>
        <div>
          <div className='flex justify-center'>
            <div className='text-xs bg-white text-black w-[7rem] text-center py-[.15rem] rounded-full'>SHOW EN LÍNEA</div>
          </div>
          <div className='mt-10 text-3xl text-center'>Faltan</div>
          <div className='flex'>
            <div>
              <div className='text-5xl'>{timeLeft.days}</div>
              <div>días</div>
            </div>
            <div className='text-5xl mx-4'>:</div>
            <div>
              <div className='text-5xl'>{timeLeft.hours}</div>
              <div>horas</div>
            </div>
            <div className='text-5xl mx-4'>:</div>
            <div>
              <div className='text-5xl'>{timeLeft.minutes}</div>
              <div>minutos</div>
            </div>
          </div>
          <div className='mt-10 text-center text-xs'>Shisosas Party</div>
        </div>
      </div>
    </div>
  )
}
