import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

export const feedsSlice = createSlice({
    name: 'feeds',
    initialState: {
        feeds: [],
    },
    reducers: {
        setfeeds: (state, action) => {
            state.feeds = action.payload.feeds;
        },

    },
});

export function s_feeds(state) {
    return state.feeds;
}


export const {
    setfeeds
} = feedsSlice.actions;
export default feedsSlice.reducer;
