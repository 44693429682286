import { useState } from 'react';
import NewHeader from '../../components/NewHeader';
import Spinner from '../../components/Spinner';
import { useApi } from '../../network/hooks/useApi';
import { resources } from '../../network/resources';
import { formatDate } from '../../utils';

function TransactionHistory() {
  const [selectedMonth, setSelectedMonth] = useState('');

  const {
    data: history,
    loading: loadingHistory,
    error: historyError,
  } = useApi(resources['transactionHistory']);

  const uniqueMonths = [
    ...new Set(history.map((item) => new Date(item.date).getMonth())),
  ];

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredData = selectedMonth
    ? history.filter(
        (item) => new Date(item.date).getMonth() === parseInt(selectedMonth)
      )
    : history;

  return (
    <div className='bg-white h-screen overflow-scroll'>
      <NewHeader dark back />
      <div className='p-10 pt-12'>
        <h1 className='text-2xl font-bold'>Historial de compras</h1>
        <div>
          <select
            value={selectedMonth}
            onChange={handleMonthChange}
            className='border text-sm rounded-[30px] w-28 p-[.3rem] bg-black text-white mt-5 mb-5 capitalize'
          >
            {uniqueMonths.map((month) => (
              <option key={month} value={month}>
                {new Date(0, month).toLocaleString(undefined, {
                  month: 'long',
                })}
              </option>
            ))}
          </select>
          {loadingHistory ? (
            <div className='flex justify-center'>
              <Spinner />
            </div>
          ) : (
            <div>
              {filteredData.length !== 0 ? (
                filteredData.map((item) => (
                  <div
                    key={item.date}
                    className='flex justify-between items-center pb-4 mb-4 border-b-2'
                  >
                    <div className='basis-3/5'>
                      <p className='font-bold text-sm'>{item.description}</p>
                      <p className='text-xs font-light capitalize'>
                        {formatDate(item.date, 'date')}
                      </p>
                    </div>
                    <p className='text-base font-bold'>$ {item.amount}</p>
                  </div>
                ))
              ) : (
                <p>No hay pagos este mes</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransactionHistory;
