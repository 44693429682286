import { useEffect, useRef, useState } from "react"
import { useSwiper, useSwiperSlide } from "swiper/react";

import './VideoPlayer.css'
import { CenterSpinner } from "../Spinner";


function VideoPlayer({ src, mute, index }) {
  const videoRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const swiperSlide = useSwiperSlide();
  const swiper = useSwiper()

  const handlePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  const handleEnded = () => swiper?.slideNext()

  const handleLoadedData = async () => {
    setLoading(false)
    if (swiperSlide.isActive) {
      await videoRef.current.play()
    }
  }

  const handleActivePlay = async () => {
    if (videoRef.current === null) {
      return
    }

    try {
      if (!swiperSlide.isActive) {
        await videoRef.current.pause();
        videoRef.current.currentTime = 0;
      } else {
        await videoRef.current.play()
      }
    } catch (e) {
      console.log('[active play]', e)
    }
    //console.log('[active play]', swiperSlide.isActive, index)
  }

  useEffect(() => {
    handleActivePlay()
  }, [swiperSlide.isActive]);

  return (
    <div id="video-player" style={{ zIndex: 10 }} className={`w-full h-[100svh]`}>
      <video
        ref={videoRef}
        src={src}
        className="w-full h-[100svh] object-contain landscape:object-cover"
        preload="auto"
        style={{ zIndex: -10 }}
        onLoadedData={handleLoadedData}
        onEnded={handleEnded}
        playsInline
        muted={mute}
        onClick={handlePlay}
        autoPlay={false}
      />
      {loading && <Loading />}
    </div>
  )
}

const Loading = () => (
  <div className='absolute top-0 left-0 w-full' style={{ zIndex: 1000 }}>
    <CenterSpinner />
  </div>
)

export default VideoPlayer