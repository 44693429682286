import './NewButtons.css';
import { BiArrowBack } from 'react-icons/bi';
import { IoCopy } from "react-icons/io5";
import { SmallSpinner, TailSpinner } from './Spinner';
import { AddIcon, AppleIcon, GoogleIcon, InstagramIcon, MuteIcon, TiktokIcon, UnMuteIcon } from './Icons';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

export function BasicButton({ disabled, loading, children, ...props }) {
  return (
    <button
      {...props}
      disabled={disabled}
      className={`basic-button ${props.className} ${disabled && 'bg-[#D9D9D9]'
        }`}
    >
      {loading ? <SmallSpinner /> : children}
    </button>
  );
}

export function Button({ className, onClick, disabled, children, loading }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={`h-12 px-8 rounded-3xl ${className}`}
    >
      {loading ? (
        <div className='flex justify-center font-interRegular'>
          <SmallSpinner />
        </div>
      ) : (
        children
      )}
    </button>
  );
}

export function ArrowButton({
  light,
  loading,
  size = 24,
  large,
  link,
  ...props
}) {
  const sizeClass = large ? 'w-11 h-11' : 'w-9 h-9';
  return (
    <button
      {...props}
      className={`${sizeClass} flex justify-center items-center ${light ? 'bg-white' : 'bg-black'
        } rounded-full ${!loading && 'scale-x-[-1]'}`}
    >
      <Link to={link && link}>
        {loading ? (
          <TailSpinner className='h-5 w-5' />
        ) : (
          <BiArrowBack size={size} color={light ? 'black' : 'white'} />
        )}
      </Link>
    </button>
  );
}

export function IconButton({ icon, onClick, className }) {
  let IconComponent;

  switch (icon) {
    case 'tiktok':
      IconComponent = <TiktokIcon />;
      break;

    case 'google':
      IconComponent = <GoogleIcon />;
      break;

    case 'apple':
      IconComponent = <AppleIcon />
      break;

    default:
      IconComponent = <InstagramIcon />;
      break;
  }

  return (
    <button
      onClick={onClick}
      className={`w-[5.5rem] h-14 bg-white flex justify-center items-center rounded-lg ${className}`}
    >
      {IconComponent}
    </button>
  );
}

export function ButtonDark(props) {
  return (
    <Button {...props} className={`bg-black text-white ${props.className}`} />
  );
}

export function CircleButton({ onClick }) {
  return (
    <button onClick={onClick}>
      <AddIcon />
    </button>
  )
}

export function ButtonGrey({ label, onClick, loading, disabled }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className='w-full bg-[#DBDBDB] h-[35px] p-2 rounded-full font-interRegular font-semibold flex items-center justify-center text-sm'>
      {loading ? <SmallSpinner /> : label}
    </button>
  )
}
export function ButtonBlack({ label, onClick, loading, disabled }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className='w-full bg-[#000] h-[35px] p-2 text-[#fff] rounded-full font-interRegular font-semibold flex items-center justify-center text-sm'>
      {loading ? <SmallSpinner /> : label}
    </button>
  )
}

export function ButtonBlue({ label, onClick, loading, disabled }) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className='w-full bg-[#0a7ace] h-[35px] p-2 text-[#fff] rounded-full font-interRegular font-semibold flex items-center justify-center text-sm'>
      {loading ? <SmallSpinner /> : label}
    </button>
  )
}

export function MuteButton({ muted }) {
  return (
    <>
      {/* {muted ? <div className="bg-[#ffffff30] h-[4rem] w-[4rem] flex items-center justify-center rounded-full ">
        <UnMuteIcon />
      </div>
        // }
        : */}
      <div className="bg-[#ffffff30] h-[4rem] w-[4rem] flex items-center justify-center rounded-full opacity-0 vanish-animation">
        <MuteIcon />
      </div>
    </>
  )
}

export function ButtonGreyCircle({ children, onClick }) {
  return (
    <button className='bg-[#DBDBDB] rounded-full h-8 w-8 flex items-center justify-center' onClick={onClick}>
      {children}
    </button>
  )
}

export function CopyButton({ text }) {
  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    toast.success("copiado")
  }

  return (
    <button onClick={handleCopy}>
      <IoCopy size={24} />
    </button>
  )
}