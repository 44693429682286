import React from 'react'
import { VideoDescriptions } from './VideoDescription'
import LandingButton from './LandingButton'

export function FooterInfo({ name, title, description, onClick, slug }) {
    return (
        <div style={{ background: "linear-gradient(#00000000 , #00000080, #000)" }}
            className="px-8 absolute bottom-0 pb-8 pt-16 right-0 left-0 text-white">
            <div className='text-[14px] mb-3'>
                Descarga la App para acceder a tu canción
            </div>
            <LandingButton
                onClick={onClick}
                label={'Mint App'}
            />
            <div className='h-[1px] w-[26px] bg-white my-6' />
            <VideoDescriptions name={name} title={title} description={description} slug={slug} />
        </div>
    )
}