import { useState } from "react"
import { Icons } from "../../../../assets"
import { PaymentCard } from "../../../../components"
import { InputSimple, InputUnderline } from "../../../../components/NewInputs"


const PAYMENT_CARDS = [
  { id: '1', icon: Icons.tb },
  { id: '2', icon: Icons.paypal },
  { id: '3', icon: Icons.wunion },
]


export default function Step2({
  selectedCard,
  setSelectedCard,
  onInfoChange,
  accountName,
  accountNumber,
  bankName,
  accountType,
  email,
  phone,
  fullName,
  city,
  country,
}) {

  const handleEmailChange = (e) => onInfoChange(e.target.value, 'email')
  const handlePhoneChange = (e) => onInfoChange(e.target.value, 'phone')

  return (
    <div>
      <div className='font-bold font-interRegular text-base'>
        Datos bancarios y de contacto *
      </div>
      <div className='text-xs text-[#BFBFBF] mt-2 mb-4'>
        Datos del responsable de repartir las ganancias de acuerdo a los contratos vigentes.
      </div>
      <div className='flex justify-between mb-4' >
        {PAYMENT_CARDS.map((item, idx) => {
          return (
            <PaymentCard key={idx} onClick={() => setSelectedCard(idx)} selected={selectedCard == idx} icon={item.icon} />
          )
        })}
      </div>
      {selectedCard == 0 &&
        <>
          <InputSimple placeholder='Nombre de la cuenta bancaria' value={accountName} onChange={e => onInfoChange(e.target.value, 'accountName')} />
          <InputSimple placeholder='N° cuenta bancaria' value={accountNumber} onChange={e => onInfoChange(e.target.value, 'accountNumber')} />
          <InputSimple placeholder='Banco' value={bankName} onChange={e => onInfoChange(e.target.value, 'bankName')} />
          <InputSimple placeholder='Tipo de Cuenta' value={accountType} onChange={e => onInfoChange(e.target.value, 'accountType')} />
          <InputSimple placeholder='Correo electrónico' value={email} onChange={handleEmailChange} />
          <InputUnderline placeholder='Número telefónico' value={phone} onChange={handlePhoneChange} />
        </>}
      {selectedCard == 1 &&
        <>
          <InputUnderline placeholder='Correo electrónico' value={email} onChange={handleEmailChange} />
        </>}
      {selectedCard == 2 &&
        <>
          <InputSimple placeholder='Nombre Completo' value={fullName} onChange={e => onInfoChange(e.target.value, 'fullName')} />
          <InputSimple placeholder='Correo electrónico' value={email} onChange={handleEmailChange} />
          <InputSimple placeholder='Número Telefónico' value={phone} onChange={handlePhoneChange} />
          <InputSimple placeholder='Ciudad' value={city} onChange={e => onInfoChange(e.target.value, 'city')} />
          <InputUnderline placeholder='País' value={country} onChange={e => onInfoChange(e.target.value, 'country')} />
        </>}
    </div>
  )
}