export const resources = {
  creatorRequest: '/users/requests/creator',
  createPayment: '/users/payment/creator',
  createTickets: '/store/event/',
  feed: '/feed/',
  stories: '/users/creators',
  trends: '/trends/',
  videos: '/videos/',
  texts: '/texts/',
  audios: '/audios/',
  audiosNew: '/preventa/audios/:name',
  photos: '/photos/',
  balance: '/clientes/get_balance/',
  payments: '/payments/',
  addFunds: '/addFunds/begin',
  creators: '/creator/',
  singleCreator: '/users/creators/:id',
  userInfoGoogle: '/auth/me/',
  events: '/store/events/',
  videoCalls: '/store/video-call/:id',
  singleVideoCalls: '/videocalls/:id',
  onlinePresentations: '/onlinePresentations/',
  scheduleVideocall: '/store/video-call/',
  createVideocall: '/store/video-call/',
  buyVideocall: '/store/video-call/buy/',
  singleonlinePresentations: '/onlinePresentations/:id',
  scheduleOnlinePresentation:
    '/onlinePresentations/scheduleOnlinePresentation/',
  storeMerchandising: '/store/merchandising/:id',
  merchandising: '/store/merchandising/',
  merchandising_detail: '/merchandising/:id/',
  buyMerchandising: '/store/merchandising/buy/',
  storePhotos: '/store/photos/',
  storeVideos: '/store/videos/',
  storeAudios: '/store/audios/',
  buyTicket: '/store/tickets/buy/',
  activateUserCreator: '/users/creators/activate/',
  deActivateUserCreator: '/users/creators/deactivate/',
  storeTickets: '/store/tickets/',
  sendMessage: '/messaging/message/',
  conversations: '/messaging/conversations/',
  conversationMessages: '/messaging/messages/:id',
  offerCopyrighted: '/store/copyrighted_material/offer/',
  copyrightedMaterial: '/store/copyrighted_material/',
  creatorCopyrightedMaterial: '/store/copyrighted_material/:id',
  updateProfile: '/users/creators/UpdateProfile/',
  getStoreTickets: '/store/tickets/:id',
  singleVideo: '/videos/:id/',
  singleText: '/texts/:id/',
  checkout: '/checkout/:slug',
  pay: '/pay/pay/:purchaseCode/:type',
  transactionHistory: '/transactions/client_transactions',
  //addCreditCard: '/pay/addCreditCard',
  addCreditCard: '/pay/pay/:purchaseCode/stripe_save_card',
  getCards: '/pay/getCustomerCards',
  deleteCard: '/pay/deleteCreditCard/:digits',
  getReproductions: '/subscriptions/get/',
  subscriptions: '/subscriptions/get/:slug',
  useReproductions: '/subscriptions/use/:slug',
  shisosaParty: '/store/event/buy_tickets/shisosa-party/',
  myTickets: '/store/event/my-tickets/',
  searchBySlug: '/preventa/search_by_slug/:slug',
  userPurshased: '/users/purchased/creators',
  createMerch: '/users/api/collections/',
  merchProducts: '/users/api/collections/list/:id/',
  merchEvents: '/users/api/creators/get-merchandising-events/:name/',
  getPurchaseCode: '/users/api/media-puchased-code/',
  verticalFeed: '/feed/vertical'
};
