import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { useState } from 'react'
import { auth, db } from '../../firebase'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { useStripe } from '@stripe/react-stripe-js'
import { generateSecureRandomString, uniqueID } from '../../utils'
import toast from 'react-hot-toast'
import config from '../../config'
import ampli from '../../ampli'

export const usePamynet = () => {
    const stripe = useStripe();
    const [initModal, setinitModal] = useState(false)
    const [paymentModal, setPaymentModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [currentProduct, setCurrentProduct] = useState(null)
    const [infoModal, setInfoModal] = useState(false)
    const [authLoading, setAuthLoading] = useState(false);
    const [prod, setProduct] = useState(false);
    const [codeVisible, setCodeVisible] = useState(false);

    const closeInfoModal = () => {
        // setSelectedIndex(-1)
        setInfoModal(false)
    }

    const StartPayment = () => {
        try {
            setLoading(true)
            setInfoModal(true)
            setinitModal(false)
            setTimeout(() => {
                setLoading(false)
            }, 1000);
        } catch (error) {

        }
    }

    const handleAuth = async (email, password, createMode) => {
        try {
            setAuthLoading(true)
            if (!createMode) {
                // console.log(createMode, email, password)
                const userCredential = await signInWithEmailAndPassword(auth, email, password)
                    .catch(async err => {
                        if (err?.code == 'auth/invalid-credential') {
                            toast.error('correo y/o contraseña incorrectos. Si no tienes cuenta, crea una cuenta') // In case pas or email incorrect
                        }
                        if (err?.code == 'auth/too-many-requests') {
                            toast.error('Demasiadas solicitudes incorrectas. Intenta más tarde') // In case pas or email incorrect
                        }
                        // else if (err?.code == 'auth/email-already-in-use') {
                        //     toast.error('Esta cuenta no fue creada. Crea una cuenta') // message general 
                        // }
                        else {
                            toast.error('Algo salió mal, vuelve a intentar en unos minutos') // message general 
                        }
                        // toast.error('Si no tienes una cuenta, crea una cuenta') //
                        // toast.error('correo y clave no coinciden') 
                    })
                // console.log('userCredential::', userCredential)
                if (userCredential) {
                    ampli.logUserLogin(email, userCredential?.user?.uid)
                    await CreatePay(userCredential?.user?.uid)
                }
            }
            else {
                let u = await createUserWithEmailAndPassword(auth, email, password)
                    .catch(async err => {
                        if (err.code == 'auth/email-already-in-use') {
                            toast.error('Esta cuenta ya fue creada. Inicia sesión')
                        }
                        else {
                            toast.error('Algo salió mal, vuelve a intentar en unos minutos') // message general 
                        }
                    })
                if (u?.user) {
                    await SaveData(u?.user)
                    await CreatePay(u?.user.uid)
                    ampli.logSignUp(email, u?.user?.uid)
                }
            }
        } catch (e) {
            console.error('[HANDLE AUTH NO OTP]', e)
            setAuthLoading(false)
        } finally {
            setAuthLoading(false)
        }
    }

    const SaveData = async (user) => {
        let _uid = user.uid
        const name = user?.email?.split('@')[0];
        const _data = {
            // phone: phone,
            userId: _uid,
            email: user.email,
            isCreator: false,
            isVerified: false,
            name: name,
            bio: '',
            photo: '',
            balance: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
        }
        try {
            if (_uid) {
                const userRef = await doc(db, 'users', _uid);
                await setDoc(userRef, _data, { merge: true }).then(res => { }).catch(err => { });
            }
        } catch (error) {
            console.error('Error adding user to Firestore:', error);
        }
    }

    const CreatePay = async (uid) => {

        // e.preventDefault();
        let price = currentProduct?.price?.replace(/\D/g, '');
        const code = generateSecureRandomString(8);
        let _id = uniqueID()
        const product = {
            name: currentProduct?.title,
            // description: currentProduct?.title,
            amount: price,
            currency: 'clp',
            quantity: 1,
        }
        try {
            const lineItem = {
                price_data: {
                    currency: product.currency,
                    product_data: {
                        name: product.name,
                        description: product.description,
                    },
                    unit_amount: product.amount,
                },
                quantity: product.quantity,
            };
            // let url = `${config.DEV_FIREBASE_URL}/createCheckoutSession` // dev firebase
            let url = `${config.FUNCTIONS_URL}/createCheckoutSession` // live firebase
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lineItems: [lineItem],
                    // successUrl: `http://localhost:3000`,
                    successUrl: `${config.APP_URL}`,
                    cancelUrl: `${config.APP_URL}/payment/unsuccessfull/${currentProduct?.id}`,
                    // cancelUrl: `https://localhost:3000/payment/unsuccessfull/${currentProduct?.id}`,
                    mode: 'payment',
                    metadata: {
                        id: _id,
                        productId: currentProduct?.id,
                        purchaserId: uid,
                        creatorId: currentProduct.userId,
                        creatorName: currentProduct?.name ?? '',
                        price: price,
                        client: 'web',
                        code: code,
                        image: currentProduct.cover,
                        title: currentProduct.title,
                    }
                }),
            });

            setAuthLoading(false)
            const { sessionId } = await response.json();
            // Redirect to checkout
            const { error } = await stripe.redirectToCheckout({
                sessionId,
            });
            ampli.track('Redirect to Stripe', {
                id: uid,
                creatorId: currentProduct.userId,
                title: currentProduct?.name ?? '',
                productId: currentProduct?.id ?? '',
            })
            if (error) {
                setAuthLoading(false)
                console.log(error)
                // console.warn(error.message);
            }
        } catch (error) {
            console.log('error:::', error)
            setAuthLoading(false)
        }
    }

    const HandlePay = async (uid) => {
        let res = await checkProductSubscription(uid, currentProduct?.productId)
        closeInfoModal()
        if (res) {
            setProduct(res)
            setCodeVisible(true)
        }
        else {
            setPaymentModal(true)
        }
    }

    const RePay = () => {
        try {
            setCodeVisible(false)
            setPaymentModal(true)
        } catch (error) {
        }
    }

    const checkProductSubscription = async (purchaserId, productId) => {
        const subscriptionsRef = collection(db, "subscriptions");
        const q = query(subscriptionsRef, where("purchaserId", "==", purchaserId), where("productId", "==", productId));
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                return false;
            } else {
                return querySnapshot.docs[0].data()
            }
        } catch (error) {
            console.error("Error checking subscription:", error);
            return false;
        }
    }
    const CancelPay = () => {
        setProduct(false)
        setinitModal(false)
        setCodeVisible(false)
        setCurrentProduct(null)
    }

    const handleReset = async (email) => {
        try {
            setAuthLoading(true)
            let res = await sendPasswordResetEmail(auth, email);
            setTimeout(() => {
                toast.success("Revisa tu correo")
                setAuthLoading(false)
            }, 1000);
        } catch (error) {
            console.log('error', error)
            setAuthLoading(false)
        }
    }

    return {
        initModal, setinitModal,
        paymentModal, setPaymentModal,
        currentProduct, setCurrentProduct,
        infoModal, setInfoModal,
        loading, setLoading,
        authLoading, setAuthLoading,
        codeVisible, setCodeVisible,
        prod,
        StartPayment,
        closeInfoModal,
        handleAuth,
        handleReset,
        CancelPay,
        RePay
    }
}


