import { createSlice } from '@reduxjs/toolkit';

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    type: ''
  },
  reducers: {
    setBuyTicket: (state, action) => {
      state.type = action.payload.type;
    },
  },
});


export function s_buy_tickets(state) {
  return {
    type: state.tickets.type
  }
}


export const {
  setBuyTicket,
} = ticketsSlice.actions;
export default ticketsSlice.reducer;
