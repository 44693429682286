import { useNavigate } from 'react-router-dom';
import {
  FlowIcon,
  MachIcon,
  OnepayIcon,
  VisaIcon,
  WebpayIcon,
} from '../../components/Icons';

import { useDispatch, useSelector } from 'react-redux';
import {
  s_payment_params,
  setPaymentMethod,
} from '../../reducer/PaymentReducer';
import { useEffect, useState } from 'react';
import { resources } from '../../network/resources';
import { useApi } from '../../network/hooks/useApi';
// import { ampli } from '../../ampli';
import NewCard from '../../components/NewCard';
import { ArrowButton } from '../../components/NewButtons';

import './Payment.css';
import { UserDataModal } from '../../components/CardDataModal';
import {
  TRACK_PAY_4_REPRO,
  TRACK_PAY_CARD,
  TRACK_PAY_DIRECT_PAY,
  TRACK_PAY_MONTHLY,
} from '../../utils/trackingNames';
import ReactGA from 'react-ga4';
import config from '../../config';
import { login, s_session_token } from '../../reducer/SessionReducer';
import { SUCCESS } from '../../utils/Routes';
import ErrorModal from '../../components/ErrorModal';
import { Steps } from '../../components/Landing/Steps';


const Cards = [
  { title: 'Pagar con Flow', detail: '(Sólo aplica a tarjetas chilenas)' },
];

async function noOTPAuth(name, email, phone, slug_field) {
  const body = {
    first_name: name,
    email: email,
    phone: phone,
    slug_field: slug_field
  }
  const result = await fetch(config.API_URL + '/auth/email-login-no-otp/', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return result.json()
}

function PaymentMethod() {
  const dispatch = useDispatch();
  const [authLoading, setAuthLoading] = useState(false);
  const [ndLoading, setndLoading] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [option, setOption] = useState(0)
  const [errorModal, setErrorModal] = useState(false)

  const {
    creatorId,
    slugField,
    paymentMethod,
    paymentOption,
    creatorName,
    creator_photo,
    content_title,
    content_description,
    purchaseCode,
    option_description,
    price,
    inner_photo,
  } = useSelector(s_payment_params);

  const {
    asyncFetch: asyncFetchFlowPay,
    loading: flowPayloading,
    error: flowPayerror,
  } = useApi(resources['pay'].replace(':purchaseCode', purchaseCode).replace(':type', 'stripe'), {
    method: 'GET',
    auto: false,
  });
  const {
    asyncFetch: asyncFetchAddCard,
    loading: loadingAddCard,
    error: errorAddCard,
  } = useApi(
    resources['addCreditCard'].replace(':purchaseCode', purchaseCode),
    {
      method: 'GET',
      auto: false,
    }
  );

  const handleAuth = async (name, email, phone) => {
    try {
      setAuthLoading(true)
      const result = await noOTPAuth(name, email, phone, slugField)
      //console.log('[authNoOTP] result ', result)
      if (result.success) {
        dispatch(login({
          accessToken: result.tokens.access,
          refreshToken: result.tokens.refresh,
          email: email,
          userId: result.user_id
        }));
        onInfoModalContinue(result.tokens.access, name)
        //navigate(`/${creatorName}/payment-success/${slugField}`)
      } else {
        setErrorModal(true)
      }
    } catch (e) {
      console.error('[HANDLE AUTH NO OTP]', e)
    } finally {
      setAuthLoading(false)
    }
  }

  const handleContinuar = async (token) => {
    ReactGA.event('preselection_payment_method', {
      event_name: 'preselection_payment_method',
      creator_name: creatorName,
      creator_id: creatorId,
      item_name: slugField,
      item_selected: TRACK_PAY_DIRECT_PAY,
    });

    // ampli.preSelectionPaymentMethod({
    //   selected: 0,
    //   name: slugField,
    //   creator_id: creatorId,
    //   creator_name: creatorName,
    //   option: TRACK_PAY_DIRECT_PAY,
    // });

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
    const reply = await asyncFetchFlowPay(undefined, undefined, headers);
    dispatch(setPaymentMethod(TRACK_PAY_DIRECT_PAY));

    // if (reply) {
    //   //redirect
    //   ampli.paymentMethod({
    //     selected: 0,
    //     name: slugField,
    //     creator_id: creatorId,
    //     creator_name: creatorName,
    //     option: TRACK_PAY_DIRECT_PAY,
    //   });
    //   ampli
    //     .paymentOption({
    //       selected: paymentOption,
    //       name: slugField,
    //       creator_id: creatorId,
    //       creator_name: creatorName,
    //       option: paymentOption === 0 ? TRACK_PAY_4_REPRO : TRACK_PAY_MONTHLY,
    //     })
    //     .promise.then(window.location.replace(reply.url));
    // }
  };

  const addCardHandler = async (token, cardName) => {
    ReactGA.event('preselection_payment_method', {
      event_name: 'preselection_payment_method',
      creator_name: creatorName,
      creator_id: creatorId,
      item_name: slugField,
      item_selected: TRACK_PAY_CARD,
    });
    // ampli.preSelectionPaymentMethod({
    //   selected: 1,
    //   name: slugField,
    //   creator_id: creatorId,
    //   creator_name: creatorName,
    //   option: TRACK_PAY_CARD,
    // });
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
    //const reply = await asyncFetchAddCard(undefined, `/${cardName}/${purchaseCode}`, headers);
    const reply = await asyncFetchAddCard(undefined, undefined, headers);
    dispatch(setPaymentMethod(TRACK_PAY_CARD));

    console.log('reply card', reply)
    if (reply) {
      //window.location.replace(reply.url)
      const stripe = window.Stripe(reply.publishable_key)
      stripe.confirmPayment({
        clientSecret: reply.client_secret,
        confirmParams: {
          return_url: 'https://dev.web.mintapp.info/'
        }
      })
      // ampli.paymentMethod({
      //   selected: 1,
      //   name: slugField,
      //   creator_id: creatorId,
      //   creator_name: creatorName,
      //   option: TRACK_PAY_CARD,
      // });
      // ampli
      //   .paymentOption({
      //     selected: paymentOption,
      //     name: slugField,
      //     creator_id: creatorId,
      //     creator_name: creatorName,
      //     option: paymentOption === 0 ? TRACK_PAY_4_REPRO : TRACK_PAY_MONTHLY,
      //   })
      //.promise.then(window.location.replace(reply.url));
    }
  };

  const OnPay1 = async (token) => {
    setndLoading(true);
    await handleContinuar(token);
  };

  const showInfoModal = (op) => {
    setOption(op)
    setInfoModal(true)
  }

  const onInfoModalContinue = (token, cardName) => {
    setInfoModal(false)
    if (option === 0) {
      addCardHandler(token, cardName);
    } else {
      OnPay1(token)
    }
  }

  const dataCard = {
    name: creatorName,
    photo: creator_photo,
    id: creatorId,
    title: content_title,
    description: content_description,
    inner_photo: inner_photo,
  };

  const Option = ({ title, text, onClick, loading, className }) => {
    return (
      <div className={`w-full flex mt-5 ${className}`}>
        <div className='grid grid-cols-2 w-full '>
          <div className='text-base font-interBold col-span-2 leading-6'>
            {title}
          </div>
          {/* <div className='flex justify-end items-start'>
            <div className='w-20'>
              <FlowIcon />
            </div>
          </div> */}
          {text && (
            <p className='col-span-3 text-[.7rem] font-interRegular'>{text}</p>
          )}
        </div>
        <div className='ml-2'>
          <ArrowButton loading={loading} onClick={onClick} />
        </div>
      </div>
    );
  };

  return (
    <div className='bg-white h-screen'>
      <NewCard data={dataCard} />
      <div className='pl-8 pr-8 pb-8 md:pb-0'>
        <Steps selected={1} />
        <h2 className='text-xl font-interRegular font-bold'>Medio de Pago</h2>
        <div className='mt-2'>
          {/*TODO wait for backend to change text*/}
          <p className='w-full h-[4vh] rounded-md bg-[#D9D9D9] flex font-interMedium items-center text-xs pl-4 '>
            {`${option_description} $ ${price} CLP`}
          </p>
          <Option
            className='pb-[3vh] border-b border-[#D9D9D9]'
            title={'Pagar y registrar tarjeta '}
            text={'¡Facilita tus próximos pagos!'}
            loading={flowPayloading && !ndLoading}
            onClick={() => showInfoModal(0)}
          />
          <div className='text-base font-interBold col-span-2 leading-6 mt-[2vh]'>
            Pagar <u>sin</u> registrar tarjeta
          </div>
          <div onClick={() => showInfoModal(1)}
            className='border border-[#D9D9D9] flex items-center py-2 justify-between rounded-md mt-[2vh]'>
            <div className='flex justify-between w-[80%]'>
              <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                <WebpayIcon />
              </div>
              <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                <MachIcon />
              </div>
              <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                <OnepayIcon />
              </div>
            </div>
            <div className='flex flex-col self-center mr-1'>
              <ArrowButton loading={flowPayloading && ndLoading} />
            </div>
          </div>
        </div>
      </div>
      {infoModal && (
        <UserDataModal onContinue={handleAuth} onClose={() => setInfoModal(false)} loading={authLoading} />
      )}
      {errorModal && <ErrorModal onClose={() => setErrorModal(false)} />}
    </div>
  );
}

export default PaymentMethod;
