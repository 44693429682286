import { BasicButton } from "../../../../components/NewButtons";

export default function Success({ onContinue }) {
  return (
    <div>
      <div className="font-bold text-xl">¡Compra exitosa!</div>
      <div className="my-6">
        Tu numero de seguimiento es #3453623. Enviamos la información de tu compra a tu correo y WhatsApp.
      </div>
      <div className="italic text-sm mb-6">
        En caso de dudas contáctanos haciendo <u>click aquí.</u>
      </div>
      <BasicButton onClick={onContinue}>
        Ver detalle de compra
      </BasicButton>
    </div>
  )
}