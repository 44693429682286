import { useState } from 'react';

import { Input, InputGrey, InputPhone, InputUnderline } from './NewInputs';
import { ButtonGrey } from './NewButtons';
import { str_not_empty } from '../utils';
import useHeight from '../utils/height';

import './CardDataModal.css';




export function PaymentModal({ onClose, children, }) {
    const { height } = useHeight()

    const handleClose = (e) => {
        if (onClose && e.target === document.getElementById('card-modal')) {
            onClose();
        }
    };

    const handleContinue = async () => {

        // onContinue()
    }

    return (
        <div className='modal-background z-[3000]' style={{ height }} onClick={handleClose} id="card-modal">
            <div className='modal-container bg-white rounded-2xl mx-10 font-interRegular'>
                <div className='modal-content p-6 text-sm'>
                    {children}
                </div>
            </div>
        </div>
    )
}