// /* tslint:disable */
// /* eslint-disable */
// // @ts-nocheck
// /**
//  * Ampli - A strong typed wrapper for your Analytics
//  *
//  * This file is generated by Amplitude.
//  * To update run 'ampli pull mint-app-web'
//  *
//  * Required dependencies: @amplitude/analytics-browser@^1.3.0
//  * Tracking Plan Version: 2
//  * Build: 1.0.0
//  * Runtime: browser:javascript-ampli-v2
//  *
//  * [View Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest)
//  *
//  * [Full Setup Instructions](https://data.amplitude.com/mint-app/Mint%20App%20/implementation/mint-app-web)
//  */

// import * as amplitude from '@amplitude/analytics-browser';

// /**
//  * @typedef BaseEvent
//  * @type {object}
//  * @property {string} event_type
//  * @property {Object.<string, *>} [event_properties]
//  */

// /**
//  * @typedef Plan
//  * @type {object}
//  * @property {string} [branch]
//  * @property {string} [source]
//  * @property {string} [version]
//  * @property {string} [versionId]
//  */

// /**
//  * @typedef LoadClientOptions
//  * @type {object}
//  * @property {string} [apiKey]
//  * @property {Object} [configuration]
//  * @property {BrowserClient} [instance]
//  */

// /**
//  * @typedef LoadOptions
//  * @type {object}
//  * @property {'development'|'production'} [environment]
//  * @property {boolean} [disabled]
//  * @property {LoadClientOptions} [client]
//  */

// /**
//  * @typedef {Object} EventOptions
//  * @type {object}
//  */

// /**
//  * @typedef ApiKey
//  * @type {object}
//  * @property {string} development
//  * @property {string} production
//  */
// export const ApiKey = {
//   development: '',
//   production: ''
// };

// /**
//  * Default Amplitude configuration options. Contains tracking plan information.
//  */
// export const DefaultConfiguration = {
//   plan: {
//     version: '2',
//     branch: 'main',
//     source: 'mint-app-web',
//     versionId: 'faec12ad-6a57-459d-9d67-e943860b5e45'
//   },
//   ...{
//     ingestionMetadata: {
//       sourceName: 'browser-javascript-ampli',
//       sourceVersion: '2.0.0'
//     }
//   }
// };

// export class AppClosed {
//   constructor(properties) {
//     this.event_type = 'Web App closed';
//     this.event_properties = properties;
//   }
// }

// export class AppOpened {
//   constructor(properties) {
//     this.event_type = 'Web App opened';
//     this.event_properties = properties;
//   }
// }

// export class AppOpenedByArtist {
//   constructor(properties) {
//     this.event_type = 'App opened by artist';
//     this.event_properties = properties;
//   }
// }

// export class ButtonClicked {
//   constructor(properties) {
//     this.event_type = 'button_clicked';
//     this.event_properties = properties;
//   }
// }

// export class GoBack {
//   constructor(properties) {
//     this.event_type = 'Go back';
//     this.event_properties = properties;
//   }
// }

// export class Login {
//   constructor(properties) {
//     this.event_type = 'Login';
//     this.event_properties = properties;
//   }
// }

// export class Logout {
//   constructor() {
//     this.event_type = 'Logout';
//   }
// }

// export class Paid {
//   constructor(properties) {
//     this.event_type = 'Paid';
//     this.event_properties = properties;
//   }
// }

// export class PauseVideo {
//   constructor(properties) {
//     this.event_type = 'Pause video';
//     this.event_properties = properties;
//   }
// }

// export class Payment {
//   constructor() {
//     this.event_type = 'Payment';
//   }
// }

// export class PaymentMethod {
//   constructor(properties) {
//     this.event_type = 'Payment method';
//     this.event_properties = properties;
//   }
// }

// export class PaymentOption {
//   constructor(properties) {
//     this.event_type = 'Payment option';
//     this.event_properties = properties;
//   }
// }

// export class PreSelectionPaymentMethod {
//   constructor(properties) {
//     this.event_type = 'Pre-selection payment method';
//     this.event_properties = properties;
//   }
// }

// export class PreSelectionPaymentOption {
//   constructor(properties) {
//     this.event_type = 'Pre-selection payment option';
//     this.event_properties = properties;
//   }
// }

// export class Presale {
//   constructor(properties) {
//     this.event_type = 'Presale';
//     this.event_properties = properties;
//   }
// }

// export class ProfileCreator {
//   constructor(properties) {
//     this.event_type = 'Profile creator';
//     this.event_properties = properties;
//   }
// }

// export class Promo {
//   constructor(properties) {
//     this.event_type = 'Promo';
//     this.event_properties = properties;
//   }
// }

// export class SeeImage {
//   constructor(properties) {
//     this.event_type = 'See image';
//     this.event_properties = properties;
//   }
// }

// export class SongListened {
//   constructor(properties) {
//     this.event_type = 'Song listened';
//     this.event_properties = properties;
//   }
// }

// export class SongListened15Seg {
//   constructor(properties) {
//     this.event_type = 'Song listened (15seg)';
//     this.event_properties = properties;
//   }
// }

// export class SongListened30Seg {
//   constructor(properties) {
//     this.event_type = 'Song listened (30seg)';
//     this.event_properties = properties;
//   }
// }

// export class SongPlayed {
//   constructor(properties) {
//     this.event_type = 'Song played';
//     this.event_properties = properties;
//   }
// }

// export class Store {
//   constructor(properties) {
//     this.event_type = 'Store';
//     this.event_properties = properties;
//   }
// }

// export class Test {
//   constructor() {
//     this.event_type = 'test';
//   }
// }

// export class TextViewed {
//   constructor(properties) {
//     this.event_type = 'Text Viewed';
//     this.event_properties = properties;
//   }
// }

// export class VideoLoad {
//   constructor(properties) {
//     this.event_type = 'Video load';
//     this.event_properties = properties;
//   }
// }

// export class WatchingVideo {
//   constructor(properties) {
//     this.event_type = 'Watching video';
//     this.event_properties = properties;
//   }
// }

// /**
//  * @typedef PromiseResult
//  * @type {object}
//  * @property {Promise|undefined} promise
//  */

// const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// // prettier-ignore
// export class Ampli {
//   constructor() {
//     /* @type {BrowserClient|undefined} */
//     this.amplitude = undefined;
//     this.disabled = false;
//   }

//   /**
//    * @return {BrowserClient}
//    */
//   get client() {
//     this.isInitializedAndEnabled();
//     return this.amplitude;
//   }

//   /**
//    * @return {boolean}
//    */
//   get isLoaded() {
//     return this.amplitude != null;
//   }

//   /**
//    * @private
//    * @return {boolean}
//    */
//   isInitializedAndEnabled() {
//     if (!this.isLoaded) {
//       console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
//       return false;
//     }
//     return !this.disabled;
//   }

//   /**
//    * Initialize the Ampli SDK. Call once when your application starts.
//    *
//    * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
//    *
//    * @return {PromiseResult}
//    */
//   load(options) {
//     this.disabled = options?.disabled ?? false;

//     if (this.isLoaded) {
//       console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
//       return getVoidPromiseResult();
//     }

//     let apiKey;
//     if (options?.client?.apiKey) {
//       apiKey = options.client.apiKey;
//     } else if (options?.environment) {
//       apiKey = ApiKey[options.environment];
//     }

//     if (options?.client?.instance) {
//       this.amplitude = options?.client?.instance;
//     } else if (apiKey) {
//       this.amplitude = amplitude.createInstance();
//       return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
//     } else {
//       console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
//     }

//     return getVoidPromiseResult();
//   }

//   /**
//    * Identify a user.
//    *
//    * @param {string|undefined} userId The user's id.
//    * @param {EventOptions} [options] Optional event options.
//    *
//    * @return {PromiseResult}
//    */
//   identify(userId, options) {
//     if (!this.isInitializedAndEnabled()) {
//       return getVoidPromiseResult();
//     }

//     if (userId) {
//       options = {...options, user_id: userId};
//     }

//     const ampIdentify = new amplitude.Identify();
//     return this.amplitude.identify(ampIdentify, options);
//   }

//   /**
//    * Track event
//    *
//    * @param {BaseEvent} event The event to track.
//    * @param {EventOptions} [options] Optional event options.
//    *
//    * @return {PromiseResult}
//    */
//   track(event, options) {
//     if (!this.isInitializedAndEnabled()) {
//       return getVoidPromiseResult();
//     }

//     return this.amplitude.track(event, undefined, options);
//   }

//   /**
//    * App closed
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/App%20closed)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   appClosed(properties, options) {
//     return this.track(new AppClosed(properties), options);
//   }

//   /**
//    * App opened
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/App%20opened)
//    *
//    * when app is opened from background or the first time
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.status] app status
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   appOpened(properties, options) {
//     return this.track(new AppOpened(properties), options);
//   }

//   /**
//    * App opened by artist
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/App%20opened%20by%20artist)
//    *
// Event has no description in tracking plan.
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   appOpenedByArtist(properties, options) {
//     return this.track(new AppOpenedByArtist(properties), options);
//   }

//   /**
//    * button_clicked
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/button_clicked)
//    *
// Event has no description in tracking plan.
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {string} [properties.button] any kind of button
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   buttonClicked(properties, options) {
//     return this.track(new ButtonClicked(properties), options);
//   }

//   /**
//    * Go back
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Go%20back)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.section] section of profile creator
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   goBack(properties, options) {
//     return this.track(new GoBack(properties), options);
//   }

//   /**
//    * Login
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Login)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.login_method] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   login(properties, options) {
//     return this.track(new Login(properties), options);
//   }

//   /**
//    * Logout
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Logout)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   logout(options) {
//     return this.track(new Logout(), options);
//   }

//   /**
//    * Paid
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Paid)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.payment_method] Property has no description in tracking plan.
//    * @param {string} [properties.payment_option] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   paid(properties, options) {
//     return this.track(new Paid(properties), options);
//   }

//   /**
//    * Pause video
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Pause%20video)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {number} [properties.percentage] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   pauseVideo(properties, options) {
//     return this.track(new PauseVideo(properties), options);
//   }

//   /**
//    * Payment
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Payment)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   payment(options) {
//     return this.track(new Payment(), options);
//   }

//   /**
//    * Payment method
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Payment%20method)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.option] Property has no description in tracking plan.
//    * @param {number} [properties.selected] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   paymentMethod(properties, options) {
//     return this.track(new PaymentMethod(properties), options);
//   }

//   /**
//    * Payment option
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Payment%20option)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.option] Property has no description in tracking plan.
//    * @param {number} [properties.selected] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   paymentOption(properties, options) {
//     return this.track(new PaymentOption(properties), options);
//   }

//   /**
//    * Pre-selection payment method
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Pre-selection%20payment%20method)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.code] Property has no description in tracking plan.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.option] Property has no description in tracking plan.
//    * @param {number} [properties.selected] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   preSelectionPaymentMethod(properties, options) {
//     return this.track(new PreSelectionPaymentMethod(properties), options);
//   }

//   /**
//    * Pre-selection payment option
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Pre-selection%20payment%20option)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.code] Property has no description in tracking plan.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.option] Property has no description in tracking plan.
//    * @param {number} [properties.selected] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   preSelectionPaymentOption(properties, options) {
//     return this.track(new PreSelectionPaymentOption(properties), options);
//   }

//   /**
//    * Presale
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Presale)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {string} [properties.button] Property has no description in tracking plan.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   presale(properties, options) {
//     return this.track(new Presale(properties), options);
//   }

//   /**
//    * Profile creator
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Profile%20creator)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Property has no description in tracking plan.
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   profileCreator(properties, options) {
//     return this.track(new ProfileCreator(properties), options);
//   }

//   /**
//    * Promo
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Promo)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {string} [properties.button] any kind of button
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   promo(properties, options) {
//     return this.track(new Promo(properties), options);
//   }

//   /**
//    * See image
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/See%20image)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.section] section of profile creator
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   seeImage(properties, options) {
//     return this.track(new SeeImage(properties), options);
//   }

//   /**
//    * Song listened
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Song%20listened)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {number} [properties.seconds] Property has no description in tracking plan.
//    * @param {string} [properties.user] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   songListened(properties, options) {
//     return this.track(new SongListened(properties), options);
//   }

//   /**
//    * Song listened (15seg)
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Song%20listened%20(15seg))
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.user] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   songListened15Seg(properties, options) {
//     return this.track(new SongListened15Seg(properties), options);
//   }

//   /**
//    * Song listened (30seg)
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Song%20listened%20(30seg))
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.user] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   songListened30Seg(properties, options) {
//     return this.track(new SongListened30Seg(properties), options);
//   }

//   /**
//    * Song played
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Song%20played)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.section] section of profile creator
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   songPlayed(properties, options) {
//     return this.track(new SongPlayed(properties), options);
//   }

//   /**
//    * Store
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Store)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {string} [properties.button] any kind of button
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   store(properties, options) {
//     return this.track(new Store(properties), options);
//   }

//   /**
//    * test
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/test)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   test(options) {
//     return this.track(new Test(), options);
//   }

//   /**
//    * Text Viewed
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Text%20Viewed)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.section] section of profile creator
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   textViewed(properties, options) {
//     return this.track(new TextViewed(properties), options);
//   }

//   /**
//    * Video load
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Video%20load)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   videoLoad(properties, options) {
//     return this.track(new VideoLoad(properties), options);
//   }

//   /**
//    * Watching video
//    *
//    * [View in Tracking Plan](https://data.amplitude.com/mint-app/Mint%20App%20/events/main/latest/Watching%20video)
//    *
//    * Owner: Javier Andres Perez Urrutia
//    *
//    * @param {Object} [properties] The event's properties.
//    * @param {number} [properties.creator_id] Creator id
//    * @param {string} [properties.creator_name] Property has no description in tracking plan.
//    * @param {string} [properties.name] Property has no description in tracking plan.
//    * @param {string} [properties.section] Property has no description in tracking plan.
//    * @param {EventOptions} [options] Options for this track call.
//    */
//   watchingVideo(properties, options) {
//     return this.track(new WatchingVideo(properties), options);
//   }
// }

// export const ampli = new Ampli();


import * as amplitude from '@amplitude/analytics-browser';
import config from '../config';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

// const sessionReplayTracking = sessionReplayPlugin();
// amplitude.add(sessionReplayTracking);
// amplitude.init(config.API_AMPLITUDE);

const logLoginAttempt = () => amplitude.logEvent('Web Login Attempt');
const logPurchaseCompleted = () => amplitude.logEvent('Web Purchase Completed');

const logSignUp = (email, uId) => {
  const loginTime = new Date().toUTCString()
  amplitude.setUserId(email)
  amplitude.setSessionId(Date.now());
  amplitude.track('Web Sign Up', { email, uId, timestamp: loginTime });
}
const load = () => {
  const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
  amplitude.init(config.API_AMPLITUDE);
  amplitude.add(sessionReplayTracking);
  amplitude.track('Web App Opened');
  amplitude.setGroup('web');
}
const logUserLogin = (email, uId) => {
  const loginTime = new Date().toUTCString()
  amplitude.setUserId(email)
  amplitude.setSessionId(Date.now());
  amplitude.track('Web User Login', { email, uId, timestamp: loginTime });
}

const logAppOpened = () => {
  amplitude.track('Web App Opened');
};

const logAppClosed = () => {
  amplitude.logEvent('Web App Closed');
};

const track = (event, options) => {
  amplitude.track(event, options);
}

const creatorProfile = (options) => {
  amplitude.track('Web Creator Profile', options);
}

const payment = (options) => {
  amplitude.track('Web Payment', options);
}


const ampli = {
  logAppOpened,
  logAppClosed,
  logLoginAttempt,
  logPurchaseCompleted,
  logSignUp,
  load,
  logUserLogin,
  track,
  payment,
  creatorProfile,
};

export default ampli;


