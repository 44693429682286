import { Link } from 'react-router-dom';
import { IoChevronBackOutline } from 'react-icons/io5';
import './Tienda.css';
import {
  AudioVideoIcon,
  ImagesIcon,
  MerchIcon,
  OnlineShowIcon,
  TicketsIcon,
  VideoCallIcon,
} from '../../../../components/Icons';

const menu = [
  {
    label: 'Tickets',
    icon: <TicketsIcon />,
    route: '../store/tickets',
  },
  {
    label: 'Videollamadas',
    icon: <VideoCallIcon />,
    route: '../store/videocalls',
  },
  // {
  //   label: 'Imagenes',
  //   icon: <ImagesIcon medium />,
  //   route: '../store/images',
  // },
  // {
  //   label: 'Audios y Videos',
  //   icon: <AudioVideoIcon medium />,
  //   route: '/..store/audiosVideos',
  // },
  {
    label: 'Show en Línea',
    icon: <OnlineShowIcon />,
    route: '../store/onlineShows',
  },
  {
    label: 'Merchandising',
    icon: <MerchIcon />,
    route: '../store/merchandising',
  },
];

function Tienda() {
  return (
    <div className='text-center gradient-bg store-create-menu'>
      <Link className='store-back' to={'/create'}>
        <IoChevronBackOutline className='icon' />
      </Link>
      {menu.map((item) => (
        <Link key={item.route} to={item.route} className='store-menu-button'>
          {item.icon}
          {item.label}
        </Link>
      ))}
    </div>
  );
}

export default Tienda;
