import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";


import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import { SwiperFeedsPath, paymentMethodPath, paymentSelectionPath, playerPath, videoPlayerPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import Descriptions from "../../components/Landing/Descriptions";
import VideoBackground from "../../components/Landing/VideoBackground";
import { BackIcon, FlipHorizontal, HorizontalVideoIcon, MenuDotsIcon, Rotate } from "../../components/Icons";
import PlayButton from "../../components/Landing/PlayButton";
import ExpiredButton from "../../components/Landing/ExpiredButton";
import { useDispatch } from "react-redux";
import { setCreatorId, setPaymentOption } from "../../reducer/PaymentReducer";
import OrientationDetector from "../../utils/getOrientation";
import { FooterInfo, VideoDescriptions } from "../../components/Landing";

const OPTIONS = { auto: false }

function VideoPlayerPpv() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { name, slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [isVertical, setVertical] = useState(true);
  const [reproductions, setReproductions] = useState(null);
  const routeSelection = paymentMethodPath(name, slug);
  const [currentTime, setCurrentTime] = useState(0)
  const profile = playerPath(name, slug)
  const swiperProfile = SwiperFeedsPath(name, slug)
  const { orientation } = OrientationDetector()

  const { asyncFetch: fetchData, data } = useApi(resources['singleCreator'].replace(':id', name), OPTIONS);
  const { asyncFetch: fetchReproductions } = useApi(resources['getReproductions'], OPTIONS);
  const { asyncFetch: fetchCheckout } = useApi(resources['checkout'].replace(':slug', slug), OPTIONS);
  const { data: dataList, loading: isLoading } = useApi(resources['searchBySlug'].replace(':slug', slug));
  const {
    asyncFetch: fetchUseReproduction,
    loading: loadingUseReproduction
  } = useApi(resources['useReproductions'].replace(':slug', slug), OPTIONS);

  const refreshReproductions = async () => {
    const res = await fetchReproductions(undefined, slug);
    console.log('[fetchReproductions]', res);
    setReproductions(res);
  }

  const initialFetch = async () => {
    try {
      const res = await fetchData();
      if (res) {
        // await refreshReproductions()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    initialFetch()
  }, [])

  // const playAvailable = async () => {
  //   const res = await fetchCheckout()
  //   return res?.next_step === 'play'
  // }

  // const handleButton = async () => {
  //   const available = await playAvailable()
  //   if (available) {
  //     navigate(videoPlayerPath(name, slug))
  //   } else {
  //     //redirect to payment?
  //   }
  // }

  // const handleBuy = async () => {
  //   const res = await fetchCheckout()
  //   // console.log('[fetchCheckout]', res)
  //   if (!res) {
  //     return
  //   }
  //   if (res.next_step !== 'checkout') {
  //     window.location.reload();
  //   } else {
  //     const { price, purchase_code, name: description } = res.options[1];
  //     dispatch(setCreatorId({
  //       creatorId: data?.pk,
  //       creatorName: name,
  //       slugField: slug,
  //       creator_photo: data?.photo,
  //       inner_photo: data.inner_photo,
  //       content_title: video?.title,
  //       content_description: video?.description,
  //     }));
  //     dispatch(setPaymentOption({
  //       option: 1,
  //       purchaseCode: purchase_code,
  //       price: price,
  //       option_description: description,
  //     }));
  //     navigate(routeSelection);
  //   }
  // }

  // const video = data?.videos && data?.videos[1]
  const video = dataList?.data
  // const expired = (reproductions?.remaining_reproductions === 0 || reproductions?.subscription_type === 'none')

  // const decreaseReproduction = async () => {
  //   console.log('[decreaseReproduction]', currentTime)
  //   await fetchUseReproduction()
  //   await refreshReproductions(slug)
  // }

  // useEffect(() => {
  //   // decrease only for ppv
  //   if (reproductions?.subscription_type === 'ppv' && currentTime === 15 && !loadingUseReproduction) {
  //     decreaseReproduction()
  //   }
  // }, [currentTime]);


  if (isLoading) {
    return <CenterSpinner />
  }

  const Vertical = () => {
    return (
      <div>
        <div className="absolute top-0 right-0 left-0">
          <Header onClick={() => { navigate(swiperProfile) }} />
        </div>
        <FooterInfo
          name={data?.user?.first_name}
          title={video?.title}
          description={video?.description}
        />
      </div>
    )
  }

  const auto = !isVertical
  return (
    <div
      className={`${auto && 'absolute top-0 left-0 overflow-hidden w-[100dvh] h-[100dvw]'}  `}
      style={{ zIndex: 100, transform: auto && 'rotate(90deg) translateY(-100%)', transformOrigin: auto && 'top left' }}>
      <VideoBackground
        // onClick={handleButton}
        src={video?.file_short_version} img={data?.photo} />
      <Vertical />
    </div>
  );
}

const Header = ({ onClick, onFlip }) => (
  <div style={{ zIndex: 10000 }} className="p-6 flex justify-between">
    <div style={{ zIndex: 10000 }} onClick={onClick}>
      <BackIcon />
    </div>
    {/* <button style={{ zIndex: 10000 }} onClick={onFlip}>
      <Rotate />
    </button> */}
  </div>
)

export default VideoPlayerPpv