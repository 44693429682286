import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Create from '../screens/Create';
import { Tickets, Audios2, Videos2, Merchandising } from '../screens/Create/screens';

import {
  CREATE,
  HORIZONTAL_FEED,
  PAYMENT,
  ROOT,
  ROUTE_CONFIGURATION,
  ROUTE_MIS_COMPRAS,
  STORE,
  TRANSACTION_HISTORY,
  WELCOME,
} from '../utils/Routes';
import ProtectedRoutes from './ProtectedRoutes';
import {
  PaymentMethod,
  PaymentSuccess,
  PaymentUnsuccessfull,
  PaymentSelection,
} from '../screens/Payment';
import TransactionHistory from '../screens/Menu/TransactionHistory';
import NewHome from '../screens/Home';
import AutoLogin from '../screens/Payment/Autologin';
import Privacy from '../screens/Payment/Privacy';
import TermsAndConditions from '../screens/Payment/TermsAndConditions';
import PaymentLoginV3 from '../screens/Payment/PaymentLoginV3';
import UploadSuccess from '../screens/Create/screens/UploadSuccess';
import Welcome from '../screens/Create/Welcome';
import HorizontalFeed from '../screens/HorizontalFeed';

import ProfileLanding from '../screens/Landing'
import { BuyTicketsNew, PreviewFeeds, ProfileTickets, QRScanner } from '../screens';
import { Success } from '../screens/Store/screens/Success';
import SongPreview from '../screens/Landing/SongPreview';
import SongPlayer from '../screens/Landing/SongPlayer';
import ProfileSwiper from '../screens/swiperProfile/swiper';
import SongPlayerMonthly from '../screens/Landing/SongPlayerMonthly';
import VerticalFeeds from '../screens/swiperProfile';
import VideoLanding from '../screens/Landing/VideoLanding';
import VideoPreview from '../screens/Landing/VideoPreview';
import VideoPlayerPpv from '../screens/Landing/VideoPlayerPpv';
import ErrorState from '../ErrorState';
import VideoPlayer from '../screens/Landing/VideoPlayer';
import Login from '../screens/Login';
import VideoPlayerMonthly from '../screens/Landing/VideoPlayerMonthly';
import UnSuccess from '../screens/Store/screens/UnSuccess';
import CountdownEvent from '../screens/Profile/screens/Tickets/CountdownEvent';
import OnlinieTickets from '../screens/Store/screens/Tickets/OnlineTickets';
import MerchLanding from '../screens/Landing/MerchLanding';
import Merch from '../screens/Store/screens/Merch';
import MerchCheckout from '../screens/Store/screens/Merch/MerchCheckout';
import MisCompras from '../screens/Profile/MisCompras';
import SongPurchased from '../screens/Landing/SongPurchased';
import VideoPurchased from '../screens/Landing/VideoPurchased';
import CreatorProfile from '../screens/Profile/CreatorProfile';
import MerchVoucher from '../screens/Store/screens/Merch/MerchVoucher';
import Configuration from '../screens/Profile/Configuration';
import CodePurchased from '../screens/Landing/CodePurchased';
import Home from '../screens/Home';

function MainRoute() {
  return (
    <Routes>
      <Route path={ROOT}>
        <Route path='/previews' element={<PreviewFeeds />} />
        <Route path='/privacidad' element={<Privacy />} />
        <Route path='/terminos' element={<TermsAndConditions />} />
        <Route path='/error' element={<ErrorState />} />
        <Route index element={<ProfileSwiper />} />
        <Route path='/payment/unsuccessfull/:slug' element={<PaymentUnsuccessfull />} />
        <Route path=':name'>
          <Route index element={<ProfileSwiper />} />
          <Route path='landing/:slug' element={<ProfileSwiper component={<ProfileLanding />} />} />
          <Route path=':slug' element={<ProfileSwiper />} />
          <Route path="preview/:slug" element={<ProfileSwiper />} />
          <Route path="landing/video/:slug" element={<VideoLanding />} />
          <Route path=':Shisosaloud&Amigos' element={<ProfileTickets />} />
          <Route path='tickets' element={<ProfileTickets />} />
          <Route path='merch' element={<MerchLanding />} />
          <Route path="profile" element={<CreatorProfile />} />
          <Route path='plans/:slug' element={<PaymentSelection />} />
          <Route path='payment-method/:slug' element={<PaymentMethod />} />
          <Route path='payment-success/:slug' element={<PaymentSuccess />} />
          <Route path='code/purchased/:slug' element={<CodePurchased />} />
          <Route element={<ProtectedRoutes />}>
            {/* new profile */}
            <Route path="audio/:slug" element={<SongPurchased />} />
            <Route path="video/:slug" element={<VideoPurchased />} />
            <Route path="player/monthly/:slug" element={<SongPlayerMonthly />} />
            <Route path="player/:slug" element={<ProfileSwiper component={<SongPlayer />} />} />
            <Route path="player/monthly/:slug" element={<ProfileSwiper component={<SongPlayerMonthly />} />} />
            {/**************/}
            <Route
              path='payment-failure/:slug'
              element={<PaymentUnsuccessfull />}
            />
            <Route path="player/video/preview/:slug" element={<VideoPreview />} />
            <Route path="player/video/:slug" element={<VideoPlayerPpv />} />
            <Route path="player/video/monthly/:slug" element={<VideoPlayerMonthly />} />
            <Route path="player/video/play/:slug" element={<VideoPlayer />} />
            <Route path="merch/store" element={<Merch />} />
            <Route path="merch/store/checkout" element={<MerchCheckout />} />
            <Route path="merch/store/voucher" element={<MerchVoucher />} />
          </Route>
          <Route path={PAYMENT}>
            <Route index element={<Login />} />
            <Route element={<ProtectedRoutes />}>
              <Route path='selection' element={<Login />} />
              {/* <Route path='selection' element={<PaymentSelection />} /> */}
              <Route path='method' element={<PaymentMethod />} />
            </Route>
          </Route>
        </Route>
        <Route path='/auth/login' element={<Login />} />
        <Route path='/auth/autologin/:code' element={<AutoLogin />} />
        <Route path='/vertical/feeds' element={<VerticalFeeds />} />
        <Route path={HORIZONTAL_FEED} element={<HorizontalFeed />} />
        <Route path={ROUTE_MIS_COMPRAS} element={<MisCompras />} />
        <Route path={ROUTE_CONFIGURATION} element={<Configuration />} />
        <Route element={<ProtectedRoutes />}>
          <Route path={WELCOME} element={<Welcome />} />
          {/* <Route path='/:name/entradas' element={<BuyTickets />} /> */}
          <Route path='/:name/online/buy' element={<BuyTicketsNew online />} />
          <Route path='/:name/entradas' element={<BuyTicketsNew />} />
          <Route path='/:name/entradas/store/success' element={<Success />} />
          <Route path={STORE}>
            {/* <Route index element={<BuyTickets />} /> */}
            <Route index element={<BuyTicketsNew />} />
            <Route path='scanner' element={<QRScanner />} />
            <Route path='scanner/online' element={<OnlinieTickets />} />
            <Route path='countdown' element={<CountdownEvent />} />
            <Route path='success' element={<Success />} />
            <Route path='fail' element={<UnSuccess />} />
          </Route>
          <Route path={CREATE}>
            <Route index element={<Create />} />
            <Route path='audio' element={<Audios2 />} />
            <Route path='tickets' element={<Tickets />} />
            <Route path='online' element={<Tickets online />} />
            <Route path='video' element={<Videos2 />} />
            <Route path='merchandising' element={<Merchandising />} />
            <Route path='uploadsuccess' element={<UploadSuccess />} />
          </Route>
          <Route path='/payment/success/:route/:slug' element={<PaymentSuccess />} />
          {/* <Route path='/payment/fail' element={<PaymentSuccess />} /> */}
          {/* <Route path='/payment/unsuccessfull/:slug' element={<PaymentUnsuccessfull />} /> */}
          {/* <Route
            path='/payment/unsuccessfull'
            element={<PaymentUnsuccessfull />}
          /> */}
          <Route path={TRANSACTION_HISTORY} element={<TransactionHistory />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default MainRoute;