export * from './ImagesList'
export * from './AudioPlayer'
export * from './MusicCard'
export * from './AudioCoontrols'
export * from './paymentCard'
export * from './ProfileTitles'
export * from './DeleteModal'

/**
 * Horizontal separator
 * @param className
 * @returns 
 */
export const Separator = ({ className }) => <div className={`border-b border-[#D9D9D9] w-full ${className}`}></div>

/**
 * Vertical separator
 * @param className
 * @returns 
 */
export const VSeparator = ({ className, height = '40px' }) => <div className={`border-r border-[#D9D9D9] h-[${height}] ${className}`}></div>

/**
 * Label
 */
export const Label = ({ value }) => (
  <div className='text-[10px] bg-white text-black w-[6rem] text-center py-[.10rem] rounded-full'>{value}</div>
)