import { createSlice } from '@reduxjs/toolkit';

export const merchSlice = createSlice({
  name: 'merch',
  initialState: {
    products: []
  },
  reducers: {
    /** one product */
    addProduct: (state, action) => {
      const index = state.products.findIndex(v => v.id === action.payload.id)
      if (index !== -1) {
        const product = state.products[index]
        product.quantity += 1
        state.products[index] = product
      } else {
        state.products.push(action.payload);
      }
    },
    removeProduct: (state, action) => {
      const index = state.products.findIndex(v => v.id === action.payload.id)
      if (index !== -1) {
        const product = state.products[index]
        product.quantity -= 1
        state.products[index] = product
      }

      state.products = state.products.filter(v => v.quantity > 0)
    },
    /** array of products */
    addProducts(state, action) {
      const newProducts = action.payload
      
      for (let product of newProducts) {
        const exist = state.products.findIndex(v => v.id === product.id)
        if (exist != -1) {
          state.products[exist].quantity += product.quantity
        } else {
          state.products.push(product)
        }
      }
    }
  },
});


export function s_merch_products(state) {
  return state.merch.products
}


export const { addProduct, removeProduct, addProducts } = merchSlice.actions;
export default merchSlice.reducer;
