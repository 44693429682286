import { forwardRef } from 'react';
import en from "react-phone-number-input/locale/en.json";

import CountrySelect from './CountryPicker';

import './NewInputs.css';
import { VSeparator } from '.';


export function InputTransparent(props) {
  return (
    <input
      {...props}
      className={`bg-transparent border rounded-full h-12 px-4 input-transparent ${props.className}`}
    />
  );
}

export const SmallInput = forwardRef((props, ref) => (
  <input
    style={{ textAlign: 'center' }}
    className='rounded-xl w-14 h-14 text-black'
    {...props}
    ref={ref}
  />
));

export function Input(props) {
  return (
    <input
      {...props}
      className={`input-basic rounded-xl border-0 px-4 text-black h-10 focus:outline-none ${props.className}`}
    />
  );
}

export function InputTransparentPhone(props) {
  return (
    <div
      className={`flex items-center rounded-full bg-transparent border h-12 ${props.className}`}
    >
      <span className='pl-4 pr-2'>+56</span>
      <div className='border-l border-white h-8'></div>
      <Input
        max='9'
        type='number'
        {...props}
        className='bg-transparent rounded-full text-white'
      />
    </div>
  );
}

export function InputUnderline(props) {
  return (
    <input
      {...props}
      className={`bg-transparent border-0 text-black h-8 pb-5 pt-5 focus:outline-none border-b border-gray-300 w-full text-sm ${props.className}`}
    />
  );
}
export function InputSimple(props) {
  return (
    <input
      {...props}
      className={`bg-transparent border-0 text-black h-8 pb-3 pt-4 focus:outline-none border-gray-300 w-full text-sm ${props.className}`}
    />
  );
}
export function InputBordered(props) {
  return (
    <input
      {...props}
      className={`bg-transparent border border-[#D9D9D9] rounded-md text-black h-9 pl-2 focus:outline-none w-full text-[.85rem] my-1 ${props.className}`}
    />
  );
}

export function NewCheckbox({
  label,
  isChecked,
  setIsChecked,
  idCheckbox,
  subLabel,
  small,
}) {
  return (
    <div className='flex items-center'>
      <input
        type='checkbox'
        id={idCheckbox}
        className='peer hidden'
        onChange={() => setIsChecked((prev) => !prev)}
      />
      <label
        htmlFor={idCheckbox}
        className={`select-none cursor-pointer ${small ? 'w-4 h-4' : 'w-8 h-8'
          } font-bold transition-colors duration-200 ease-in-out bg-[#D9D9D9]`}
      >
        {isChecked && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className={`${small ? 'w-3 h-3' : 'w-6 h-6'
              } text-black fill-current`}
            viewBox='0 0 14 18'
          >
            <path
              fillRule='evenodd'
              d='M17.707 6.293a1 1 0 010 1.414l-9 9a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586l8.293-8.293a1 1 0 011.414 0z'
              clipRule='evenodd'
            />
          </svg>
        )}
      </label>

      {label && (
        <p className='text-[0.7rem] font-interRegular leading-3 w-64 pl-5'>
          {label}
          <br />
          <i className='text-[0.625rem] font-interRegular leading-3'>
            {subLabel}
          </i>
        </p>
      )}
    </div>
  );
}

export function UploadFile({
  label,
  handleFileChange,
  large,
  video,
  img,
  audio,
  className
}) {
  let acceptFileType = '';

  if (video) {
    acceptFileType = 'video/mp4';
  } else if (img) {
    acceptFileType = 'image/*,.heic';
  } else if (audio) {
    acceptFileType = 'audio/mp3';
  }

  return (
    <div
      className={`file-select ${large ? 'large' : 'small'} text-base ${className}`}
      data-content={label}
    >
      <input
        type='file'
        name='src-file1'
        onChange={handleFileChange}
        accept={acceptFileType}
      />
    </div>
  );
}

export function InputGrey(props) {
  return (
    <input
      {...props}
      className={`bg-[#F2F2F2] border-0 text-black h-8 py-5 px-4 rounded-lg focus:outline-none w-full text-sm ${props.className}`}
    />
  );
}

export function InputPhone(props) {
  return (
    <div className='flex items-center bg-[#F2F2F2] rounded-lg'>
      <CountrySelect
        labels={en}
        onChange={props?.onChangeCountry}
        name="country"
        value={props?.country}
        className="bg-transparent ml-2"
      />
      <VSeparator className="mx-2" height='20px' />
      <input
        {...props}
        className={`bg-transparent border-0 text-black h-8 py-5 px-4 focus:outline-none w-full text-sm ${props.className}`}
      />
    </div>
  );
}