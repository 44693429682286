import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Keyboard, Mousewheel, Pagination, Scrollbar, Virtual } from "swiper";
import { Keyboard, Mousewheel, Pagination, Scrollbar, Virtual } from "swiper/modules";
import { useParams } from "react-router-dom";
import './Swiper.css'
import { ProfileTickets } from "../Profile";
import { CenterSpinner } from "../../components/Spinner";
import { SongSlide, VideoSlide } from "./screens";
import AppModal from "../../components/AppModal";
import { HandleGotopp } from "../../utils";
import { useSelector } from "react-redux";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { LoadingLogo } from "../../components/Landing/LoadingLogo";

function ProfileSwiper({ }) {
    const { name, slug } = useParams();
    const name1 = useParams();
    const swiperRef = useRef(null)
    // const [feeds, setFeed] = useState(feeds)
    const [muted, setMuted] = useState(true)
    const [pressed, setPressed] = useState(true)
    const [loading, setLoading] = useState(true)
    const feeds_redux = useSelector(state => state.feeds)
    //const [creatorData, setCreatorData] = useState([])
    //const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [isTrue, setIsTrue] = useState(false);
    const [feeds, setFeeds] = useState(feeds_redux?.feeds);

    useEffect(() => {
        // Check local storage for the value when the component mounts
        const storedValue = localStorage.getItem('isTrue');
        if (storedValue === 'true') {
            setIsTrue(true);
        }
    }, []);

    const handleChange = () => {
        setIsTrue((prev) => {
            const newValue = !prev;
            // Update local storage with the new value
            localStorage.setItem('isTrue', newValue);
            return newValue;
        });
    };

    useEffect(() => {
        setTimeout(() => {
            setModal(true)
        }, 1500);
        setLoading(false)
        if (slug == undefined && name == undefined) {
        }
    }, [])

    const closeModal = () => {
        setModal(false)
        setPressed(true)
        handleChange()
        // setMuted(false)
    }

    const handleEnded = () => setModal(true)

    useEffect(() => {
        if (slug) {
            FIND(slug)
        }
        if (name != undefined && name.includes('-')) {
            FIND(name)
        }
    }, [slug, name]);

    const FIND = async (key) => {
        setLoading(true)
        try {
            const index = await feeds?.findIndex(feed => feed.productId === key);
            if (index !== -1) {
                const newFeeds = await MoveItem(index);
                setFeeds(newFeeds);
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    const MoveItem = async (fromIndex) => {
        const newItems = [...feeds];
        const [movedItem] = newItems.splice(fromIndex, 1);
        newItems.unshift(movedItem);
        return newItems
    };

    if (loading) return <LoadingLogo />

    return (
        <div className="relative" >
            <Swiper
                ref={swiperRef}
                slidesPerView={1}
                grabCursor={true}
                direction="vertical"
                className="swiper-container h-[100svh]"
                modules={[Keyboard, Pagination, Mousewheel, Scrollbar, Virtual]}
                keyboard={{
                    enabled: true,
                    onlyInViewport: true,
                    pageUpDown: false
                }}
                mousewheel={{
                    invert: false,
                    sensitivity: 1,
                    releaseOnEdges: true
                }}
                loop
            >
                {feeds?.length > 0 && feeds?.map((item, i) => {
                    return (
                        <SwiperSlide
                            key={i}
                            virtualIndex={i}>
                            <>
                                {(item?.previewType == 'video' || item?.previewType == 'audio') && <VideoSlide item={item} muted={muted} setMuted={setMuted} onClick={() => setMuted(!muted)} onEnd={handleEnded} pressed={pressed} swiperRef={swiperRef} />}
                                {/* {(item?.previewType == 'audio' && item?.previewType != 'video') && <SongSlide item={item} muted={muted} setMuted={setMuted} onEnd={handleEnded} pressed={pressed} />} */}
                                {/* {(item.isAudio == true || item?.previewType == 'audio') && <SongSlide item={item} muted={muted} setMuted={setMuted} onEnd={handleEnded} pressed={pressed} />}
                                {(item?.isAudio == false || item?.previewType == 'video') && <VideoSlide item={item} muted={muted} setMuted={setMuted} onClick={() => setMuted(!muted)} onEnd={handleEnded} />} */}

                            </>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className='absolute top-0 left-0 right-0 cursor-pointer flex justify-between z-20 font-interRegular'>
                <div className='text-white bg-black w-full flex items-center justify-around h-16 text-xs'>
                    <div className="flex items-center">
                        <img src="/logo512.png" alt="logo mint" className='h-[40px] w-[40px] rounded' />
                        <div className="leading-tight ml-3">
                            <div className='font-bold'>Disfruta de una experiencia</div>
                            <div className='font-bold'>completa en la aplicación</div>
                        </div>
                    </div>
                    <button
                        onClick={HandleGotopp}
                        className='bg-[#DBDBDB] rounded-full py-1 mx-2 px-4 text-black'>ABRIR</button>
                </div>
            </div>
            {(!isTrue && modal) && <AppModal onClose={closeModal} />}
        </div>
    )
}

function ContentType({ item, creatorData, muted, setMuted }) {
    if (item.type == 'audio') return <SongSlide item={item} data={creatorData} muted={muted} setMuted={setMuted} />
    if (item.type == 'video') return (<VideoSlide item={item} data={creatorData} muted={muted} setMuted={setMuted} />)
    if (item.type == 'event' && item.location == 'online') return (<ProfileTickets online={true} />)
    if (item.type == 'event') return (<ProfileTickets />)
}

export default ProfileSwiper