import { Header } from "../../components/Headers";
import { CheckCircle, VisaCircleIcon } from "../../components/Icons";
import { BasicButton, ButtonDark } from "../../components/NewButtons";
import NewHeader from "../../components/NewHeader";
import { InputBordered, UploadFile } from "../../components/NewInputs";

export default function Configuration() {
  return (
    <div className="bg-white h-screen font-interRegular">
      <Header />
      <div className="px-6">
        <div className="font-bold text-xl">Configurar Perfil</div>
        <div className="mt-6">
          <div className="font-bold text-sm mb-3">Nombre de Usuario</div>
          <InputBordered placeholder="Nombre Completo" />
        </div>
        <div className="mt-6">
          <div className="font-bold text-sm">Foto de Perfil</div>
          <div className='mt-4'>
            <div className='flex flex-row justify-between'>
              <div className='text-xs text-gray-400 my-4'>
                <div>Peso: 50 KB</div>
              </div>
              {<CheckCircle />}
            </div>
            <UploadFile className='w-full' img label='Subir imágen' />
          </div>
        </div>
        <div className="mt-6 text-sm font-bold">Configuración de Pago</div>
        <div className="mt-4 italic underline text-xs">Añadir Medio de Pago</div>
        <div className="mt-4 flex items-center">
          <VisaCircleIcon />
          <div className="ml-2 text-sm">Visa **** 2345</div>
        </div>
        <div className="my-6">
          <div>Nombre Completo</div>
          <div>RUT</div>
        </div>
        <div className="italic underline text-center text-xs">Cambiar Tarjeta Registrada</div>
        <ButtonDark className="w-full mt-4">Eliminar Tarjeta Registrada</ButtonDark>
        <BasicButton className="w-full mt-4">Continuar</BasicButton>
      </div>
    </div>
  )
}