import React from 'react'
import { MachIcon, OnepayIcon, WebpayIcon } from '../Icons'
import { ArrowButton } from '../NewButtons'

export function WebPayButton({ onClick, title, loading }) {
    return (
        <div>
            <div className='text-base font-interBold col-span-2 leading-6 mt-[2vh]'>
                {title}
            </div>
            <div onClick={onClick}
                className='border border-[#D9D9D9] flex items-center py-2 justify-between rounded-md mt-[2vh]'>
                <div className='flex justify-between w-[80%]'>
                    <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                        <WebpayIcon />
                    </div>
                    <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                        <MachIcon />
                    </div>
                    <div className='w-20 sm:w-26 h-12 flex items-center justify-center rounded-xl'>
                        <OnepayIcon />
                    </div>
                </div>
                <div className='flex flex-col self-center mr-1'>
                    <ArrowButton loading={loading} onClick={onClick} />
                </div>
            </div>
        </div>
    )
}
