
import useHeight from '../../utils/height';
import { ButtonBlack, ButtonGrey } from '../NewButtons';
import './paymentModal.css';



export const PaymentModal1 = ({ onClose, onClick, loading, currentProduct }) => {

    const { height } = useHeight()
    const PRICE = currentProduct?.price
    const isExpired = currentProduct?.isExpired
    const handleAppleClick = () => {
        let storeLink = 'https://apps.apple.com/cl/app/mint/id6467195856'
        setTimeout(() => {
            window.location.href = storeLink;
        }, 500);
    }

    const handleAndroidClick = () => {
        let storeLink = 'https://play.google.com/store/apps/details?id=info.mintapp'
        setTimeout(() => {
            window.location.href = storeLink;
        }, 500);

    }

    const handleClose = (e) => {
        if (onClose && e.target === document.getElementById('app-modal')) {
            onClose();
        }
    };

    return (
        <div className='app-modal-background font-interRegular' style={{ height, }} id='app-modal' onClick={handleClose}>
            <div style={{ backgroundColor: 'white', }} className='app-modal-container p-6 px-10  relative'>
                {isExpired && <>
                    <div className='text-[14px] mt-4'>
                        Esta preventa ya expiró
                    </div>
                    <br />
                    <div className='mt-4' />
                    <ButtonBlack loading={loading} label={'Aceptar'} onClick={onClose} />
                    <br />
                </>}
                {!isExpired &&
                    <>
                        <div style={{ lineHeight: 1.2 }} className='text-[17px] leading-none text-left mt-3'>{currentProduct?.type == 'live' ? 'Compra una entrada para acceder a este concierto por' : 'Escucha esta canción durante un mes por'} <b>{PRICE}</b></div>
                        <div className='text-[10px] mt-4'>
                            {currentProduct?.type == 'live' ? '' : 'Puedes escucharla cuantas veces quieras durante 30 días'}
                        </div>
                        <br />
                        <div className='mt-2' />
                        <ButtonBlack loading={loading} label={'Continuar'} onClick={onClick} />
                        <br />
                    </>}

            </div>
        </div>
    );
}
