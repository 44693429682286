import { useNavigate, useParams } from "react-router-dom";


import { useApi } from "../../network/hooks/useApi";
import { resources } from "../../network/resources";
import NewHeader from "../../components/NewHeader";
import { LOGIN, ROOT, merchStorePath, previewPath } from "../../utils/Routes";
import { CenterSpinner } from "../../components/Spinner";
import AudioPlayer from "../../components/Landing/AudioPlayer";
import LandingButton from "../../components/Landing/LandingButton";
import Descriptions from "../../components/Landing/Descriptions";
import ImageBackground from "../../components/Landing/ImageBackground";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { s_is_auth } from "../../reducer/SessionReducer";
import useHeight from "../../utils/height";



function MerchLanding({ data }) {
  const { name } = useParams();
  const is_auth = useSelector(s_is_auth)
  const navigate = useNavigate()
  const { height } = useHeight()
  //const { data, loading } = useApi(resources['singleCreator'].replace(':id', name));

  const handleButton = () => {
    const route = merchStorePath(name)
    if (is_auth) {
      navigate(route, { state: data.products })
    } else {
      navigate(LOGIN, { state: { route } })
    }
  }

  return (
    <div className="relative">
      <ImageBackground src={data?.picture} />
      <div style={{ height: height }}
        className="relative h-[100dvh] flex flex-col justify-between">
        <NewHeader back to={ROOT} />
        <div className="px-10 mb-[4vh] text-white">
          <Descriptions name={"Colección LOUD"} />
          <div className="mt-6">
            <LandingButton label={'Acceder a la tienda'} onClick={handleButton} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchLanding;
