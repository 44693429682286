import {
  BrowserRouter as Router,
  useLocation,
  useParams,
} from 'react-router-dom';
import ReactGA from 'react-ga4';

import './App.css';

import MainRoute from './routes/MainRoute';
import { useEffect, useState } from 'react';
import { HORIZONTAL_FEED } from './utils/Routes';
import { resources } from './network/resources';
import { useApi } from './network/hooks/useApi';
import { s_user_info, updateUserInfo } from './reducer/SessionReducer';
import { useDispatch, useSelector } from 'react-redux';
import config from './config';
import { Toaster } from 'react-hot-toast';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { collection, getDoc, getDocs, limit, query, where } from 'firebase/firestore';
import { db } from './firebase';
import { setfeeds } from './reducer/FeedsReducer';
import { LoadingLogo } from './components/Landing/LoadingLogo';
import ampli from './ampli';

/**
 * it can change between "app" and a fullscreen layout  "app-full"
 */
function MainApp() {
  const location = useLocation();
  const [container, setContainer] = useState('app');
  //const { data } = useApi(resources['userInfoGoogle']);

  //const dispatch = useDispatch();

  useEffect(() => {
    if (isFullApp(location.pathname)) {
      setContainer('app-full');
    } else {
      setContainer('app');
    }
  }, [location]);

  const [loading, setLoading] = useState(false)
  const [feeds, setFeeds] = useState(false)
  const [modal, setModal] = useState(false)

  const closeModal = () => {
    setModal(false)
  }
  const dispatch = useDispatch()

  const currentUrl = window.location.href;
  const slg = currentUrl.split('/').pop();

  useEffect(() => {
    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) return;
      // window.location = currentUrl
    }, 25);
    if (slg) {
      window.location = `mintapp://Feed/${slg}`
    }
  }, [slg]);
  // console.log("Current URL:", );


  useEffect(() => {
    // AlignData()
    localStorage.setItem('isTrue', false);
    GetD()
  }, [])

  const filters = [
    { field: 'isVisible', operator: '==', value: true },
    { field: 'isDeleted', operator: '==', value: false },
    { field: 'isDisabled', operator: '==', value: false },
    // Add more filter conditions as needed
  ];

  const GetD = async () => {
    setLoading(true);
    try {
      let res = await fetch(`${config.FUNCTIONS_URL}/getFeeds`)
      let data = await res.json()
      setFeeds(data);
      dispatch(setfeeds({ feeds: data }));
    } catch (error) {
      console.log('error', error)
    }
    finally {
      setLoading(false);
    }
  }

  const AlignData = async () => {
    setLoading(true);
    try {
      const q = query(collection(db, 'FeedsVertical'), ...filters.map(filter => where(filter.field, filter.operator, filter.value)), limit(100));
      const querySnapshot = await getDocs(q);
      const users = [];
      for (const doc of querySnapshot.docs) {
        const userData = doc.data();
        const userRef = userData.user;
        const userDoc = await getDoc(userRef);
        if (userData.preview !== '' || userData.mp4Preview) {
          users.push({ id: doc.id, ...userData, user: userDoc.data() });
        }
      }
      setFeeds(users);
      dispatch(setfeeds({ feeds: users }));
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  }

  /*useEffect(() => {
    if (data !== null) {
      dispatch(updateUserInfo({
        creator_id: data.creator_id,
        is_creator: data.is_creator,
        userId: data.id
      }));
    }
  }, [data, dispatch]);*/

  if (loading) return <LoadingLogo />

  return (
    <div className={container}>
      <div><Toaster position="bottom-center" reverseOrder={false} /></div>
      <MainRoute />
      {/* {container === 'app' && <Footer />} */}
    </div>
  );
}

function isFullApp(path) {
  return path === HORIZONTAL_FEED || path.indexOf('videoplayer') !== -1;
}

/**
 * Main app, routing and initializations
 */
function App() {
  const { email } = useSelector(s_user_info);

  useEffect(() => {
    ampli.load()

    //Google analytics init
    ReactGA.initialize([{ trackingId: config.GOOGLE_ANALYTICS_KEY }]);
  }, []);

  useEffect(() => {
    ReactGA.event('app_opened', {
      event_name: 'app_opened',
      creator_name: window.location.pathname,
    });
    // ampli.appOpened({ creator_name: window.location.pathname });
    // ampli.appOpenedByArtist({ creator_name: window.location.pathname });

    return () => {
      // ampli.appClosed({ creator_name: window.location.pathname });
    };
  }, []);
  const stripePromise = loadStripe(config.STRIPE_KAY);
  return (
    <Router>
      <Elements stripe={stripePromise}>
        <MainApp />
      </Elements>
    </Router>
  );
}

export default App;
